(function(scope){
'use strict';

function F(arity, fun, wrapper) {
  wrapper.a = arity;
  wrapper.f = fun;
  return wrapper;
}

function F2(fun) {
  return F(2, fun, function(a) { return function(b) { return fun(a,b); }; })
}
function F3(fun) {
  return F(3, fun, function(a) {
    return function(b) { return function(c) { return fun(a, b, c); }; };
  });
}
function F4(fun) {
  return F(4, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return fun(a, b, c, d); }; }; };
  });
}
function F5(fun) {
  return F(5, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return fun(a, b, c, d, e); }; }; }; };
  });
}
function F6(fun) {
  return F(6, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return fun(a, b, c, d, e, f); }; }; }; }; };
  });
}
function F7(fun) {
  return F(7, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return fun(a, b, c, d, e, f, g); }; }; }; }; }; };
  });
}
function F8(fun) {
  return F(8, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return function(h) {
    return fun(a, b, c, d, e, f, g, h); }; }; }; }; }; }; };
  });
}
function F9(fun) {
  return F(9, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return function(h) { return function(i) {
    return fun(a, b, c, d, e, f, g, h, i); }; }; }; }; }; }; }; };
  });
}

function A2(fun, a, b) {
  return fun.a === 2 ? fun.f(a, b) : fun(a)(b);
}
function A3(fun, a, b, c) {
  return fun.a === 3 ? fun.f(a, b, c) : fun(a)(b)(c);
}
function A4(fun, a, b, c, d) {
  return fun.a === 4 ? fun.f(a, b, c, d) : fun(a)(b)(c)(d);
}
function A5(fun, a, b, c, d, e) {
  return fun.a === 5 ? fun.f(a, b, c, d, e) : fun(a)(b)(c)(d)(e);
}
function A6(fun, a, b, c, d, e, f) {
  return fun.a === 6 ? fun.f(a, b, c, d, e, f) : fun(a)(b)(c)(d)(e)(f);
}
function A7(fun, a, b, c, d, e, f, g) {
  return fun.a === 7 ? fun.f(a, b, c, d, e, f, g) : fun(a)(b)(c)(d)(e)(f)(g);
}
function A8(fun, a, b, c, d, e, f, g, h) {
  return fun.a === 8 ? fun.f(a, b, c, d, e, f, g, h) : fun(a)(b)(c)(d)(e)(f)(g)(h);
}
function A9(fun, a, b, c, d, e, f, g, h, i) {
  return fun.a === 9 ? fun.f(a, b, c, d, e, f, g, h, i) : fun(a)(b)(c)(d)(e)(f)(g)(h)(i);
}




// EQUALITY

function _Utils_eq(x, y)
{
	for (
		var pair, stack = [], isEqual = _Utils_eqHelp(x, y, 0, stack);
		isEqual && (pair = stack.pop());
		isEqual = _Utils_eqHelp(pair.a, pair.b, 0, stack)
		)
	{}

	return isEqual;
}

function _Utils_eqHelp(x, y, depth, stack)
{
	if (x === y)
	{
		return true;
	}

	if (typeof x !== 'object' || x === null || y === null)
	{
		typeof x === 'function' && _Debug_crash(5);
		return false;
	}

	if (depth > 100)
	{
		stack.push(_Utils_Tuple2(x,y));
		return true;
	}

	/**_UNUSED/
	if (x.$ === 'Set_elm_builtin')
	{
		x = $elm$core$Set$toList(x);
		y = $elm$core$Set$toList(y);
	}
	if (x.$ === 'RBNode_elm_builtin' || x.$ === 'RBEmpty_elm_builtin')
	{
		x = $elm$core$Dict$toList(x);
		y = $elm$core$Dict$toList(y);
	}
	//*/

	/**/
	if (x.$ < 0)
	{
		x = $elm$core$Dict$toList(x);
		y = $elm$core$Dict$toList(y);
	}
	//*/

	for (var key in x)
	{
		if (!_Utils_eqHelp(x[key], y[key], depth + 1, stack))
		{
			return false;
		}
	}
	return true;
}

var _Utils_equal = F2(_Utils_eq);
var _Utils_notEqual = F2(function(a, b) { return !_Utils_eq(a,b); });



// COMPARISONS

// Code in Generate/JavaScript.hs, Basics.js, and List.js depends on
// the particular integer values assigned to LT, EQ, and GT.

function _Utils_cmp(x, y, ord)
{
	if (typeof x !== 'object')
	{
		return x === y ? /*EQ*/ 0 : x < y ? /*LT*/ -1 : /*GT*/ 1;
	}

	/**_UNUSED/
	if (x instanceof String)
	{
		var a = x.valueOf();
		var b = y.valueOf();
		return a === b ? 0 : a < b ? -1 : 1;
	}
	//*/

	/**/
	if (typeof x.$ === 'undefined')
	//*/
	/**_UNUSED/
	if (x.$[0] === '#')
	//*/
	{
		return (ord = _Utils_cmp(x.a, y.a))
			? ord
			: (ord = _Utils_cmp(x.b, y.b))
				? ord
				: _Utils_cmp(x.c, y.c);
	}

	// traverse conses until end of a list or a mismatch
	for (; x.b && y.b && !(ord = _Utils_cmp(x.a, y.a)); x = x.b, y = y.b) {} // WHILE_CONSES
	return ord || (x.b ? /*GT*/ 1 : y.b ? /*LT*/ -1 : /*EQ*/ 0);
}

var _Utils_lt = F2(function(a, b) { return _Utils_cmp(a, b) < 0; });
var _Utils_le = F2(function(a, b) { return _Utils_cmp(a, b) < 1; });
var _Utils_gt = F2(function(a, b) { return _Utils_cmp(a, b) > 0; });
var _Utils_ge = F2(function(a, b) { return _Utils_cmp(a, b) >= 0; });

var _Utils_compare = F2(function(x, y)
{
	var n = _Utils_cmp(x, y);
	return n < 0 ? $elm$core$Basics$LT : n ? $elm$core$Basics$GT : $elm$core$Basics$EQ;
});


// COMMON VALUES

var _Utils_Tuple0 = 0;
var _Utils_Tuple0_UNUSED = { $: '#0' };

function _Utils_Tuple2(a, b) { return { a: a, b: b }; }
function _Utils_Tuple2_UNUSED(a, b) { return { $: '#2', a: a, b: b }; }

function _Utils_Tuple3(a, b, c) { return { a: a, b: b, c: c }; }
function _Utils_Tuple3_UNUSED(a, b, c) { return { $: '#3', a: a, b: b, c: c }; }

function _Utils_chr(c) { return c; }
function _Utils_chr_UNUSED(c) { return new String(c); }


// RECORDS

function _Utils_update(oldRecord, updatedFields)
{
	var newRecord = {};

	for (var key in oldRecord)
	{
		newRecord[key] = oldRecord[key];
	}

	for (var key in updatedFields)
	{
		newRecord[key] = updatedFields[key];
	}

	return newRecord;
}


// APPEND

var _Utils_append = F2(_Utils_ap);

function _Utils_ap(xs, ys)
{
	// append Strings
	if (typeof xs === 'string')
	{
		return xs + ys;
	}

	// append Lists
	if (!xs.b)
	{
		return ys;
	}
	var root = _List_Cons(xs.a, ys);
	xs = xs.b
	for (var curr = root; xs.b; xs = xs.b) // WHILE_CONS
	{
		curr = curr.b = _List_Cons(xs.a, ys);
	}
	return root;
}



var _List_Nil = { $: 0 };
var _List_Nil_UNUSED = { $: '[]' };

function _List_Cons(hd, tl) { return { $: 1, a: hd, b: tl }; }
function _List_Cons_UNUSED(hd, tl) { return { $: '::', a: hd, b: tl }; }


var _List_cons = F2(_List_Cons);

function _List_fromArray(arr)
{
	var out = _List_Nil;
	for (var i = arr.length; i--; )
	{
		out = _List_Cons(arr[i], out);
	}
	return out;
}

function _List_toArray(xs)
{
	for (var out = []; xs.b; xs = xs.b) // WHILE_CONS
	{
		out.push(xs.a);
	}
	return out;
}

var _List_map2 = F3(function(f, xs, ys)
{
	for (var arr = []; xs.b && ys.b; xs = xs.b, ys = ys.b) // WHILE_CONSES
	{
		arr.push(A2(f, xs.a, ys.a));
	}
	return _List_fromArray(arr);
});

var _List_map3 = F4(function(f, xs, ys, zs)
{
	for (var arr = []; xs.b && ys.b && zs.b; xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A3(f, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_map4 = F5(function(f, ws, xs, ys, zs)
{
	for (var arr = []; ws.b && xs.b && ys.b && zs.b; ws = ws.b, xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A4(f, ws.a, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_map5 = F6(function(f, vs, ws, xs, ys, zs)
{
	for (var arr = []; vs.b && ws.b && xs.b && ys.b && zs.b; vs = vs.b, ws = ws.b, xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A5(f, vs.a, ws.a, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_sortBy = F2(function(f, xs)
{
	return _List_fromArray(_List_toArray(xs).sort(function(a, b) {
		return _Utils_cmp(f(a), f(b));
	}));
});

var _List_sortWith = F2(function(f, xs)
{
	return _List_fromArray(_List_toArray(xs).sort(function(a, b) {
		var ord = A2(f, a, b);
		return ord === $elm$core$Basics$EQ ? 0 : ord === $elm$core$Basics$LT ? -1 : 1;
	}));
});



var _JsArray_empty = [];

function _JsArray_singleton(value)
{
    return [value];
}

function _JsArray_length(array)
{
    return array.length;
}

var _JsArray_initialize = F3(function(size, offset, func)
{
    var result = new Array(size);

    for (var i = 0; i < size; i++)
    {
        result[i] = func(offset + i);
    }

    return result;
});

var _JsArray_initializeFromList = F2(function (max, ls)
{
    var result = new Array(max);

    for (var i = 0; i < max && ls.b; i++)
    {
        result[i] = ls.a;
        ls = ls.b;
    }

    result.length = i;
    return _Utils_Tuple2(result, ls);
});

var _JsArray_unsafeGet = F2(function(index, array)
{
    return array[index];
});

var _JsArray_unsafeSet = F3(function(index, value, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = array[i];
    }

    result[index] = value;
    return result;
});

var _JsArray_push = F2(function(value, array)
{
    var length = array.length;
    var result = new Array(length + 1);

    for (var i = 0; i < length; i++)
    {
        result[i] = array[i];
    }

    result[length] = value;
    return result;
});

var _JsArray_foldl = F3(function(func, acc, array)
{
    var length = array.length;

    for (var i = 0; i < length; i++)
    {
        acc = A2(func, array[i], acc);
    }

    return acc;
});

var _JsArray_foldr = F3(function(func, acc, array)
{
    for (var i = array.length - 1; i >= 0; i--)
    {
        acc = A2(func, array[i], acc);
    }

    return acc;
});

var _JsArray_map = F2(function(func, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = func(array[i]);
    }

    return result;
});

var _JsArray_indexedMap = F3(function(func, offset, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = A2(func, offset + i, array[i]);
    }

    return result;
});

var _JsArray_slice = F3(function(from, to, array)
{
    return array.slice(from, to);
});

var _JsArray_appendN = F3(function(n, dest, source)
{
    var destLen = dest.length;
    var itemsToCopy = n - destLen;

    if (itemsToCopy > source.length)
    {
        itemsToCopy = source.length;
    }

    var size = destLen + itemsToCopy;
    var result = new Array(size);

    for (var i = 0; i < destLen; i++)
    {
        result[i] = dest[i];
    }

    for (var i = 0; i < itemsToCopy; i++)
    {
        result[i + destLen] = source[i];
    }

    return result;
});



// LOG

var _Debug_log = F2(function(tag, value)
{
	return value;
});

var _Debug_log_UNUSED = F2(function(tag, value)
{
	console.log(tag + ': ' + _Debug_toString(value));
	return value;
});


// TODOS

function _Debug_todo(moduleName, region)
{
	return function(message) {
		_Debug_crash(8, moduleName, region, message);
	};
}

function _Debug_todoCase(moduleName, region, value)
{
	return function(message) {
		_Debug_crash(9, moduleName, region, value, message);
	};
}


// TO STRING

function _Debug_toString(value)
{
	return '<internals>';
}

function _Debug_toString_UNUSED(value)
{
	return _Debug_toAnsiString(false, value);
}

function _Debug_toAnsiString(ansi, value)
{
	if (typeof value === 'function')
	{
		return _Debug_internalColor(ansi, '<function>');
	}

	if (typeof value === 'boolean')
	{
		return _Debug_ctorColor(ansi, value ? 'True' : 'False');
	}

	if (typeof value === 'number')
	{
		return _Debug_numberColor(ansi, value + '');
	}

	if (value instanceof String)
	{
		return _Debug_charColor(ansi, "'" + _Debug_addSlashes(value, true) + "'");
	}

	if (typeof value === 'string')
	{
		return _Debug_stringColor(ansi, '"' + _Debug_addSlashes(value, false) + '"');
	}

	if (typeof value === 'object' && '$' in value)
	{
		var tag = value.$;

		if (typeof tag === 'number')
		{
			return _Debug_internalColor(ansi, '<internals>');
		}

		if (tag[0] === '#')
		{
			var output = [];
			for (var k in value)
			{
				if (k === '$') continue;
				output.push(_Debug_toAnsiString(ansi, value[k]));
			}
			return '(' + output.join(',') + ')';
		}

		if (tag === 'Set_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Set')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Set$toList(value));
		}

		if (tag === 'RBNode_elm_builtin' || tag === 'RBEmpty_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Dict')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Dict$toList(value));
		}

		if (tag === 'Array_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Array')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Array$toList(value));
		}

		if (tag === '::' || tag === '[]')
		{
			var output = '[';

			value.b && (output += _Debug_toAnsiString(ansi, value.a), value = value.b)

			for (; value.b; value = value.b) // WHILE_CONS
			{
				output += ',' + _Debug_toAnsiString(ansi, value.a);
			}
			return output + ']';
		}

		var output = '';
		for (var i in value)
		{
			if (i === '$') continue;
			var str = _Debug_toAnsiString(ansi, value[i]);
			var c0 = str[0];
			var parenless = c0 === '{' || c0 === '(' || c0 === '[' || c0 === '<' || c0 === '"' || str.indexOf(' ') < 0;
			output += ' ' + (parenless ? str : '(' + str + ')');
		}
		return _Debug_ctorColor(ansi, tag) + output;
	}

	if (typeof DataView === 'function' && value instanceof DataView)
	{
		return _Debug_stringColor(ansi, '<' + value.byteLength + ' bytes>');
	}

	if (typeof File !== 'undefined' && value instanceof File)
	{
		return _Debug_internalColor(ansi, '<' + value.name + '>');
	}

	if (typeof value === 'object')
	{
		var output = [];
		for (var key in value)
		{
			var field = key[0] === '_' ? key.slice(1) : key;
			output.push(_Debug_fadeColor(ansi, field) + ' = ' + _Debug_toAnsiString(ansi, value[key]));
		}
		if (output.length === 0)
		{
			return '{}';
		}
		return '{ ' + output.join(', ') + ' }';
	}

	return _Debug_internalColor(ansi, '<internals>');
}

function _Debug_addSlashes(str, isChar)
{
	var s = str
		.replace(/\\/g, '\\\\')
		.replace(/\n/g, '\\n')
		.replace(/\t/g, '\\t')
		.replace(/\r/g, '\\r')
		.replace(/\v/g, '\\v')
		.replace(/\0/g, '\\0');

	if (isChar)
	{
		return s.replace(/\'/g, '\\\'');
	}
	else
	{
		return s.replace(/\"/g, '\\"');
	}
}

function _Debug_ctorColor(ansi, string)
{
	return ansi ? '\x1b[96m' + string + '\x1b[0m' : string;
}

function _Debug_numberColor(ansi, string)
{
	return ansi ? '\x1b[95m' + string + '\x1b[0m' : string;
}

function _Debug_stringColor(ansi, string)
{
	return ansi ? '\x1b[93m' + string + '\x1b[0m' : string;
}

function _Debug_charColor(ansi, string)
{
	return ansi ? '\x1b[92m' + string + '\x1b[0m' : string;
}

function _Debug_fadeColor(ansi, string)
{
	return ansi ? '\x1b[37m' + string + '\x1b[0m' : string;
}

function _Debug_internalColor(ansi, string)
{
	return ansi ? '\x1b[36m' + string + '\x1b[0m' : string;
}

function _Debug_toHexDigit(n)
{
	return String.fromCharCode(n < 10 ? 48 + n : 55 + n);
}


// CRASH


function _Debug_crash(identifier)
{
	throw new Error('https://github.com/elm/core/blob/1.0.0/hints/' + identifier + '.md');
}


function _Debug_crash_UNUSED(identifier, fact1, fact2, fact3, fact4)
{
	switch(identifier)
	{
		case 0:
			throw new Error('What node should I take over? In JavaScript I need something like:\n\n    Elm.Main.init({\n        node: document.getElementById("elm-node")\n    })\n\nYou need to do this with any Browser.sandbox or Browser.element program.');

		case 1:
			throw new Error('Browser.application programs cannot handle URLs like this:\n\n    ' + document.location.href + '\n\nWhat is the root? The root of your file system? Try looking at this program with `elm reactor` or some other server.');

		case 2:
			var jsonErrorString = fact1;
			throw new Error('Problem with the flags given to your Elm program on initialization.\n\n' + jsonErrorString);

		case 3:
			var portName = fact1;
			throw new Error('There can only be one port named `' + portName + '`, but your program has multiple.');

		case 4:
			var portName = fact1;
			var problem = fact2;
			throw new Error('Trying to send an unexpected type of value through port `' + portName + '`:\n' + problem);

		case 5:
			throw new Error('Trying to use `(==)` on functions.\nThere is no way to know if functions are "the same" in the Elm sense.\nRead more about this at https://package.elm-lang.org/packages/elm/core/latest/Basics#== which describes why it is this way and what the better version will look like.');

		case 6:
			var moduleName = fact1;
			throw new Error('Your page is loading multiple Elm scripts with a module named ' + moduleName + '. Maybe a duplicate script is getting loaded accidentally? If not, rename one of them so I know which is which!');

		case 8:
			var moduleName = fact1;
			var region = fact2;
			var message = fact3;
			throw new Error('TODO in module `' + moduleName + '` ' + _Debug_regionToString(region) + '\n\n' + message);

		case 9:
			var moduleName = fact1;
			var region = fact2;
			var value = fact3;
			var message = fact4;
			throw new Error(
				'TODO in module `' + moduleName + '` from the `case` expression '
				+ _Debug_regionToString(region) + '\n\nIt received the following value:\n\n    '
				+ _Debug_toString(value).replace('\n', '\n    ')
				+ '\n\nBut the branch that handles it says:\n\n    ' + message.replace('\n', '\n    ')
			);

		case 10:
			throw new Error('Bug in https://github.com/elm/virtual-dom/issues');

		case 11:
			throw new Error('Cannot perform mod 0. Division by zero error.');
	}
}

function _Debug_regionToString(region)
{
	if (region.bL.dE === region.b8.dE)
	{
		return 'on line ' + region.bL.dE;
	}
	return 'on lines ' + region.bL.dE + ' through ' + region.b8.dE;
}



// MATH

var _Basics_add = F2(function(a, b) { return a + b; });
var _Basics_sub = F2(function(a, b) { return a - b; });
var _Basics_mul = F2(function(a, b) { return a * b; });
var _Basics_fdiv = F2(function(a, b) { return a / b; });
var _Basics_idiv = F2(function(a, b) { return (a / b) | 0; });
var _Basics_pow = F2(Math.pow);

var _Basics_remainderBy = F2(function(b, a) { return a % b; });

// https://www.microsoft.com/en-us/research/wp-content/uploads/2016/02/divmodnote-letter.pdf
var _Basics_modBy = F2(function(modulus, x)
{
	var answer = x % modulus;
	return modulus === 0
		? _Debug_crash(11)
		:
	((answer > 0 && modulus < 0) || (answer < 0 && modulus > 0))
		? answer + modulus
		: answer;
});


// TRIGONOMETRY

var _Basics_pi = Math.PI;
var _Basics_e = Math.E;
var _Basics_cos = Math.cos;
var _Basics_sin = Math.sin;
var _Basics_tan = Math.tan;
var _Basics_acos = Math.acos;
var _Basics_asin = Math.asin;
var _Basics_atan = Math.atan;
var _Basics_atan2 = F2(Math.atan2);


// MORE MATH

function _Basics_toFloat(x) { return x; }
function _Basics_truncate(n) { return n | 0; }
function _Basics_isInfinite(n) { return n === Infinity || n === -Infinity; }

var _Basics_ceiling = Math.ceil;
var _Basics_floor = Math.floor;
var _Basics_round = Math.round;
var _Basics_sqrt = Math.sqrt;
var _Basics_log = Math.log;
var _Basics_isNaN = isNaN;


// BOOLEANS

function _Basics_not(bool) { return !bool; }
var _Basics_and = F2(function(a, b) { return a && b; });
var _Basics_or  = F2(function(a, b) { return a || b; });
var _Basics_xor = F2(function(a, b) { return a !== b; });



var _String_cons = F2(function(chr, str)
{
	return chr + str;
});

function _String_uncons(string)
{
	var word = string.charCodeAt(0);
	return !isNaN(word)
		? $elm$core$Maybe$Just(
			0xD800 <= word && word <= 0xDBFF
				? _Utils_Tuple2(_Utils_chr(string[0] + string[1]), string.slice(2))
				: _Utils_Tuple2(_Utils_chr(string[0]), string.slice(1))
		)
		: $elm$core$Maybe$Nothing;
}

var _String_append = F2(function(a, b)
{
	return a + b;
});

function _String_length(str)
{
	return str.length;
}

var _String_map = F2(function(func, string)
{
	var len = string.length;
	var array = new Array(len);
	var i = 0;
	while (i < len)
	{
		var word = string.charCodeAt(i);
		if (0xD800 <= word && word <= 0xDBFF)
		{
			array[i] = func(_Utils_chr(string[i] + string[i+1]));
			i += 2;
			continue;
		}
		array[i] = func(_Utils_chr(string[i]));
		i++;
	}
	return array.join('');
});

var _String_filter = F2(function(isGood, str)
{
	var arr = [];
	var len = str.length;
	var i = 0;
	while (i < len)
	{
		var char = str[i];
		var word = str.charCodeAt(i);
		i++;
		if (0xD800 <= word && word <= 0xDBFF)
		{
			char += str[i];
			i++;
		}

		if (isGood(_Utils_chr(char)))
		{
			arr.push(char);
		}
	}
	return arr.join('');
});

function _String_reverse(str)
{
	var len = str.length;
	var arr = new Array(len);
	var i = 0;
	while (i < len)
	{
		var word = str.charCodeAt(i);
		if (0xD800 <= word && word <= 0xDBFF)
		{
			arr[len - i] = str[i + 1];
			i++;
			arr[len - i] = str[i - 1];
			i++;
		}
		else
		{
			arr[len - i] = str[i];
			i++;
		}
	}
	return arr.join('');
}

var _String_foldl = F3(function(func, state, string)
{
	var len = string.length;
	var i = 0;
	while (i < len)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		i++;
		if (0xD800 <= word && word <= 0xDBFF)
		{
			char += string[i];
			i++;
		}
		state = A2(func, _Utils_chr(char), state);
	}
	return state;
});

var _String_foldr = F3(function(func, state, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		state = A2(func, _Utils_chr(char), state);
	}
	return state;
});

var _String_split = F2(function(sep, str)
{
	return str.split(sep);
});

var _String_join = F2(function(sep, strs)
{
	return strs.join(sep);
});

var _String_slice = F3(function(start, end, str) {
	return str.slice(start, end);
});

function _String_trim(str)
{
	return str.trim();
}

function _String_trimLeft(str)
{
	return str.replace(/^\s+/, '');
}

function _String_trimRight(str)
{
	return str.replace(/\s+$/, '');
}

function _String_words(str)
{
	return _List_fromArray(str.trim().split(/\s+/g));
}

function _String_lines(str)
{
	return _List_fromArray(str.split(/\r\n|\r|\n/g));
}

function _String_toUpper(str)
{
	return str.toUpperCase();
}

function _String_toLower(str)
{
	return str.toLowerCase();
}

var _String_any = F2(function(isGood, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		if (isGood(_Utils_chr(char)))
		{
			return true;
		}
	}
	return false;
});

var _String_all = F2(function(isGood, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		if (!isGood(_Utils_chr(char)))
		{
			return false;
		}
	}
	return true;
});

var _String_contains = F2(function(sub, str)
{
	return str.indexOf(sub) > -1;
});

var _String_startsWith = F2(function(sub, str)
{
	return str.indexOf(sub) === 0;
});

var _String_endsWith = F2(function(sub, str)
{
	return str.length >= sub.length &&
		str.lastIndexOf(sub) === str.length - sub.length;
});

var _String_indexes = F2(function(sub, str)
{
	var subLen = sub.length;

	if (subLen < 1)
	{
		return _List_Nil;
	}

	var i = 0;
	var is = [];

	while ((i = str.indexOf(sub, i)) > -1)
	{
		is.push(i);
		i = i + subLen;
	}

	return _List_fromArray(is);
});


// TO STRING

function _String_fromNumber(number)
{
	return number + '';
}


// INT CONVERSIONS

function _String_toInt(str)
{
	var total = 0;
	var code0 = str.charCodeAt(0);
	var start = code0 == 0x2B /* + */ || code0 == 0x2D /* - */ ? 1 : 0;

	for (var i = start; i < str.length; ++i)
	{
		var code = str.charCodeAt(i);
		if (code < 0x30 || 0x39 < code)
		{
			return $elm$core$Maybe$Nothing;
		}
		total = 10 * total + code - 0x30;
	}

	return i == start
		? $elm$core$Maybe$Nothing
		: $elm$core$Maybe$Just(code0 == 0x2D ? -total : total);
}


// FLOAT CONVERSIONS

function _String_toFloat(s)
{
	// check if it is a hex, octal, or binary number
	if (s.length === 0 || /[\sxbo]/.test(s))
	{
		return $elm$core$Maybe$Nothing;
	}
	var n = +s;
	// faster isNaN check
	return n === n ? $elm$core$Maybe$Just(n) : $elm$core$Maybe$Nothing;
}

function _String_fromList(chars)
{
	return _List_toArray(chars).join('');
}




function _Char_toCode(char)
{
	var code = char.charCodeAt(0);
	if (0xD800 <= code && code <= 0xDBFF)
	{
		return (code - 0xD800) * 0x400 + char.charCodeAt(1) - 0xDC00 + 0x10000
	}
	return code;
}

function _Char_fromCode(code)
{
	return _Utils_chr(
		(code < 0 || 0x10FFFF < code)
			? '\uFFFD'
			:
		(code <= 0xFFFF)
			? String.fromCharCode(code)
			:
		(code -= 0x10000,
			String.fromCharCode(Math.floor(code / 0x400) + 0xD800, code % 0x400 + 0xDC00)
		)
	);
}

function _Char_toUpper(char)
{
	return _Utils_chr(char.toUpperCase());
}

function _Char_toLower(char)
{
	return _Utils_chr(char.toLowerCase());
}

function _Char_toLocaleUpper(char)
{
	return _Utils_chr(char.toLocaleUpperCase());
}

function _Char_toLocaleLower(char)
{
	return _Utils_chr(char.toLocaleLowerCase());
}



/**_UNUSED/
function _Json_errorToString(error)
{
	return $elm$json$Json$Decode$errorToString(error);
}
//*/


// CORE DECODERS

function _Json_succeed(msg)
{
	return {
		$: 0,
		a: msg
	};
}

function _Json_fail(msg)
{
	return {
		$: 1,
		a: msg
	};
}

function _Json_decodePrim(decoder)
{
	return { $: 2, b: decoder };
}

var _Json_decodeInt = _Json_decodePrim(function(value) {
	return (typeof value !== 'number')
		? _Json_expecting('an INT', value)
		:
	(-2147483647 < value && value < 2147483647 && (value | 0) === value)
		? $elm$core$Result$Ok(value)
		:
	(isFinite(value) && !(value % 1))
		? $elm$core$Result$Ok(value)
		: _Json_expecting('an INT', value);
});

var _Json_decodeBool = _Json_decodePrim(function(value) {
	return (typeof value === 'boolean')
		? $elm$core$Result$Ok(value)
		: _Json_expecting('a BOOL', value);
});

var _Json_decodeFloat = _Json_decodePrim(function(value) {
	return (typeof value === 'number')
		? $elm$core$Result$Ok(value)
		: _Json_expecting('a FLOAT', value);
});

var _Json_decodeValue = _Json_decodePrim(function(value) {
	return $elm$core$Result$Ok(_Json_wrap(value));
});

var _Json_decodeString = _Json_decodePrim(function(value) {
	return (typeof value === 'string')
		? $elm$core$Result$Ok(value)
		: (value instanceof String)
			? $elm$core$Result$Ok(value + '')
			: _Json_expecting('a STRING', value);
});

function _Json_decodeList(decoder) { return { $: 3, b: decoder }; }
function _Json_decodeArray(decoder) { return { $: 4, b: decoder }; }

function _Json_decodeNull(value) { return { $: 5, c: value }; }

var _Json_decodeField = F2(function(field, decoder)
{
	return {
		$: 6,
		d: field,
		b: decoder
	};
});

var _Json_decodeIndex = F2(function(index, decoder)
{
	return {
		$: 7,
		e: index,
		b: decoder
	};
});

function _Json_decodeKeyValuePairs(decoder)
{
	return {
		$: 8,
		b: decoder
	};
}

function _Json_mapMany(f, decoders)
{
	return {
		$: 9,
		f: f,
		g: decoders
	};
}

var _Json_andThen = F2(function(callback, decoder)
{
	return {
		$: 10,
		b: decoder,
		h: callback
	};
});

function _Json_oneOf(decoders)
{
	return {
		$: 11,
		g: decoders
	};
}


// DECODING OBJECTS

var _Json_map1 = F2(function(f, d1)
{
	return _Json_mapMany(f, [d1]);
});

var _Json_map2 = F3(function(f, d1, d2)
{
	return _Json_mapMany(f, [d1, d2]);
});

var _Json_map3 = F4(function(f, d1, d2, d3)
{
	return _Json_mapMany(f, [d1, d2, d3]);
});

var _Json_map4 = F5(function(f, d1, d2, d3, d4)
{
	return _Json_mapMany(f, [d1, d2, d3, d4]);
});

var _Json_map5 = F6(function(f, d1, d2, d3, d4, d5)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5]);
});

var _Json_map6 = F7(function(f, d1, d2, d3, d4, d5, d6)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6]);
});

var _Json_map7 = F8(function(f, d1, d2, d3, d4, d5, d6, d7)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6, d7]);
});

var _Json_map8 = F9(function(f, d1, d2, d3, d4, d5, d6, d7, d8)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6, d7, d8]);
});


// DECODE

var _Json_runOnString = F2(function(decoder, string)
{
	try
	{
		var value = JSON.parse(string);
		return _Json_runHelp(decoder, value);
	}
	catch (e)
	{
		return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, 'This is not valid JSON! ' + e.message, _Json_wrap(string)));
	}
});

var _Json_run = F2(function(decoder, value)
{
	return _Json_runHelp(decoder, _Json_unwrap(value));
});

function _Json_runHelp(decoder, value)
{
	switch (decoder.$)
	{
		case 2:
			return decoder.b(value);

		case 5:
			return (value === null)
				? $elm$core$Result$Ok(decoder.c)
				: _Json_expecting('null', value);

		case 3:
			if (!_Json_isArray(value))
			{
				return _Json_expecting('a LIST', value);
			}
			return _Json_runArrayDecoder(decoder.b, value, _List_fromArray);

		case 4:
			if (!_Json_isArray(value))
			{
				return _Json_expecting('an ARRAY', value);
			}
			return _Json_runArrayDecoder(decoder.b, value, _Json_toElmArray);

		case 6:
			var field = decoder.d;
			if (typeof value !== 'object' || value === null || !(field in value))
			{
				return _Json_expecting('an OBJECT with a field named `' + field + '`', value);
			}
			var result = _Json_runHelp(decoder.b, value[field]);
			return ($elm$core$Result$isOk(result)) ? result : $elm$core$Result$Err(A2($elm$json$Json$Decode$Field, field, result.a));

		case 7:
			var index = decoder.e;
			if (!_Json_isArray(value))
			{
				return _Json_expecting('an ARRAY', value);
			}
			if (index >= value.length)
			{
				return _Json_expecting('a LONGER array. Need index ' + index + ' but only see ' + value.length + ' entries', value);
			}
			var result = _Json_runHelp(decoder.b, value[index]);
			return ($elm$core$Result$isOk(result)) ? result : $elm$core$Result$Err(A2($elm$json$Json$Decode$Index, index, result.a));

		case 8:
			if (typeof value !== 'object' || value === null || _Json_isArray(value))
			{
				return _Json_expecting('an OBJECT', value);
			}

			var keyValuePairs = _List_Nil;
			// TODO test perf of Object.keys and switch when support is good enough
			for (var key in value)
			{
				if (value.hasOwnProperty(key))
				{
					var result = _Json_runHelp(decoder.b, value[key]);
					if (!$elm$core$Result$isOk(result))
					{
						return $elm$core$Result$Err(A2($elm$json$Json$Decode$Field, key, result.a));
					}
					keyValuePairs = _List_Cons(_Utils_Tuple2(key, result.a), keyValuePairs);
				}
			}
			return $elm$core$Result$Ok($elm$core$List$reverse(keyValuePairs));

		case 9:
			var answer = decoder.f;
			var decoders = decoder.g;
			for (var i = 0; i < decoders.length; i++)
			{
				var result = _Json_runHelp(decoders[i], value);
				if (!$elm$core$Result$isOk(result))
				{
					return result;
				}
				answer = answer(result.a);
			}
			return $elm$core$Result$Ok(answer);

		case 10:
			var result = _Json_runHelp(decoder.b, value);
			return (!$elm$core$Result$isOk(result))
				? result
				: _Json_runHelp(decoder.h(result.a), value);

		case 11:
			var errors = _List_Nil;
			for (var temp = decoder.g; temp.b; temp = temp.b) // WHILE_CONS
			{
				var result = _Json_runHelp(temp.a, value);
				if ($elm$core$Result$isOk(result))
				{
					return result;
				}
				errors = _List_Cons(result.a, errors);
			}
			return $elm$core$Result$Err($elm$json$Json$Decode$OneOf($elm$core$List$reverse(errors)));

		case 1:
			return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, decoder.a, _Json_wrap(value)));

		case 0:
			return $elm$core$Result$Ok(decoder.a);
	}
}

function _Json_runArrayDecoder(decoder, value, toElmValue)
{
	var len = value.length;
	var array = new Array(len);
	for (var i = 0; i < len; i++)
	{
		var result = _Json_runHelp(decoder, value[i]);
		if (!$elm$core$Result$isOk(result))
		{
			return $elm$core$Result$Err(A2($elm$json$Json$Decode$Index, i, result.a));
		}
		array[i] = result.a;
	}
	return $elm$core$Result$Ok(toElmValue(array));
}

function _Json_isArray(value)
{
	return Array.isArray(value) || (typeof FileList !== 'undefined' && value instanceof FileList);
}

function _Json_toElmArray(array)
{
	return A2($elm$core$Array$initialize, array.length, function(i) { return array[i]; });
}

function _Json_expecting(type, value)
{
	return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, 'Expecting ' + type, _Json_wrap(value)));
}


// EQUALITY

function _Json_equality(x, y)
{
	if (x === y)
	{
		return true;
	}

	if (x.$ !== y.$)
	{
		return false;
	}

	switch (x.$)
	{
		case 0:
		case 1:
			return x.a === y.a;

		case 2:
			return x.b === y.b;

		case 5:
			return x.c === y.c;

		case 3:
		case 4:
		case 8:
			return _Json_equality(x.b, y.b);

		case 6:
			return x.d === y.d && _Json_equality(x.b, y.b);

		case 7:
			return x.e === y.e && _Json_equality(x.b, y.b);

		case 9:
			return x.f === y.f && _Json_listEquality(x.g, y.g);

		case 10:
			return x.h === y.h && _Json_equality(x.b, y.b);

		case 11:
			return _Json_listEquality(x.g, y.g);
	}
}

function _Json_listEquality(aDecoders, bDecoders)
{
	var len = aDecoders.length;
	if (len !== bDecoders.length)
	{
		return false;
	}
	for (var i = 0; i < len; i++)
	{
		if (!_Json_equality(aDecoders[i], bDecoders[i]))
		{
			return false;
		}
	}
	return true;
}


// ENCODE

var _Json_encode = F2(function(indentLevel, value)
{
	return JSON.stringify(_Json_unwrap(value), null, indentLevel) + '';
});

function _Json_wrap_UNUSED(value) { return { $: 0, a: value }; }
function _Json_unwrap_UNUSED(value) { return value.a; }

function _Json_wrap(value) { return value; }
function _Json_unwrap(value) { return value; }

function _Json_emptyArray() { return []; }
function _Json_emptyObject() { return {}; }

var _Json_addField = F3(function(key, value, object)
{
	object[key] = _Json_unwrap(value);
	return object;
});

function _Json_addEntry(func)
{
	return F2(function(entry, array)
	{
		array.push(_Json_unwrap(func(entry)));
		return array;
	});
}

var _Json_encodeNull = _Json_wrap(null);



// TASKS

function _Scheduler_succeed(value)
{
	return {
		$: 0,
		a: value
	};
}

function _Scheduler_fail(error)
{
	return {
		$: 1,
		a: error
	};
}

function _Scheduler_binding(callback)
{
	return {
		$: 2,
		b: callback,
		c: null
	};
}

var _Scheduler_andThen = F2(function(callback, task)
{
	return {
		$: 3,
		b: callback,
		d: task
	};
});

var _Scheduler_onError = F2(function(callback, task)
{
	return {
		$: 4,
		b: callback,
		d: task
	};
});

function _Scheduler_receive(callback)
{
	return {
		$: 5,
		b: callback
	};
}


// PROCESSES

var _Scheduler_guid = 0;

function _Scheduler_rawSpawn(task)
{
	var proc = {
		$: 0,
		e: _Scheduler_guid++,
		f: task,
		g: null,
		h: []
	};

	_Scheduler_enqueue(proc);

	return proc;
}

function _Scheduler_spawn(task)
{
	return _Scheduler_binding(function(callback) {
		callback(_Scheduler_succeed(_Scheduler_rawSpawn(task)));
	});
}

function _Scheduler_rawSend(proc, msg)
{
	proc.h.push(msg);
	_Scheduler_enqueue(proc);
}

var _Scheduler_send = F2(function(proc, msg)
{
	return _Scheduler_binding(function(callback) {
		_Scheduler_rawSend(proc, msg);
		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
});

function _Scheduler_kill(proc)
{
	return _Scheduler_binding(function(callback) {
		var task = proc.f;
		if (task.$ === 2 && task.c)
		{
			task.c();
		}

		proc.f = null;

		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
}


/* STEP PROCESSES

type alias Process =
  { $ : tag
  , id : unique_id
  , root : Task
  , stack : null | { $: SUCCEED | FAIL, a: callback, b: stack }
  , mailbox : [msg]
  }

*/


var _Scheduler_working = false;
var _Scheduler_queue = [];


function _Scheduler_enqueue(proc)
{
	_Scheduler_queue.push(proc);
	if (_Scheduler_working)
	{
		return;
	}
	_Scheduler_working = true;
	while (proc = _Scheduler_queue.shift())
	{
		_Scheduler_step(proc);
	}
	_Scheduler_working = false;
}


function _Scheduler_step(proc)
{
	while (proc.f)
	{
		var rootTag = proc.f.$;
		if (rootTag === 0 || rootTag === 1)
		{
			while (proc.g && proc.g.$ !== rootTag)
			{
				proc.g = proc.g.i;
			}
			if (!proc.g)
			{
				return;
			}
			proc.f = proc.g.b(proc.f.a);
			proc.g = proc.g.i;
		}
		else if (rootTag === 2)
		{
			proc.f.c = proc.f.b(function(newRoot) {
				proc.f = newRoot;
				_Scheduler_enqueue(proc);
			});
			return;
		}
		else if (rootTag === 5)
		{
			if (proc.h.length === 0)
			{
				return;
			}
			proc.f = proc.f.b(proc.h.shift());
		}
		else // if (rootTag === 3 || rootTag === 4)
		{
			proc.g = {
				$: rootTag === 3 ? 0 : 1,
				b: proc.f.b,
				i: proc.g
			};
			proc.f = proc.f.d;
		}
	}
}



function _Process_sleep(time)
{
	return _Scheduler_binding(function(callback) {
		var id = setTimeout(function() {
			callback(_Scheduler_succeed(_Utils_Tuple0));
		}, time);

		return function() { clearTimeout(id); };
	});
}




// PROGRAMS


var _Platform_worker = F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.dB,
		impl.ek,
		impl.ee,
		function() { return function() {} }
	);
});



// INITIALIZE A PROGRAM


function _Platform_initialize(flagDecoder, args, init, update, subscriptions, stepperBuilder)
{
	var result = A2(_Json_run, flagDecoder, _Json_wrap(args ? args['flags'] : undefined));
	$elm$core$Result$isOk(result) || _Debug_crash(2 /**_UNUSED/, _Json_errorToString(result.a) /**/);
	var managers = {};
	var initPair = init(result.a);
	var model = initPair.a;
	var stepper = stepperBuilder(sendToApp, model);
	var ports = _Platform_setupEffects(managers, sendToApp);

	function sendToApp(msg, viewMetadata)
	{
		var pair = A2(update, msg, model);
		stepper(model = pair.a, viewMetadata);
		_Platform_enqueueEffects(managers, pair.b, subscriptions(model));
	}

	_Platform_enqueueEffects(managers, initPair.b, subscriptions(model));

	return ports ? { ports: ports } : {};
}



// TRACK PRELOADS
//
// This is used by code in elm/browser and elm/http
// to register any HTTP requests that are triggered by init.
//


var _Platform_preload;


function _Platform_registerPreload(url)
{
	_Platform_preload.add(url);
}



// EFFECT MANAGERS


var _Platform_effectManagers = {};


function _Platform_setupEffects(managers, sendToApp)
{
	var ports;

	// setup all necessary effect managers
	for (var key in _Platform_effectManagers)
	{
		var manager = _Platform_effectManagers[key];

		if (manager.a)
		{
			ports = ports || {};
			ports[key] = manager.a(key, sendToApp);
		}

		managers[key] = _Platform_instantiateManager(manager, sendToApp);
	}

	return ports;
}


function _Platform_createManager(init, onEffects, onSelfMsg, cmdMap, subMap)
{
	return {
		b: init,
		c: onEffects,
		d: onSelfMsg,
		e: cmdMap,
		f: subMap
	};
}


function _Platform_instantiateManager(info, sendToApp)
{
	var router = {
		g: sendToApp,
		h: undefined
	};

	var onEffects = info.c;
	var onSelfMsg = info.d;
	var cmdMap = info.e;
	var subMap = info.f;

	function loop(state)
	{
		return A2(_Scheduler_andThen, loop, _Scheduler_receive(function(msg)
		{
			var value = msg.a;

			if (msg.$ === 0)
			{
				return A3(onSelfMsg, router, value, state);
			}

			return cmdMap && subMap
				? A4(onEffects, router, value.i, value.j, state)
				: A3(onEffects, router, cmdMap ? value.i : value.j, state);
		}));
	}

	return router.h = _Scheduler_rawSpawn(A2(_Scheduler_andThen, loop, info.b));
}



// ROUTING


var _Platform_sendToApp = F2(function(router, msg)
{
	return _Scheduler_binding(function(callback)
	{
		router.g(msg);
		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
});


var _Platform_sendToSelf = F2(function(router, msg)
{
	return A2(_Scheduler_send, router.h, {
		$: 0,
		a: msg
	});
});



// BAGS


function _Platform_leaf(home)
{
	return function(value)
	{
		return {
			$: 1,
			k: home,
			l: value
		};
	};
}


function _Platform_batch(list)
{
	return {
		$: 2,
		m: list
	};
}


var _Platform_map = F2(function(tagger, bag)
{
	return {
		$: 3,
		n: tagger,
		o: bag
	}
});



// PIPE BAGS INTO EFFECT MANAGERS
//
// Effects must be queued!
//
// Say your init contains a synchronous command, like Time.now or Time.here
//
//   - This will produce a batch of effects (FX_1)
//   - The synchronous task triggers the subsequent `update` call
//   - This will produce a batch of effects (FX_2)
//
// If we just start dispatching FX_2, subscriptions from FX_2 can be processed
// before subscriptions from FX_1. No good! Earlier versions of this code had
// this problem, leading to these reports:
//
//   https://github.com/elm/core/issues/980
//   https://github.com/elm/core/pull/981
//   https://github.com/elm/compiler/issues/1776
//
// The queue is necessary to avoid ordering issues for synchronous commands.


// Why use true/false here? Why not just check the length of the queue?
// The goal is to detect "are we currently dispatching effects?" If we
// are, we need to bail and let the ongoing while loop handle things.
//
// Now say the queue has 1 element. When we dequeue the final element,
// the queue will be empty, but we are still actively dispatching effects.
// So you could get queue jumping in a really tricky category of cases.
//
var _Platform_effectsQueue = [];
var _Platform_effectsActive = false;


function _Platform_enqueueEffects(managers, cmdBag, subBag)
{
	_Platform_effectsQueue.push({ p: managers, q: cmdBag, r: subBag });

	if (_Platform_effectsActive) return;

	_Platform_effectsActive = true;
	for (var fx; fx = _Platform_effectsQueue.shift(); )
	{
		_Platform_dispatchEffects(fx.p, fx.q, fx.r);
	}
	_Platform_effectsActive = false;
}


function _Platform_dispatchEffects(managers, cmdBag, subBag)
{
	var effectsDict = {};
	_Platform_gatherEffects(true, cmdBag, effectsDict, null);
	_Platform_gatherEffects(false, subBag, effectsDict, null);

	for (var home in managers)
	{
		_Scheduler_rawSend(managers[home], {
			$: 'fx',
			a: effectsDict[home] || { i: _List_Nil, j: _List_Nil }
		});
	}
}


function _Platform_gatherEffects(isCmd, bag, effectsDict, taggers)
{
	switch (bag.$)
	{
		case 1:
			var home = bag.k;
			var effect = _Platform_toEffect(isCmd, home, taggers, bag.l);
			effectsDict[home] = _Platform_insert(isCmd, effect, effectsDict[home]);
			return;

		case 2:
			for (var list = bag.m; list.b; list = list.b) // WHILE_CONS
			{
				_Platform_gatherEffects(isCmd, list.a, effectsDict, taggers);
			}
			return;

		case 3:
			_Platform_gatherEffects(isCmd, bag.o, effectsDict, {
				s: bag.n,
				t: taggers
			});
			return;
	}
}


function _Platform_toEffect(isCmd, home, taggers, value)
{
	function applyTaggers(x)
	{
		for (var temp = taggers; temp; temp = temp.t)
		{
			x = temp.s(x);
		}
		return x;
	}

	var map = isCmd
		? _Platform_effectManagers[home].e
		: _Platform_effectManagers[home].f;

	return A2(map, applyTaggers, value)
}


function _Platform_insert(isCmd, newEffect, effects)
{
	effects = effects || { i: _List_Nil, j: _List_Nil };

	isCmd
		? (effects.i = _List_Cons(newEffect, effects.i))
		: (effects.j = _List_Cons(newEffect, effects.j));

	return effects;
}



// PORTS


function _Platform_checkPortName(name)
{
	if (_Platform_effectManagers[name])
	{
		_Debug_crash(3, name)
	}
}



// OUTGOING PORTS


function _Platform_outgoingPort(name, converter)
{
	_Platform_checkPortName(name);
	_Platform_effectManagers[name] = {
		e: _Platform_outgoingPortMap,
		u: converter,
		a: _Platform_setupOutgoingPort
	};
	return _Platform_leaf(name);
}


var _Platform_outgoingPortMap = F2(function(tagger, value) { return value; });


function _Platform_setupOutgoingPort(name)
{
	var subs = [];
	var converter = _Platform_effectManagers[name].u;

	// CREATE MANAGER

	var init = _Process_sleep(0);

	_Platform_effectManagers[name].b = init;
	_Platform_effectManagers[name].c = F3(function(router, cmdList, state)
	{
		for ( ; cmdList.b; cmdList = cmdList.b) // WHILE_CONS
		{
			// grab a separate reference to subs in case unsubscribe is called
			var currentSubs = subs;
			var value = _Json_unwrap(converter(cmdList.a));
			for (var i = 0; i < currentSubs.length; i++)
			{
				currentSubs[i](value);
			}
		}
		return init;
	});

	// PUBLIC API

	function subscribe(callback)
	{
		subs.push(callback);
	}

	function unsubscribe(callback)
	{
		// copy subs into a new array in case unsubscribe is called within a
		// subscribed callback
		subs = subs.slice();
		var index = subs.indexOf(callback);
		if (index >= 0)
		{
			subs.splice(index, 1);
		}
	}

	return {
		subscribe: subscribe,
		unsubscribe: unsubscribe
	};
}



// INCOMING PORTS


function _Platform_incomingPort(name, converter)
{
	_Platform_checkPortName(name);
	_Platform_effectManagers[name] = {
		f: _Platform_incomingPortMap,
		u: converter,
		a: _Platform_setupIncomingPort
	};
	return _Platform_leaf(name);
}


var _Platform_incomingPortMap = F2(function(tagger, finalTagger)
{
	return function(value)
	{
		return tagger(finalTagger(value));
	};
});


function _Platform_setupIncomingPort(name, sendToApp)
{
	var subs = _List_Nil;
	var converter = _Platform_effectManagers[name].u;

	// CREATE MANAGER

	var init = _Scheduler_succeed(null);

	_Platform_effectManagers[name].b = init;
	_Platform_effectManagers[name].c = F3(function(router, subList, state)
	{
		subs = subList;
		return init;
	});

	// PUBLIC API

	function send(incomingValue)
	{
		var result = A2(_Json_run, converter, _Json_wrap(incomingValue));

		$elm$core$Result$isOk(result) || _Debug_crash(4, name, result.a);

		var value = result.a;
		for (var temp = subs; temp.b; temp = temp.b) // WHILE_CONS
		{
			sendToApp(temp.a(value));
		}
	}

	return { send: send };
}



// EXPORT ELM MODULES
//
// Have DEBUG and PROD versions so that we can (1) give nicer errors in
// debug mode and (2) not pay for the bits needed for that in prod mode.
//


function _Platform_export(exports)
{
	scope['Elm']
		? _Platform_mergeExportsProd(scope['Elm'], exports)
		: scope['Elm'] = exports;
}


function _Platform_mergeExportsProd(obj, exports)
{
	for (var name in exports)
	{
		(name in obj)
			? (name == 'init')
				? _Debug_crash(6)
				: _Platform_mergeExportsProd(obj[name], exports[name])
			: (obj[name] = exports[name]);
	}
}


function _Platform_export_UNUSED(exports)
{
	scope['Elm']
		? _Platform_mergeExportsDebug('Elm', scope['Elm'], exports)
		: scope['Elm'] = exports;
}


function _Platform_mergeExportsDebug(moduleName, obj, exports)
{
	for (var name in exports)
	{
		(name in obj)
			? (name == 'init')
				? _Debug_crash(6, moduleName)
				: _Platform_mergeExportsDebug(moduleName + '.' + name, obj[name], exports[name])
			: (obj[name] = exports[name]);
	}
}




// HELPERS


var _VirtualDom_divertHrefToApp;

var _VirtualDom_doc = typeof document !== 'undefined' ? document : {};


function _VirtualDom_appendChild(parent, child)
{
	parent.appendChild(child);
}

var _VirtualDom_init = F4(function(virtualNode, flagDecoder, debugMetadata, args)
{
	// NOTE: this function needs _Platform_export available to work

	/**/
	var node = args['node'];
	//*/
	/**_UNUSED/
	var node = args && args['node'] ? args['node'] : _Debug_crash(0);
	//*/

	node.parentNode.replaceChild(
		_VirtualDom_render(virtualNode, function() {}),
		node
	);

	return {};
});



// TEXT


function _VirtualDom_text(string)
{
	return {
		$: 0,
		a: string
	};
}



// NODE


var _VirtualDom_nodeNS = F2(function(namespace, tag)
{
	return F2(function(factList, kidList)
	{
		for (var kids = [], descendantsCount = 0; kidList.b; kidList = kidList.b) // WHILE_CONS
		{
			var kid = kidList.a;
			descendantsCount += (kid.b || 0);
			kids.push(kid);
		}
		descendantsCount += kids.length;

		return {
			$: 1,
			c: tag,
			d: _VirtualDom_organizeFacts(factList),
			e: kids,
			f: namespace,
			b: descendantsCount
		};
	});
});


var _VirtualDom_node = _VirtualDom_nodeNS(undefined);



// KEYED NODE


var _VirtualDom_keyedNodeNS = F2(function(namespace, tag)
{
	return F2(function(factList, kidList)
	{
		for (var kids = [], descendantsCount = 0; kidList.b; kidList = kidList.b) // WHILE_CONS
		{
			var kid = kidList.a;
			descendantsCount += (kid.b.b || 0);
			kids.push(kid);
		}
		descendantsCount += kids.length;

		return {
			$: 2,
			c: tag,
			d: _VirtualDom_organizeFacts(factList),
			e: kids,
			f: namespace,
			b: descendantsCount
		};
	});
});


var _VirtualDom_keyedNode = _VirtualDom_keyedNodeNS(undefined);



// CUSTOM


function _VirtualDom_custom(factList, model, render, diff)
{
	return {
		$: 3,
		d: _VirtualDom_organizeFacts(factList),
		g: model,
		h: render,
		i: diff
	};
}



// MAP


var _VirtualDom_map = F2(function(tagger, node)
{
	return {
		$: 4,
		j: tagger,
		k: node,
		b: 1 + (node.b || 0)
	};
});



// LAZY


function _VirtualDom_thunk(refs, thunk)
{
	return {
		$: 5,
		l: refs,
		m: thunk,
		k: undefined
	};
}

var _VirtualDom_lazy = F2(function(func, a)
{
	return _VirtualDom_thunk([func, a], function() {
		return func(a);
	});
});

var _VirtualDom_lazy2 = F3(function(func, a, b)
{
	return _VirtualDom_thunk([func, a, b], function() {
		return A2(func, a, b);
	});
});

var _VirtualDom_lazy3 = F4(function(func, a, b, c)
{
	return _VirtualDom_thunk([func, a, b, c], function() {
		return A3(func, a, b, c);
	});
});

var _VirtualDom_lazy4 = F5(function(func, a, b, c, d)
{
	return _VirtualDom_thunk([func, a, b, c, d], function() {
		return A4(func, a, b, c, d);
	});
});

var _VirtualDom_lazy5 = F6(function(func, a, b, c, d, e)
{
	return _VirtualDom_thunk([func, a, b, c, d, e], function() {
		return A5(func, a, b, c, d, e);
	});
});

var _VirtualDom_lazy6 = F7(function(func, a, b, c, d, e, f)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f], function() {
		return A6(func, a, b, c, d, e, f);
	});
});

var _VirtualDom_lazy7 = F8(function(func, a, b, c, d, e, f, g)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f, g], function() {
		return A7(func, a, b, c, d, e, f, g);
	});
});

var _VirtualDom_lazy8 = F9(function(func, a, b, c, d, e, f, g, h)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f, g, h], function() {
		return A8(func, a, b, c, d, e, f, g, h);
	});
});



// FACTS


var _VirtualDom_on = F2(function(key, handler)
{
	return {
		$: 'a0',
		n: key,
		o: handler
	};
});
var _VirtualDom_style = F2(function(key, value)
{
	return {
		$: 'a1',
		n: key,
		o: value
	};
});
var _VirtualDom_property = F2(function(key, value)
{
	return {
		$: 'a2',
		n: key,
		o: value
	};
});
var _VirtualDom_attribute = F2(function(key, value)
{
	return {
		$: 'a3',
		n: key,
		o: value
	};
});
var _VirtualDom_attributeNS = F3(function(namespace, key, value)
{
	return {
		$: 'a4',
		n: key,
		o: { f: namespace, o: value }
	};
});



// XSS ATTACK VECTOR CHECKS


function _VirtualDom_noScript(tag)
{
	return tag == 'script' ? 'p' : tag;
}

function _VirtualDom_noOnOrFormAction(key)
{
	return /^(on|formAction$)/i.test(key) ? 'data-' + key : key;
}

function _VirtualDom_noInnerHtmlOrFormAction(key)
{
	return key == 'innerHTML' || key == 'formAction' ? 'data-' + key : key;
}

function _VirtualDom_noJavaScriptUri(value)
{
	return /^javascript:/i.test(value.replace(/\s/g,'')) ? '' : value;
}

function _VirtualDom_noJavaScriptUri_UNUSED(value)
{
	return /^javascript:/i.test(value.replace(/\s/g,''))
		? 'javascript:alert("This is an XSS vector. Please use ports or web components instead.")'
		: value;
}

function _VirtualDom_noJavaScriptOrHtmlUri(value)
{
	return /^\s*(javascript:|data:text\/html)/i.test(value) ? '' : value;
}

function _VirtualDom_noJavaScriptOrHtmlUri_UNUSED(value)
{
	return /^\s*(javascript:|data:text\/html)/i.test(value)
		? 'javascript:alert("This is an XSS vector. Please use ports or web components instead.")'
		: value;
}



// MAP FACTS


var _VirtualDom_mapAttribute = F2(function(func, attr)
{
	return (attr.$ === 'a0')
		? A2(_VirtualDom_on, attr.n, _VirtualDom_mapHandler(func, attr.o))
		: attr;
});

function _VirtualDom_mapHandler(func, handler)
{
	var tag = $elm$virtual_dom$VirtualDom$toHandlerInt(handler);

	// 0 = Normal
	// 1 = MayStopPropagation
	// 2 = MayPreventDefault
	// 3 = Custom

	return {
		$: handler.$,
		a:
			!tag
				? A2($elm$json$Json$Decode$map, func, handler.a)
				:
			A3($elm$json$Json$Decode$map2,
				tag < 3
					? _VirtualDom_mapEventTuple
					: _VirtualDom_mapEventRecord,
				$elm$json$Json$Decode$succeed(func),
				handler.a
			)
	};
}

var _VirtualDom_mapEventTuple = F2(function(func, tuple)
{
	return _Utils_Tuple2(func(tuple.a), tuple.b);
});

var _VirtualDom_mapEventRecord = F2(function(func, record)
{
	return {
		dL: func(record.dL),
		ec: record.ec,
		d$: record.d$
	}
});



// ORGANIZE FACTS


function _VirtualDom_organizeFacts(factList)
{
	for (var facts = {}; factList.b; factList = factList.b) // WHILE_CONS
	{
		var entry = factList.a;

		var tag = entry.$;
		var key = entry.n;
		var value = entry.o;

		if (tag === 'a2')
		{
			(key === 'className')
				? _VirtualDom_addClass(facts, key, _Json_unwrap(value))
				: facts[key] = _Json_unwrap(value);

			continue;
		}

		var subFacts = facts[tag] || (facts[tag] = {});
		(tag === 'a3' && key === 'class')
			? _VirtualDom_addClass(subFacts, key, value)
			: subFacts[key] = value;
	}

	return facts;
}

function _VirtualDom_addClass(object, key, newClass)
{
	var classes = object[key];
	object[key] = classes ? classes + ' ' + newClass : newClass;
}



// RENDER


function _VirtualDom_render(vNode, eventNode)
{
	var tag = vNode.$;

	if (tag === 5)
	{
		return _VirtualDom_render(vNode.k || (vNode.k = vNode.m()), eventNode);
	}

	if (tag === 0)
	{
		return _VirtualDom_doc.createTextNode(vNode.a);
	}

	if (tag === 4)
	{
		var subNode = vNode.k;
		var tagger = vNode.j;

		while (subNode.$ === 4)
		{
			typeof tagger !== 'object'
				? tagger = [tagger, subNode.j]
				: tagger.push(subNode.j);

			subNode = subNode.k;
		}

		var subEventRoot = { j: tagger, p: eventNode };
		var domNode = _VirtualDom_render(subNode, subEventRoot);
		domNode.elm_event_node_ref = subEventRoot;
		return domNode;
	}

	if (tag === 3)
	{
		var domNode = vNode.h(vNode.g);
		_VirtualDom_applyFacts(domNode, eventNode, vNode.d);
		return domNode;
	}

	// at this point `tag` must be 1 or 2

	var domNode = vNode.f
		? _VirtualDom_doc.createElementNS(vNode.f, vNode.c)
		: _VirtualDom_doc.createElement(vNode.c);

	if (_VirtualDom_divertHrefToApp && vNode.c == 'a')
	{
		domNode.addEventListener('click', _VirtualDom_divertHrefToApp(domNode));
	}

	_VirtualDom_applyFacts(domNode, eventNode, vNode.d);

	for (var kids = vNode.e, i = 0; i < kids.length; i++)
	{
		_VirtualDom_appendChild(domNode, _VirtualDom_render(tag === 1 ? kids[i] : kids[i].b, eventNode));
	}

	return domNode;
}



// APPLY FACTS


function _VirtualDom_applyFacts(domNode, eventNode, facts)
{
	for (var key in facts)
	{
		var value = facts[key];

		key === 'a1'
			? _VirtualDom_applyStyles(domNode, value)
			:
		key === 'a0'
			? _VirtualDom_applyEvents(domNode, eventNode, value)
			:
		key === 'a3'
			? _VirtualDom_applyAttrs(domNode, value)
			:
		key === 'a4'
			? _VirtualDom_applyAttrsNS(domNode, value)
			:
		((key !== 'value' && key !== 'checked') || domNode[key] !== value) && (domNode[key] = value);
	}
}



// APPLY STYLES


function _VirtualDom_applyStyles(domNode, styles)
{
	var domNodeStyle = domNode.style;

	for (var key in styles)
	{
		domNodeStyle[key] = styles[key];
	}
}



// APPLY ATTRS


function _VirtualDom_applyAttrs(domNode, attrs)
{
	for (var key in attrs)
	{
		var value = attrs[key];
		typeof value !== 'undefined'
			? domNode.setAttribute(key, value)
			: domNode.removeAttribute(key);
	}
}



// APPLY NAMESPACED ATTRS


function _VirtualDom_applyAttrsNS(domNode, nsAttrs)
{
	for (var key in nsAttrs)
	{
		var pair = nsAttrs[key];
		var namespace = pair.f;
		var value = pair.o;

		typeof value !== 'undefined'
			? domNode.setAttributeNS(namespace, key, value)
			: domNode.removeAttributeNS(namespace, key);
	}
}



// APPLY EVENTS


function _VirtualDom_applyEvents(domNode, eventNode, events)
{
	var allCallbacks = domNode.elmFs || (domNode.elmFs = {});

	for (var key in events)
	{
		var newHandler = events[key];
		var oldCallback = allCallbacks[key];

		if (!newHandler)
		{
			domNode.removeEventListener(key, oldCallback);
			allCallbacks[key] = undefined;
			continue;
		}

		if (oldCallback)
		{
			var oldHandler = oldCallback.q;
			if (oldHandler.$ === newHandler.$)
			{
				oldCallback.q = newHandler;
				continue;
			}
			domNode.removeEventListener(key, oldCallback);
		}

		oldCallback = _VirtualDom_makeCallback(eventNode, newHandler);
		domNode.addEventListener(key, oldCallback,
			_VirtualDom_passiveSupported
			&& { passive: $elm$virtual_dom$VirtualDom$toHandlerInt(newHandler) < 2 }
		);
		allCallbacks[key] = oldCallback;
	}
}



// PASSIVE EVENTS


var _VirtualDom_passiveSupported;

try
{
	window.addEventListener('t', null, Object.defineProperty({}, 'passive', {
		get: function() { _VirtualDom_passiveSupported = true; }
	}));
}
catch(e) {}



// EVENT HANDLERS


function _VirtualDom_makeCallback(eventNode, initialHandler)
{
	function callback(event)
	{
		var handler = callback.q;
		var result = _Json_runHelp(handler.a, event);

		if (!$elm$core$Result$isOk(result))
		{
			return;
		}

		var tag = $elm$virtual_dom$VirtualDom$toHandlerInt(handler);

		// 0 = Normal
		// 1 = MayStopPropagation
		// 2 = MayPreventDefault
		// 3 = Custom

		var value = result.a;
		var message = !tag ? value : tag < 3 ? value.a : value.dL;
		var stopPropagation = tag == 1 ? value.b : tag == 3 && value.ec;
		var currentEventNode = (
			stopPropagation && event.stopPropagation(),
			(tag == 2 ? value.b : tag == 3 && value.d$) && event.preventDefault(),
			eventNode
		);
		var tagger;
		var i;
		while (tagger = currentEventNode.j)
		{
			if (typeof tagger == 'function')
			{
				message = tagger(message);
			}
			else
			{
				for (var i = tagger.length; i--; )
				{
					message = tagger[i](message);
				}
			}
			currentEventNode = currentEventNode.p;
		}
		currentEventNode(message, stopPropagation); // stopPropagation implies isSync
	}

	callback.q = initialHandler;

	return callback;
}

function _VirtualDom_equalEvents(x, y)
{
	return x.$ == y.$ && _Json_equality(x.a, y.a);
}



// DIFF


// TODO: Should we do patches like in iOS?
//
// type Patch
//   = At Int Patch
//   | Batch (List Patch)
//   | Change ...
//
// How could it not be better?
//
function _VirtualDom_diff(x, y)
{
	var patches = [];
	_VirtualDom_diffHelp(x, y, patches, 0);
	return patches;
}


function _VirtualDom_pushPatch(patches, type, index, data)
{
	var patch = {
		$: type,
		r: index,
		s: data,
		t: undefined,
		u: undefined
	};
	patches.push(patch);
	return patch;
}


function _VirtualDom_diffHelp(x, y, patches, index)
{
	if (x === y)
	{
		return;
	}

	var xType = x.$;
	var yType = y.$;

	// Bail if you run into different types of nodes. Implies that the
	// structure has changed significantly and it's not worth a diff.
	if (xType !== yType)
	{
		if (xType === 1 && yType === 2)
		{
			y = _VirtualDom_dekey(y);
			yType = 1;
		}
		else
		{
			_VirtualDom_pushPatch(patches, 0, index, y);
			return;
		}
	}

	// Now we know that both nodes are the same $.
	switch (yType)
	{
		case 5:
			var xRefs = x.l;
			var yRefs = y.l;
			var i = xRefs.length;
			var same = i === yRefs.length;
			while (same && i--)
			{
				same = xRefs[i] === yRefs[i];
			}
			if (same)
			{
				y.k = x.k;
				return;
			}
			y.k = y.m();
			var subPatches = [];
			_VirtualDom_diffHelp(x.k, y.k, subPatches, 0);
			subPatches.length > 0 && _VirtualDom_pushPatch(patches, 1, index, subPatches);
			return;

		case 4:
			// gather nested taggers
			var xTaggers = x.j;
			var yTaggers = y.j;
			var nesting = false;

			var xSubNode = x.k;
			while (xSubNode.$ === 4)
			{
				nesting = true;

				typeof xTaggers !== 'object'
					? xTaggers = [xTaggers, xSubNode.j]
					: xTaggers.push(xSubNode.j);

				xSubNode = xSubNode.k;
			}

			var ySubNode = y.k;
			while (ySubNode.$ === 4)
			{
				nesting = true;

				typeof yTaggers !== 'object'
					? yTaggers = [yTaggers, ySubNode.j]
					: yTaggers.push(ySubNode.j);

				ySubNode = ySubNode.k;
			}

			// Just bail if different numbers of taggers. This implies the
			// structure of the virtual DOM has changed.
			if (nesting && xTaggers.length !== yTaggers.length)
			{
				_VirtualDom_pushPatch(patches, 0, index, y);
				return;
			}

			// check if taggers are "the same"
			if (nesting ? !_VirtualDom_pairwiseRefEqual(xTaggers, yTaggers) : xTaggers !== yTaggers)
			{
				_VirtualDom_pushPatch(patches, 2, index, yTaggers);
			}

			// diff everything below the taggers
			_VirtualDom_diffHelp(xSubNode, ySubNode, patches, index + 1);
			return;

		case 0:
			if (x.a !== y.a)
			{
				_VirtualDom_pushPatch(patches, 3, index, y.a);
			}
			return;

		case 1:
			_VirtualDom_diffNodes(x, y, patches, index, _VirtualDom_diffKids);
			return;

		case 2:
			_VirtualDom_diffNodes(x, y, patches, index, _VirtualDom_diffKeyedKids);
			return;

		case 3:
			if (x.h !== y.h)
			{
				_VirtualDom_pushPatch(patches, 0, index, y);
				return;
			}

			var factsDiff = _VirtualDom_diffFacts(x.d, y.d);
			factsDiff && _VirtualDom_pushPatch(patches, 4, index, factsDiff);

			var patch = y.i(x.g, y.g);
			patch && _VirtualDom_pushPatch(patches, 5, index, patch);

			return;
	}
}

// assumes the incoming arrays are the same length
function _VirtualDom_pairwiseRefEqual(as, bs)
{
	for (var i = 0; i < as.length; i++)
	{
		if (as[i] !== bs[i])
		{
			return false;
		}
	}

	return true;
}

function _VirtualDom_diffNodes(x, y, patches, index, diffKids)
{
	// Bail if obvious indicators have changed. Implies more serious
	// structural changes such that it's not worth it to diff.
	if (x.c !== y.c || x.f !== y.f)
	{
		_VirtualDom_pushPatch(patches, 0, index, y);
		return;
	}

	var factsDiff = _VirtualDom_diffFacts(x.d, y.d);
	factsDiff && _VirtualDom_pushPatch(patches, 4, index, factsDiff);

	diffKids(x, y, patches, index);
}



// DIFF FACTS


// TODO Instead of creating a new diff object, it's possible to just test if
// there *is* a diff. During the actual patch, do the diff again and make the
// modifications directly. This way, there's no new allocations. Worth it?
function _VirtualDom_diffFacts(x, y, category)
{
	var diff;

	// look for changes and removals
	for (var xKey in x)
	{
		if (xKey === 'a1' || xKey === 'a0' || xKey === 'a3' || xKey === 'a4')
		{
			var subDiff = _VirtualDom_diffFacts(x[xKey], y[xKey] || {}, xKey);
			if (subDiff)
			{
				diff = diff || {};
				diff[xKey] = subDiff;
			}
			continue;
		}

		// remove if not in the new facts
		if (!(xKey in y))
		{
			diff = diff || {};
			diff[xKey] =
				!category
					? (typeof x[xKey] === 'string' ? '' : null)
					:
				(category === 'a1')
					? ''
					:
				(category === 'a0' || category === 'a3')
					? undefined
					:
				{ f: x[xKey].f, o: undefined };

			continue;
		}

		var xValue = x[xKey];
		var yValue = y[xKey];

		// reference equal, so don't worry about it
		if (xValue === yValue && xKey !== 'value' && xKey !== 'checked'
			|| category === 'a0' && _VirtualDom_equalEvents(xValue, yValue))
		{
			continue;
		}

		diff = diff || {};
		diff[xKey] = yValue;
	}

	// add new stuff
	for (var yKey in y)
	{
		if (!(yKey in x))
		{
			diff = diff || {};
			diff[yKey] = y[yKey];
		}
	}

	return diff;
}



// DIFF KIDS


function _VirtualDom_diffKids(xParent, yParent, patches, index)
{
	var xKids = xParent.e;
	var yKids = yParent.e;

	var xLen = xKids.length;
	var yLen = yKids.length;

	// FIGURE OUT IF THERE ARE INSERTS OR REMOVALS

	if (xLen > yLen)
	{
		_VirtualDom_pushPatch(patches, 6, index, {
			v: yLen,
			i: xLen - yLen
		});
	}
	else if (xLen < yLen)
	{
		_VirtualDom_pushPatch(patches, 7, index, {
			v: xLen,
			e: yKids
		});
	}

	// PAIRWISE DIFF EVERYTHING ELSE

	for (var minLen = xLen < yLen ? xLen : yLen, i = 0; i < minLen; i++)
	{
		var xKid = xKids[i];
		_VirtualDom_diffHelp(xKid, yKids[i], patches, ++index);
		index += xKid.b || 0;
	}
}



// KEYED DIFF


function _VirtualDom_diffKeyedKids(xParent, yParent, patches, rootIndex)
{
	var localPatches = [];

	var changes = {}; // Dict String Entry
	var inserts = []; // Array { index : Int, entry : Entry }
	// type Entry = { tag : String, vnode : VNode, index : Int, data : _ }

	var xKids = xParent.e;
	var yKids = yParent.e;
	var xLen = xKids.length;
	var yLen = yKids.length;
	var xIndex = 0;
	var yIndex = 0;

	var index = rootIndex;

	while (xIndex < xLen && yIndex < yLen)
	{
		var x = xKids[xIndex];
		var y = yKids[yIndex];

		var xKey = x.a;
		var yKey = y.a;
		var xNode = x.b;
		var yNode = y.b;

		var newMatch = undefined;
		var oldMatch = undefined;

		// check if keys match

		if (xKey === yKey)
		{
			index++;
			_VirtualDom_diffHelp(xNode, yNode, localPatches, index);
			index += xNode.b || 0;

			xIndex++;
			yIndex++;
			continue;
		}

		// look ahead 1 to detect insertions and removals.

		var xNext = xKids[xIndex + 1];
		var yNext = yKids[yIndex + 1];

		if (xNext)
		{
			var xNextKey = xNext.a;
			var xNextNode = xNext.b;
			oldMatch = yKey === xNextKey;
		}

		if (yNext)
		{
			var yNextKey = yNext.a;
			var yNextNode = yNext.b;
			newMatch = xKey === yNextKey;
		}


		// swap x and y
		if (newMatch && oldMatch)
		{
			index++;
			_VirtualDom_diffHelp(xNode, yNextNode, localPatches, index);
			_VirtualDom_insertNode(changes, localPatches, xKey, yNode, yIndex, inserts);
			index += xNode.b || 0;

			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNextNode, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 2;
			continue;
		}

		// insert y
		if (newMatch)
		{
			index++;
			_VirtualDom_insertNode(changes, localPatches, yKey, yNode, yIndex, inserts);
			_VirtualDom_diffHelp(xNode, yNextNode, localPatches, index);
			index += xNode.b || 0;

			xIndex += 1;
			yIndex += 2;
			continue;
		}

		// remove x
		if (oldMatch)
		{
			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNode, index);
			index += xNode.b || 0;

			index++;
			_VirtualDom_diffHelp(xNextNode, yNode, localPatches, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 1;
			continue;
		}

		// remove x, insert y
		if (xNext && xNextKey === yNextKey)
		{
			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNode, index);
			_VirtualDom_insertNode(changes, localPatches, yKey, yNode, yIndex, inserts);
			index += xNode.b || 0;

			index++;
			_VirtualDom_diffHelp(xNextNode, yNextNode, localPatches, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 2;
			continue;
		}

		break;
	}

	// eat up any remaining nodes with removeNode and insertNode

	while (xIndex < xLen)
	{
		index++;
		var x = xKids[xIndex];
		var xNode = x.b;
		_VirtualDom_removeNode(changes, localPatches, x.a, xNode, index);
		index += xNode.b || 0;
		xIndex++;
	}

	while (yIndex < yLen)
	{
		var endInserts = endInserts || [];
		var y = yKids[yIndex];
		_VirtualDom_insertNode(changes, localPatches, y.a, y.b, undefined, endInserts);
		yIndex++;
	}

	if (localPatches.length > 0 || inserts.length > 0 || endInserts)
	{
		_VirtualDom_pushPatch(patches, 8, rootIndex, {
			w: localPatches,
			x: inserts,
			y: endInserts
		});
	}
}



// CHANGES FROM KEYED DIFF


var _VirtualDom_POSTFIX = '_elmW6BL';


function _VirtualDom_insertNode(changes, localPatches, key, vnode, yIndex, inserts)
{
	var entry = changes[key];

	// never seen this key before
	if (!entry)
	{
		entry = {
			c: 0,
			z: vnode,
			r: yIndex,
			s: undefined
		};

		inserts.push({ r: yIndex, A: entry });
		changes[key] = entry;

		return;
	}

	// this key was removed earlier, a match!
	if (entry.c === 1)
	{
		inserts.push({ r: yIndex, A: entry });

		entry.c = 2;
		var subPatches = [];
		_VirtualDom_diffHelp(entry.z, vnode, subPatches, entry.r);
		entry.r = yIndex;
		entry.s.s = {
			w: subPatches,
			A: entry
		};

		return;
	}

	// this key has already been inserted or moved, a duplicate!
	_VirtualDom_insertNode(changes, localPatches, key + _VirtualDom_POSTFIX, vnode, yIndex, inserts);
}


function _VirtualDom_removeNode(changes, localPatches, key, vnode, index)
{
	var entry = changes[key];

	// never seen this key before
	if (!entry)
	{
		var patch = _VirtualDom_pushPatch(localPatches, 9, index, undefined);

		changes[key] = {
			c: 1,
			z: vnode,
			r: index,
			s: patch
		};

		return;
	}

	// this key was inserted earlier, a match!
	if (entry.c === 0)
	{
		entry.c = 2;
		var subPatches = [];
		_VirtualDom_diffHelp(vnode, entry.z, subPatches, index);

		_VirtualDom_pushPatch(localPatches, 9, index, {
			w: subPatches,
			A: entry
		});

		return;
	}

	// this key has already been removed or moved, a duplicate!
	_VirtualDom_removeNode(changes, localPatches, key + _VirtualDom_POSTFIX, vnode, index);
}



// ADD DOM NODES
//
// Each DOM node has an "index" assigned in order of traversal. It is important
// to minimize our crawl over the actual DOM, so these indexes (along with the
// descendantsCount of virtual nodes) let us skip touching entire subtrees of
// the DOM if we know there are no patches there.


function _VirtualDom_addDomNodes(domNode, vNode, patches, eventNode)
{
	_VirtualDom_addDomNodesHelp(domNode, vNode, patches, 0, 0, vNode.b, eventNode);
}


// assumes `patches` is non-empty and indexes increase monotonically.
function _VirtualDom_addDomNodesHelp(domNode, vNode, patches, i, low, high, eventNode)
{
	var patch = patches[i];
	var index = patch.r;

	while (index === low)
	{
		var patchType = patch.$;

		if (patchType === 1)
		{
			_VirtualDom_addDomNodes(domNode, vNode.k, patch.s, eventNode);
		}
		else if (patchType === 8)
		{
			patch.t = domNode;
			patch.u = eventNode;

			var subPatches = patch.s.w;
			if (subPatches.length > 0)
			{
				_VirtualDom_addDomNodesHelp(domNode, vNode, subPatches, 0, low, high, eventNode);
			}
		}
		else if (patchType === 9)
		{
			patch.t = domNode;
			patch.u = eventNode;

			var data = patch.s;
			if (data)
			{
				data.A.s = domNode;
				var subPatches = data.w;
				if (subPatches.length > 0)
				{
					_VirtualDom_addDomNodesHelp(domNode, vNode, subPatches, 0, low, high, eventNode);
				}
			}
		}
		else
		{
			patch.t = domNode;
			patch.u = eventNode;
		}

		i++;

		if (!(patch = patches[i]) || (index = patch.r) > high)
		{
			return i;
		}
	}

	var tag = vNode.$;

	if (tag === 4)
	{
		var subNode = vNode.k;

		while (subNode.$ === 4)
		{
			subNode = subNode.k;
		}

		return _VirtualDom_addDomNodesHelp(domNode, subNode, patches, i, low + 1, high, domNode.elm_event_node_ref);
	}

	// tag must be 1 or 2 at this point

	var vKids = vNode.e;
	var childNodes = domNode.childNodes;
	for (var j = 0; j < vKids.length; j++)
	{
		low++;
		var vKid = tag === 1 ? vKids[j] : vKids[j].b;
		var nextLow = low + (vKid.b || 0);
		if (low <= index && index <= nextLow)
		{
			i = _VirtualDom_addDomNodesHelp(childNodes[j], vKid, patches, i, low, nextLow, eventNode);
			if (!(patch = patches[i]) || (index = patch.r) > high)
			{
				return i;
			}
		}
		low = nextLow;
	}
	return i;
}



// APPLY PATCHES


function _VirtualDom_applyPatches(rootDomNode, oldVirtualNode, patches, eventNode)
{
	if (patches.length === 0)
	{
		return rootDomNode;
	}

	_VirtualDom_addDomNodes(rootDomNode, oldVirtualNode, patches, eventNode);
	return _VirtualDom_applyPatchesHelp(rootDomNode, patches);
}

function _VirtualDom_applyPatchesHelp(rootDomNode, patches)
{
	for (var i = 0; i < patches.length; i++)
	{
		var patch = patches[i];
		var localDomNode = patch.t
		var newNode = _VirtualDom_applyPatch(localDomNode, patch);
		if (localDomNode === rootDomNode)
		{
			rootDomNode = newNode;
		}
	}
	return rootDomNode;
}

function _VirtualDom_applyPatch(domNode, patch)
{
	switch (patch.$)
	{
		case 0:
			return _VirtualDom_applyPatchRedraw(domNode, patch.s, patch.u);

		case 4:
			_VirtualDom_applyFacts(domNode, patch.u, patch.s);
			return domNode;

		case 3:
			domNode.replaceData(0, domNode.length, patch.s);
			return domNode;

		case 1:
			return _VirtualDom_applyPatchesHelp(domNode, patch.s);

		case 2:
			if (domNode.elm_event_node_ref)
			{
				domNode.elm_event_node_ref.j = patch.s;
			}
			else
			{
				domNode.elm_event_node_ref = { j: patch.s, p: patch.u };
			}
			return domNode;

		case 6:
			var data = patch.s;
			for (var i = 0; i < data.i; i++)
			{
				domNode.removeChild(domNode.childNodes[data.v]);
			}
			return domNode;

		case 7:
			var data = patch.s;
			var kids = data.e;
			var i = data.v;
			var theEnd = domNode.childNodes[i];
			for (; i < kids.length; i++)
			{
				domNode.insertBefore(_VirtualDom_render(kids[i], patch.u), theEnd);
			}
			return domNode;

		case 9:
			var data = patch.s;
			if (!data)
			{
				domNode.parentNode.removeChild(domNode);
				return domNode;
			}
			var entry = data.A;
			if (typeof entry.r !== 'undefined')
			{
				domNode.parentNode.removeChild(domNode);
			}
			entry.s = _VirtualDom_applyPatchesHelp(domNode, data.w);
			return domNode;

		case 8:
			return _VirtualDom_applyPatchReorder(domNode, patch);

		case 5:
			return patch.s(domNode);

		default:
			_Debug_crash(10); // 'Ran into an unknown patch!'
	}
}


function _VirtualDom_applyPatchRedraw(domNode, vNode, eventNode)
{
	var parentNode = domNode.parentNode;
	var newNode = _VirtualDom_render(vNode, eventNode);

	if (!newNode.elm_event_node_ref)
	{
		newNode.elm_event_node_ref = domNode.elm_event_node_ref;
	}

	if (parentNode && newNode !== domNode)
	{
		parentNode.replaceChild(newNode, domNode);
	}
	return newNode;
}


function _VirtualDom_applyPatchReorder(domNode, patch)
{
	var data = patch.s;

	// remove end inserts
	var frag = _VirtualDom_applyPatchReorderEndInsertsHelp(data.y, patch);

	// removals
	domNode = _VirtualDom_applyPatchesHelp(domNode, data.w);

	// inserts
	var inserts = data.x;
	for (var i = 0; i < inserts.length; i++)
	{
		var insert = inserts[i];
		var entry = insert.A;
		var node = entry.c === 2
			? entry.s
			: _VirtualDom_render(entry.z, patch.u);
		domNode.insertBefore(node, domNode.childNodes[insert.r]);
	}

	// add end inserts
	if (frag)
	{
		_VirtualDom_appendChild(domNode, frag);
	}

	return domNode;
}


function _VirtualDom_applyPatchReorderEndInsertsHelp(endInserts, patch)
{
	if (!endInserts)
	{
		return;
	}

	var frag = _VirtualDom_doc.createDocumentFragment();
	for (var i = 0; i < endInserts.length; i++)
	{
		var insert = endInserts[i];
		var entry = insert.A;
		_VirtualDom_appendChild(frag, entry.c === 2
			? entry.s
			: _VirtualDom_render(entry.z, patch.u)
		);
	}
	return frag;
}


function _VirtualDom_virtualize(node)
{
	// TEXT NODES

	if (node.nodeType === 3)
	{
		return _VirtualDom_text(node.textContent);
	}


	// WEIRD NODES

	if (node.nodeType !== 1)
	{
		return _VirtualDom_text('');
	}


	// ELEMENT NODES

	var attrList = _List_Nil;
	var attrs = node.attributes;
	for (var i = attrs.length; i--; )
	{
		var attr = attrs[i];
		var name = attr.name;
		var value = attr.value;
		attrList = _List_Cons( A2(_VirtualDom_attribute, name, value), attrList );
	}

	var tag = node.tagName.toLowerCase();
	var kidList = _List_Nil;
	var kids = node.childNodes;

	for (var i = kids.length; i--; )
	{
		kidList = _List_Cons(_VirtualDom_virtualize(kids[i]), kidList);
	}
	return A3(_VirtualDom_node, tag, attrList, kidList);
}

function _VirtualDom_dekey(keyedNode)
{
	var keyedKids = keyedNode.e;
	var len = keyedKids.length;
	var kids = new Array(len);
	for (var i = 0; i < len; i++)
	{
		kids[i] = keyedKids[i].b;
	}

	return {
		$: 1,
		c: keyedNode.c,
		d: keyedNode.d,
		e: kids,
		f: keyedNode.f,
		b: keyedNode.b
	};
}




// ELEMENT


var _Debugger_element;

var _Browser_element = _Debugger_element || F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.dB,
		impl.ek,
		impl.ee,
		function(sendToApp, initialModel) {
			var view = impl.el;
			/**/
			var domNode = args['node'];
			//*/
			/**_UNUSED/
			var domNode = args && args['node'] ? args['node'] : _Debug_crash(0);
			//*/
			var currNode = _VirtualDom_virtualize(domNode);

			return _Browser_makeAnimator(initialModel, function(model)
			{
				var nextNode = view(model);
				var patches = _VirtualDom_diff(currNode, nextNode);
				domNode = _VirtualDom_applyPatches(domNode, currNode, patches, sendToApp);
				currNode = nextNode;
			});
		}
	);
});



// DOCUMENT


var _Debugger_document;

var _Browser_document = _Debugger_document || F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.dB,
		impl.ek,
		impl.ee,
		function(sendToApp, initialModel) {
			var divertHrefToApp = impl.bK && impl.bK(sendToApp)
			var view = impl.el;
			var title = _VirtualDom_doc.title;
			var bodyNode = _VirtualDom_doc.body;
			var currNode = _VirtualDom_virtualize(bodyNode);
			return _Browser_makeAnimator(initialModel, function(model)
			{
				_VirtualDom_divertHrefToApp = divertHrefToApp;
				var doc = view(model);
				var nextNode = _VirtualDom_node('body')(_List_Nil)(doc.df);
				var patches = _VirtualDom_diff(currNode, nextNode);
				bodyNode = _VirtualDom_applyPatches(bodyNode, currNode, patches, sendToApp);
				currNode = nextNode;
				_VirtualDom_divertHrefToApp = 0;
				(title !== doc.eh) && (_VirtualDom_doc.title = title = doc.eh);
			});
		}
	);
});



// ANIMATION


var _Browser_cancelAnimationFrame =
	typeof cancelAnimationFrame !== 'undefined'
		? cancelAnimationFrame
		: function(id) { clearTimeout(id); };

var _Browser_requestAnimationFrame =
	typeof requestAnimationFrame !== 'undefined'
		? requestAnimationFrame
		: function(callback) { return setTimeout(callback, 1000 / 60); };


function _Browser_makeAnimator(model, draw)
{
	draw(model);

	var state = 0;

	function updateIfNeeded()
	{
		state = state === 1
			? 0
			: ( _Browser_requestAnimationFrame(updateIfNeeded), draw(model), 1 );
	}

	return function(nextModel, isSync)
	{
		model = nextModel;

		isSync
			? ( draw(model),
				state === 2 && (state = 1)
				)
			: ( state === 0 && _Browser_requestAnimationFrame(updateIfNeeded),
				state = 2
				);
	};
}



// APPLICATION


function _Browser_application(impl)
{
	var onUrlChange = impl.dX;
	var onUrlRequest = impl.dY;
	var key = function() { key.a(onUrlChange(_Browser_getUrl())); };

	return _Browser_document({
		bK: function(sendToApp)
		{
			key.a = sendToApp;
			_Browser_window.addEventListener('popstate', key);
			_Browser_window.navigator.userAgent.indexOf('Trident') < 0 || _Browser_window.addEventListener('hashchange', key);

			return F2(function(domNode, event)
			{
				if (!event.ctrlKey && !event.metaKey && !event.shiftKey && event.button < 1 && !domNode.target && !domNode.hasAttribute('download'))
				{
					event.preventDefault();
					var href = domNode.href;
					var curr = _Browser_getUrl();
					var next = $elm$url$Url$fromString(href).a;
					sendToApp(onUrlRequest(
						(next
							&& curr.cJ === next.cJ
							&& curr.ck === next.ck
							&& curr.cB.a === next.cB.a
						)
							? $elm$browser$Browser$Internal(next)
							: $elm$browser$Browser$External(href)
					));
				}
			});
		},
		dB: function(flags)
		{
			return A3(impl.dB, flags, _Browser_getUrl(), key);
		},
		el: impl.el,
		ek: impl.ek,
		ee: impl.ee
	});
}

function _Browser_getUrl()
{
	return $elm$url$Url$fromString(_VirtualDom_doc.location.href).a || _Debug_crash(1);
}

var _Browser_go = F2(function(key, n)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		n && history.go(n);
		key();
	}));
});

var _Browser_pushUrl = F2(function(key, url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		history.pushState({}, '', url);
		key();
	}));
});

var _Browser_replaceUrl = F2(function(key, url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		history.replaceState({}, '', url);
		key();
	}));
});



// GLOBAL EVENTS


var _Browser_fakeNode = { addEventListener: function() {}, removeEventListener: function() {} };
var _Browser_doc = typeof document !== 'undefined' ? document : _Browser_fakeNode;
var _Browser_window = typeof window !== 'undefined' ? window : _Browser_fakeNode;

var _Browser_on = F3(function(node, eventName, sendToSelf)
{
	return _Scheduler_spawn(_Scheduler_binding(function(callback)
	{
		function handler(event)	{ _Scheduler_rawSpawn(sendToSelf(event)); }
		node.addEventListener(eventName, handler, _VirtualDom_passiveSupported && { passive: true });
		return function() { node.removeEventListener(eventName, handler); };
	}));
});

var _Browser_decodeEvent = F2(function(decoder, event)
{
	var result = _Json_runHelp(decoder, event);
	return $elm$core$Result$isOk(result) ? $elm$core$Maybe$Just(result.a) : $elm$core$Maybe$Nothing;
});



// PAGE VISIBILITY


function _Browser_visibilityInfo()
{
	return (typeof _VirtualDom_doc.hidden !== 'undefined')
		? { dx: 'hidden', di: 'visibilitychange' }
		:
	(typeof _VirtualDom_doc.mozHidden !== 'undefined')
		? { dx: 'mozHidden', di: 'mozvisibilitychange' }
		:
	(typeof _VirtualDom_doc.msHidden !== 'undefined')
		? { dx: 'msHidden', di: 'msvisibilitychange' }
		:
	(typeof _VirtualDom_doc.webkitHidden !== 'undefined')
		? { dx: 'webkitHidden', di: 'webkitvisibilitychange' }
		: { dx: 'hidden', di: 'visibilitychange' };
}



// ANIMATION FRAMES


function _Browser_rAF()
{
	return _Scheduler_binding(function(callback)
	{
		var id = _Browser_requestAnimationFrame(function() {
			callback(_Scheduler_succeed(Date.now()));
		});

		return function() {
			_Browser_cancelAnimationFrame(id);
		};
	});
}


function _Browser_now()
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(Date.now()));
	});
}



// DOM STUFF


function _Browser_withNode(id, doStuff)
{
	return _Scheduler_binding(function(callback)
	{
		_Browser_requestAnimationFrame(function() {
			var node = document.getElementById(id);
			callback(node
				? _Scheduler_succeed(doStuff(node))
				: _Scheduler_fail($elm$browser$Browser$Dom$NotFound(id))
			);
		});
	});
}


function _Browser_withWindow(doStuff)
{
	return _Scheduler_binding(function(callback)
	{
		_Browser_requestAnimationFrame(function() {
			callback(_Scheduler_succeed(doStuff()));
		});
	});
}


// FOCUS and BLUR


var _Browser_call = F2(function(functionName, id)
{
	return _Browser_withNode(id, function(node) {
		node[functionName]();
		return _Utils_Tuple0;
	});
});



// WINDOW VIEWPORT


function _Browser_getViewport()
{
	return {
		cS: _Browser_getScene(),
		c7: {
			c9: _Browser_window.pageXOffset,
			da: _Browser_window.pageYOffset,
			c8: _Browser_doc.documentElement.clientWidth,
			ci: _Browser_doc.documentElement.clientHeight
		}
	};
}

function _Browser_getScene()
{
	var body = _Browser_doc.body;
	var elem = _Browser_doc.documentElement;
	return {
		c8: Math.max(body.scrollWidth, body.offsetWidth, elem.scrollWidth, elem.offsetWidth, elem.clientWidth),
		ci: Math.max(body.scrollHeight, body.offsetHeight, elem.scrollHeight, elem.offsetHeight, elem.clientHeight)
	};
}

var _Browser_setViewport = F2(function(x, y)
{
	return _Browser_withWindow(function()
	{
		_Browser_window.scroll(x, y);
		return _Utils_Tuple0;
	});
});



// ELEMENT VIEWPORT


function _Browser_getViewportOf(id)
{
	return _Browser_withNode(id, function(node)
	{
		return {
			cS: {
				c8: node.scrollWidth,
				ci: node.scrollHeight
			},
			c7: {
				c9: node.scrollLeft,
				da: node.scrollTop,
				c8: node.clientWidth,
				ci: node.clientHeight
			}
		};
	});
}


var _Browser_setViewportOf = F3(function(id, x, y)
{
	return _Browser_withNode(id, function(node)
	{
		node.scrollLeft = x;
		node.scrollTop = y;
		return _Utils_Tuple0;
	});
});



// ELEMENT


function _Browser_getElement(id)
{
	return _Browser_withNode(id, function(node)
	{
		var rect = node.getBoundingClientRect();
		var x = _Browser_window.pageXOffset;
		var y = _Browser_window.pageYOffset;
		return {
			cS: _Browser_getScene(),
			c7: {
				c9: x,
				da: y,
				c8: _Browser_doc.documentElement.clientWidth,
				ci: _Browser_doc.documentElement.clientHeight
			},
			dr: {
				c9: x + rect.left,
				da: y + rect.top,
				c8: rect.width,
				ci: rect.height
			}
		};
	});
}



// LOAD and RELOAD


function _Browser_reload(skipCache)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function(callback)
	{
		_VirtualDom_doc.location.reload(skipCache);
	}));
}

function _Browser_load(url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function(callback)
	{
		try
		{
			_Browser_window.location = url;
		}
		catch(err)
		{
			// Only Firefox can throw a NS_ERROR_MALFORMED_URI exception here.
			// Other browsers reload the page, so let's be consistent about that.
			_VirtualDom_doc.location.reload(false);
		}
	}));
}


// CREATE

var _Regex_never = /.^/;

var _Regex_fromStringWith = F2(function(options, string)
{
	var flags = 'g';
	if (options.dN) { flags += 'm'; }
	if (options.dh) { flags += 'i'; }

	try
	{
		return $elm$core$Maybe$Just(new RegExp(string, flags));
	}
	catch(error)
	{
		return $elm$core$Maybe$Nothing;
	}
});


// USE

var _Regex_contains = F2(function(re, string)
{
	return string.match(re) !== null;
});


var _Regex_findAtMost = F3(function(n, re, str)
{
	var out = [];
	var number = 0;
	var string = str;
	var lastIndex = re.lastIndex;
	var prevLastIndex = -1;
	var result;
	while (number++ < n && (result = re.exec(string)))
	{
		if (prevLastIndex == re.lastIndex) break;
		var i = result.length - 1;
		var subs = new Array(i);
		while (i > 0)
		{
			var submatch = result[i];
			subs[--i] = submatch
				? $elm$core$Maybe$Just(submatch)
				: $elm$core$Maybe$Nothing;
		}
		out.push(A4($elm$regex$Regex$Match, result[0], result.index, number, _List_fromArray(subs)));
		prevLastIndex = re.lastIndex;
	}
	re.lastIndex = lastIndex;
	return _List_fromArray(out);
});


var _Regex_replaceAtMost = F4(function(n, re, replacer, string)
{
	var count = 0;
	function jsReplacer(match)
	{
		if (count++ >= n)
		{
			return match;
		}
		var i = arguments.length - 3;
		var submatches = new Array(i);
		while (i > 0)
		{
			var submatch = arguments[i];
			submatches[--i] = submatch
				? $elm$core$Maybe$Just(submatch)
				: $elm$core$Maybe$Nothing;
		}
		return replacer(A4($elm$regex$Regex$Match, match, arguments[arguments.length - 2], count, _List_fromArray(submatches)));
	}
	return string.replace(re, jsReplacer);
});

var _Regex_splitAtMost = F3(function(n, re, str)
{
	var string = str;
	var out = [];
	var start = re.lastIndex;
	var restoreLastIndex = re.lastIndex;
	while (n--)
	{
		var result = re.exec(string);
		if (!result) break;
		out.push(string.slice(start, result.index));
		start = re.lastIndex;
	}
	out.push(string.slice(start));
	re.lastIndex = restoreLastIndex;
	return _List_fromArray(out);
});

var _Regex_infinity = Infinity;



// SEND REQUEST

var _Http_toTask = F3(function(router, toTask, request)
{
	return _Scheduler_binding(function(callback)
	{
		function done(response) {
			callback(toTask(request.ds.a(response)));
		}

		var xhr = new XMLHttpRequest();
		xhr.addEventListener('error', function() { done($elm$http$Http$NetworkError_); });
		xhr.addEventListener('timeout', function() { done($elm$http$Http$Timeout_); });
		xhr.addEventListener('load', function() { done(_Http_toResponse(request.ds.b, xhr)); });
		$elm$core$Maybe$isJust(request.c5) && _Http_track(router, xhr, request.c5.a);

		try {
			xhr.open(request.ae, request.ar, true);
		} catch (e) {
			return done($elm$http$Http$BadUrl_(request.ar));
		}

		_Http_configureRequest(xhr, request);

		request.df.a && xhr.setRequestHeader('Content-Type', request.df.a);
		xhr.send(request.df.b);

		return function() { xhr.c = true; xhr.abort(); };
	});
});


// CONFIGURE

function _Http_configureRequest(xhr, request)
{
	for (var headers = request.z; headers.b; headers = headers.b) // WHILE_CONS
	{
		xhr.setRequestHeader(headers.a.a, headers.a.b);
	}
	xhr.timeout = request.E.a || 0;
	xhr.responseType = request.ds.d;
	xhr.withCredentials = request.dd;
}


// RESPONSES

function _Http_toResponse(toBody, xhr)
{
	return A2(
		200 <= xhr.status && xhr.status < 300 ? $elm$http$Http$GoodStatus_ : $elm$http$Http$BadStatus_,
		_Http_toMetadata(xhr),
		toBody(xhr.response)
	);
}


// METADATA

function _Http_toMetadata(xhr)
{
	return {
		ar: xhr.responseURL,
		d9: xhr.status,
		ea: xhr.statusText,
		z: _Http_parseHeaders(xhr.getAllResponseHeaders())
	};
}


// HEADERS

function _Http_parseHeaders(rawHeaders)
{
	if (!rawHeaders)
	{
		return $elm$core$Dict$empty;
	}

	var headers = $elm$core$Dict$empty;
	var headerPairs = rawHeaders.split('\r\n');
	for (var i = headerPairs.length; i--; )
	{
		var headerPair = headerPairs[i];
		var index = headerPair.indexOf(': ');
		if (index > 0)
		{
			var key = headerPair.substring(0, index);
			var value = headerPair.substring(index + 2);

			headers = A3($elm$core$Dict$update, key, function(oldValue) {
				return $elm$core$Maybe$Just($elm$core$Maybe$isJust(oldValue)
					? value + ', ' + oldValue.a
					: value
				);
			}, headers);
		}
	}
	return headers;
}


// EXPECT

var _Http_expect = F3(function(type, toBody, toValue)
{
	return {
		$: 0,
		d: type,
		b: toBody,
		a: toValue
	};
});

var _Http_mapExpect = F2(function(func, expect)
{
	return {
		$: 0,
		d: expect.d,
		b: expect.b,
		a: function(x) { return func(expect.a(x)); }
	};
});

function _Http_toDataView(arrayBuffer)
{
	return new DataView(arrayBuffer);
}


// BODY and PARTS

var _Http_emptyBody = { $: 0 };
var _Http_pair = F2(function(a, b) { return { $: 0, a: a, b: b }; });

function _Http_toFormData(parts)
{
	for (var formData = new FormData(); parts.b; parts = parts.b) // WHILE_CONS
	{
		var part = parts.a;
		formData.append(part.a, part.b);
	}
	return formData;
}

var _Http_bytesToBlob = F2(function(mime, bytes)
{
	return new Blob([bytes], { type: mime });
});


// PROGRESS

function _Http_track(router, xhr, tracker)
{
	// TODO check out lengthComputable on loadstart event

	xhr.upload.addEventListener('progress', function(event) {
		if (xhr.c) { return; }
		_Scheduler_rawSpawn(A2($elm$core$Platform$sendToSelf, router, _Utils_Tuple2(tracker, $elm$http$Http$Sending({
			d8: event.loaded,
			cY: event.total
		}))));
	});
	xhr.addEventListener('progress', function(event) {
		if (xhr.c) { return; }
		_Scheduler_rawSpawn(A2($elm$core$Platform$sendToSelf, router, _Utils_Tuple2(tracker, $elm$http$Http$Receiving({
			d4: event.loaded,
			cY: event.lengthComputable ? $elm$core$Maybe$Just(event.total) : $elm$core$Maybe$Nothing
		}))));
	});
}


function _Time_now(millisToPosix)
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(millisToPosix(Date.now())));
	});
}

var _Time_setInterval = F2(function(interval, task)
{
	return _Scheduler_binding(function(callback)
	{
		var id = setInterval(function() { _Scheduler_rawSpawn(task); }, interval);
		return function() { clearInterval(id); };
	});
});

function _Time_here()
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(
			A2($elm$time$Time$customZone, -(new Date().getTimezoneOffset()), _List_Nil)
		));
	});
}


function _Time_getZoneName()
{
	return _Scheduler_binding(function(callback)
	{
		try
		{
			var name = $elm$time$Time$Name(Intl.DateTimeFormat().resolvedOptions().timeZone);
		}
		catch (e)
		{
			var name = $elm$time$Time$Offset(new Date().getTimezoneOffset());
		}
		callback(_Scheduler_succeed(name));
	});
}



var _Bitwise_and = F2(function(a, b)
{
	return a & b;
});

var _Bitwise_or = F2(function(a, b)
{
	return a | b;
});

var _Bitwise_xor = F2(function(a, b)
{
	return a ^ b;
});

function _Bitwise_complement(a)
{
	return ~a;
};

var _Bitwise_shiftLeftBy = F2(function(offset, a)
{
	return a << offset;
});

var _Bitwise_shiftRightBy = F2(function(offset, a)
{
	return a >> offset;
});

var _Bitwise_shiftRightZfBy = F2(function(offset, a)
{
	return a >>> offset;
});


function _Url_percentEncode(string)
{
	return encodeURIComponent(string);
}

function _Url_percentDecode(string)
{
	try
	{
		return $elm$core$Maybe$Just(decodeURIComponent(string));
	}
	catch (e)
	{
		return $elm$core$Maybe$Nothing;
	}
}var $elm$core$Basics$EQ = 1;
var $elm$core$Basics$GT = 2;
var $elm$core$Basics$LT = 0;
var $elm$core$List$cons = _List_cons;
var $elm$core$Dict$foldr = F3(
	function (func, acc, t) {
		foldr:
		while (true) {
			if (t.$ === -2) {
				return acc;
			} else {
				var key = t.b;
				var value = t.c;
				var left = t.d;
				var right = t.e;
				var $temp$func = func,
					$temp$acc = A3(
					func,
					key,
					value,
					A3($elm$core$Dict$foldr, func, acc, right)),
					$temp$t = left;
				func = $temp$func;
				acc = $temp$acc;
				t = $temp$t;
				continue foldr;
			}
		}
	});
var $elm$core$Dict$toList = function (dict) {
	return A3(
		$elm$core$Dict$foldr,
		F3(
			function (key, value, list) {
				return A2(
					$elm$core$List$cons,
					_Utils_Tuple2(key, value),
					list);
			}),
		_List_Nil,
		dict);
};
var $elm$core$Dict$keys = function (dict) {
	return A3(
		$elm$core$Dict$foldr,
		F3(
			function (key, value, keyList) {
				return A2($elm$core$List$cons, key, keyList);
			}),
		_List_Nil,
		dict);
};
var $elm$core$Set$toList = function (_v0) {
	var dict = _v0;
	return $elm$core$Dict$keys(dict);
};
var $elm$core$Elm$JsArray$foldr = _JsArray_foldr;
var $elm$core$Array$foldr = F3(
	function (func, baseCase, _v0) {
		var tree = _v0.c;
		var tail = _v0.d;
		var helper = F2(
			function (node, acc) {
				if (!node.$) {
					var subTree = node.a;
					return A3($elm$core$Elm$JsArray$foldr, helper, acc, subTree);
				} else {
					var values = node.a;
					return A3($elm$core$Elm$JsArray$foldr, func, acc, values);
				}
			});
		return A3(
			$elm$core$Elm$JsArray$foldr,
			helper,
			A3($elm$core$Elm$JsArray$foldr, func, baseCase, tail),
			tree);
	});
var $elm$core$Array$toList = function (array) {
	return A3($elm$core$Array$foldr, $elm$core$List$cons, _List_Nil, array);
};
var $elm$core$Result$Err = function (a) {
	return {$: 1, a: a};
};
var $elm$json$Json$Decode$Failure = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $elm$json$Json$Decode$Field = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$json$Json$Decode$Index = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $elm$core$Result$Ok = function (a) {
	return {$: 0, a: a};
};
var $elm$json$Json$Decode$OneOf = function (a) {
	return {$: 2, a: a};
};
var $elm$core$Basics$False = 1;
var $elm$core$Basics$add = _Basics_add;
var $elm$core$Maybe$Just = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Maybe$Nothing = {$: 1};
var $elm$core$String$all = _String_all;
var $elm$core$Basics$and = _Basics_and;
var $elm$core$Basics$append = _Utils_append;
var $elm$json$Json$Encode$encode = _Json_encode;
var $elm$core$String$fromInt = _String_fromNumber;
var $elm$core$String$join = F2(
	function (sep, chunks) {
		return A2(
			_String_join,
			sep,
			_List_toArray(chunks));
	});
var $elm$core$String$split = F2(
	function (sep, string) {
		return _List_fromArray(
			A2(_String_split, sep, string));
	});
var $elm$json$Json$Decode$indent = function (str) {
	return A2(
		$elm$core$String$join,
		'\n    ',
		A2($elm$core$String$split, '\n', str));
};
var $elm$core$List$foldl = F3(
	function (func, acc, list) {
		foldl:
		while (true) {
			if (!list.b) {
				return acc;
			} else {
				var x = list.a;
				var xs = list.b;
				var $temp$func = func,
					$temp$acc = A2(func, x, acc),
					$temp$list = xs;
				func = $temp$func;
				acc = $temp$acc;
				list = $temp$list;
				continue foldl;
			}
		}
	});
var $elm$core$List$length = function (xs) {
	return A3(
		$elm$core$List$foldl,
		F2(
			function (_v0, i) {
				return i + 1;
			}),
		0,
		xs);
};
var $elm$core$List$map2 = _List_map2;
var $elm$core$Basics$le = _Utils_le;
var $elm$core$Basics$sub = _Basics_sub;
var $elm$core$List$rangeHelp = F3(
	function (lo, hi, list) {
		rangeHelp:
		while (true) {
			if (_Utils_cmp(lo, hi) < 1) {
				var $temp$lo = lo,
					$temp$hi = hi - 1,
					$temp$list = A2($elm$core$List$cons, hi, list);
				lo = $temp$lo;
				hi = $temp$hi;
				list = $temp$list;
				continue rangeHelp;
			} else {
				return list;
			}
		}
	});
var $elm$core$List$range = F2(
	function (lo, hi) {
		return A3($elm$core$List$rangeHelp, lo, hi, _List_Nil);
	});
var $elm$core$List$indexedMap = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$map2,
			f,
			A2(
				$elm$core$List$range,
				0,
				$elm$core$List$length(xs) - 1),
			xs);
	});
var $elm$core$Char$toCode = _Char_toCode;
var $elm$core$Char$isLower = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (97 <= code) && (code <= 122);
};
var $elm$core$Char$isUpper = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (code <= 90) && (65 <= code);
};
var $elm$core$Basics$or = _Basics_or;
var $elm$core$Char$isAlpha = function (_char) {
	return $elm$core$Char$isLower(_char) || $elm$core$Char$isUpper(_char);
};
var $elm$core$Char$isDigit = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (code <= 57) && (48 <= code);
};
var $elm$core$Char$isAlphaNum = function (_char) {
	return $elm$core$Char$isLower(_char) || ($elm$core$Char$isUpper(_char) || $elm$core$Char$isDigit(_char));
};
var $elm$core$List$reverse = function (list) {
	return A3($elm$core$List$foldl, $elm$core$List$cons, _List_Nil, list);
};
var $elm$core$String$uncons = _String_uncons;
var $elm$json$Json$Decode$errorOneOf = F2(
	function (i, error) {
		return '\n\n(' + ($elm$core$String$fromInt(i + 1) + (') ' + $elm$json$Json$Decode$indent(
			$elm$json$Json$Decode$errorToString(error))));
	});
var $elm$json$Json$Decode$errorToString = function (error) {
	return A2($elm$json$Json$Decode$errorToStringHelp, error, _List_Nil);
};
var $elm$json$Json$Decode$errorToStringHelp = F2(
	function (error, context) {
		errorToStringHelp:
		while (true) {
			switch (error.$) {
				case 0:
					var f = error.a;
					var err = error.b;
					var isSimple = function () {
						var _v1 = $elm$core$String$uncons(f);
						if (_v1.$ === 1) {
							return false;
						} else {
							var _v2 = _v1.a;
							var _char = _v2.a;
							var rest = _v2.b;
							return $elm$core$Char$isAlpha(_char) && A2($elm$core$String$all, $elm$core$Char$isAlphaNum, rest);
						}
					}();
					var fieldName = isSimple ? ('.' + f) : ('[\'' + (f + '\']'));
					var $temp$error = err,
						$temp$context = A2($elm$core$List$cons, fieldName, context);
					error = $temp$error;
					context = $temp$context;
					continue errorToStringHelp;
				case 1:
					var i = error.a;
					var err = error.b;
					var indexName = '[' + ($elm$core$String$fromInt(i) + ']');
					var $temp$error = err,
						$temp$context = A2($elm$core$List$cons, indexName, context);
					error = $temp$error;
					context = $temp$context;
					continue errorToStringHelp;
				case 2:
					var errors = error.a;
					if (!errors.b) {
						return 'Ran into a Json.Decode.oneOf with no possibilities' + function () {
							if (!context.b) {
								return '!';
							} else {
								return ' at json' + A2(
									$elm$core$String$join,
									'',
									$elm$core$List$reverse(context));
							}
						}();
					} else {
						if (!errors.b.b) {
							var err = errors.a;
							var $temp$error = err,
								$temp$context = context;
							error = $temp$error;
							context = $temp$context;
							continue errorToStringHelp;
						} else {
							var starter = function () {
								if (!context.b) {
									return 'Json.Decode.oneOf';
								} else {
									return 'The Json.Decode.oneOf at json' + A2(
										$elm$core$String$join,
										'',
										$elm$core$List$reverse(context));
								}
							}();
							var introduction = starter + (' failed in the following ' + ($elm$core$String$fromInt(
								$elm$core$List$length(errors)) + ' ways:'));
							return A2(
								$elm$core$String$join,
								'\n\n',
								A2(
									$elm$core$List$cons,
									introduction,
									A2($elm$core$List$indexedMap, $elm$json$Json$Decode$errorOneOf, errors)));
						}
					}
				default:
					var msg = error.a;
					var json = error.b;
					var introduction = function () {
						if (!context.b) {
							return 'Problem with the given value:\n\n';
						} else {
							return 'Problem with the value at json' + (A2(
								$elm$core$String$join,
								'',
								$elm$core$List$reverse(context)) + ':\n\n    ');
						}
					}();
					return introduction + ($elm$json$Json$Decode$indent(
						A2($elm$json$Json$Encode$encode, 4, json)) + ('\n\n' + msg));
			}
		}
	});
var $elm$core$Array$branchFactor = 32;
var $elm$core$Array$Array_elm_builtin = F4(
	function (a, b, c, d) {
		return {$: 0, a: a, b: b, c: c, d: d};
	});
var $elm$core$Elm$JsArray$empty = _JsArray_empty;
var $elm$core$Basics$ceiling = _Basics_ceiling;
var $elm$core$Basics$fdiv = _Basics_fdiv;
var $elm$core$Basics$logBase = F2(
	function (base, number) {
		return _Basics_log(number) / _Basics_log(base);
	});
var $elm$core$Basics$toFloat = _Basics_toFloat;
var $elm$core$Array$shiftStep = $elm$core$Basics$ceiling(
	A2($elm$core$Basics$logBase, 2, $elm$core$Array$branchFactor));
var $elm$core$Array$empty = A4($elm$core$Array$Array_elm_builtin, 0, $elm$core$Array$shiftStep, $elm$core$Elm$JsArray$empty, $elm$core$Elm$JsArray$empty);
var $elm$core$Elm$JsArray$initialize = _JsArray_initialize;
var $elm$core$Array$Leaf = function (a) {
	return {$: 1, a: a};
};
var $elm$core$Basics$apL = F2(
	function (f, x) {
		return f(x);
	});
var $elm$core$Basics$apR = F2(
	function (x, f) {
		return f(x);
	});
var $elm$core$Basics$eq = _Utils_equal;
var $elm$core$Basics$floor = _Basics_floor;
var $elm$core$Elm$JsArray$length = _JsArray_length;
var $elm$core$Basics$gt = _Utils_gt;
var $elm$core$Basics$max = F2(
	function (x, y) {
		return (_Utils_cmp(x, y) > 0) ? x : y;
	});
var $elm$core$Basics$mul = _Basics_mul;
var $elm$core$Array$SubTree = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Elm$JsArray$initializeFromList = _JsArray_initializeFromList;
var $elm$core$Array$compressNodes = F2(
	function (nodes, acc) {
		compressNodes:
		while (true) {
			var _v0 = A2($elm$core$Elm$JsArray$initializeFromList, $elm$core$Array$branchFactor, nodes);
			var node = _v0.a;
			var remainingNodes = _v0.b;
			var newAcc = A2(
				$elm$core$List$cons,
				$elm$core$Array$SubTree(node),
				acc);
			if (!remainingNodes.b) {
				return $elm$core$List$reverse(newAcc);
			} else {
				var $temp$nodes = remainingNodes,
					$temp$acc = newAcc;
				nodes = $temp$nodes;
				acc = $temp$acc;
				continue compressNodes;
			}
		}
	});
var $elm$core$Tuple$first = function (_v0) {
	var x = _v0.a;
	return x;
};
var $elm$core$Array$treeFromBuilder = F2(
	function (nodeList, nodeListSize) {
		treeFromBuilder:
		while (true) {
			var newNodeSize = $elm$core$Basics$ceiling(nodeListSize / $elm$core$Array$branchFactor);
			if (newNodeSize === 1) {
				return A2($elm$core$Elm$JsArray$initializeFromList, $elm$core$Array$branchFactor, nodeList).a;
			} else {
				var $temp$nodeList = A2($elm$core$Array$compressNodes, nodeList, _List_Nil),
					$temp$nodeListSize = newNodeSize;
				nodeList = $temp$nodeList;
				nodeListSize = $temp$nodeListSize;
				continue treeFromBuilder;
			}
		}
	});
var $elm$core$Array$builderToArray = F2(
	function (reverseNodeList, builder) {
		if (!builder.m) {
			return A4(
				$elm$core$Array$Array_elm_builtin,
				$elm$core$Elm$JsArray$length(builder.r),
				$elm$core$Array$shiftStep,
				$elm$core$Elm$JsArray$empty,
				builder.r);
		} else {
			var treeLen = builder.m * $elm$core$Array$branchFactor;
			var depth = $elm$core$Basics$floor(
				A2($elm$core$Basics$logBase, $elm$core$Array$branchFactor, treeLen - 1));
			var correctNodeList = reverseNodeList ? $elm$core$List$reverse(builder.s) : builder.s;
			var tree = A2($elm$core$Array$treeFromBuilder, correctNodeList, builder.m);
			return A4(
				$elm$core$Array$Array_elm_builtin,
				$elm$core$Elm$JsArray$length(builder.r) + treeLen,
				A2($elm$core$Basics$max, 5, depth * $elm$core$Array$shiftStep),
				tree,
				builder.r);
		}
	});
var $elm$core$Basics$idiv = _Basics_idiv;
var $elm$core$Basics$lt = _Utils_lt;
var $elm$core$Array$initializeHelp = F5(
	function (fn, fromIndex, len, nodeList, tail) {
		initializeHelp:
		while (true) {
			if (fromIndex < 0) {
				return A2(
					$elm$core$Array$builderToArray,
					false,
					{s: nodeList, m: (len / $elm$core$Array$branchFactor) | 0, r: tail});
			} else {
				var leaf = $elm$core$Array$Leaf(
					A3($elm$core$Elm$JsArray$initialize, $elm$core$Array$branchFactor, fromIndex, fn));
				var $temp$fn = fn,
					$temp$fromIndex = fromIndex - $elm$core$Array$branchFactor,
					$temp$len = len,
					$temp$nodeList = A2($elm$core$List$cons, leaf, nodeList),
					$temp$tail = tail;
				fn = $temp$fn;
				fromIndex = $temp$fromIndex;
				len = $temp$len;
				nodeList = $temp$nodeList;
				tail = $temp$tail;
				continue initializeHelp;
			}
		}
	});
var $elm$core$Basics$remainderBy = _Basics_remainderBy;
var $elm$core$Array$initialize = F2(
	function (len, fn) {
		if (len <= 0) {
			return $elm$core$Array$empty;
		} else {
			var tailLen = len % $elm$core$Array$branchFactor;
			var tail = A3($elm$core$Elm$JsArray$initialize, tailLen, len - tailLen, fn);
			var initialFromIndex = (len - tailLen) - $elm$core$Array$branchFactor;
			return A5($elm$core$Array$initializeHelp, fn, initialFromIndex, len, _List_Nil, tail);
		}
	});
var $elm$core$Basics$True = 0;
var $elm$core$Result$isOk = function (result) {
	if (!result.$) {
		return true;
	} else {
		return false;
	}
};
var $elm$json$Json$Decode$map = _Json_map1;
var $elm$json$Json$Decode$map2 = _Json_map2;
var $elm$json$Json$Decode$succeed = _Json_succeed;
var $elm$virtual_dom$VirtualDom$toHandlerInt = function (handler) {
	switch (handler.$) {
		case 0:
			return 0;
		case 1:
			return 1;
		case 2:
			return 2;
		default:
			return 3;
	}
};
var $elm$browser$Browser$External = function (a) {
	return {$: 1, a: a};
};
var $elm$browser$Browser$Internal = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Basics$identity = function (x) {
	return x;
};
var $elm$browser$Browser$Dom$NotFound = $elm$core$Basics$identity;
var $elm$url$Url$Http = 0;
var $elm$url$Url$Https = 1;
var $elm$url$Url$Url = F6(
	function (protocol, host, port_, path, query, fragment) {
		return {ce: fragment, ck: host, cz: path, cB: port_, cJ: protocol, y: query};
	});
var $elm$core$String$contains = _String_contains;
var $elm$core$String$length = _String_length;
var $elm$core$String$slice = _String_slice;
var $elm$core$String$dropLeft = F2(
	function (n, string) {
		return (n < 1) ? string : A3(
			$elm$core$String$slice,
			n,
			$elm$core$String$length(string),
			string);
	});
var $elm$core$String$indexes = _String_indexes;
var $elm$core$String$isEmpty = function (string) {
	return string === '';
};
var $elm$core$String$left = F2(
	function (n, string) {
		return (n < 1) ? '' : A3($elm$core$String$slice, 0, n, string);
	});
var $elm$core$String$toInt = _String_toInt;
var $elm$url$Url$chompBeforePath = F5(
	function (protocol, path, params, frag, str) {
		if ($elm$core$String$isEmpty(str) || A2($elm$core$String$contains, '@', str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, ':', str);
			if (!_v0.b) {
				return $elm$core$Maybe$Just(
					A6($elm$url$Url$Url, protocol, str, $elm$core$Maybe$Nothing, path, params, frag));
			} else {
				if (!_v0.b.b) {
					var i = _v0.a;
					var _v1 = $elm$core$String$toInt(
						A2($elm$core$String$dropLeft, i + 1, str));
					if (_v1.$ === 1) {
						return $elm$core$Maybe$Nothing;
					} else {
						var port_ = _v1;
						return $elm$core$Maybe$Just(
							A6(
								$elm$url$Url$Url,
								protocol,
								A2($elm$core$String$left, i, str),
								port_,
								path,
								params,
								frag));
					}
				} else {
					return $elm$core$Maybe$Nothing;
				}
			}
		}
	});
var $elm$url$Url$chompBeforeQuery = F4(
	function (protocol, params, frag, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '/', str);
			if (!_v0.b) {
				return A5($elm$url$Url$chompBeforePath, protocol, '/', params, frag, str);
			} else {
				var i = _v0.a;
				return A5(
					$elm$url$Url$chompBeforePath,
					protocol,
					A2($elm$core$String$dropLeft, i, str),
					params,
					frag,
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$url$Url$chompBeforeFragment = F3(
	function (protocol, frag, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '?', str);
			if (!_v0.b) {
				return A4($elm$url$Url$chompBeforeQuery, protocol, $elm$core$Maybe$Nothing, frag, str);
			} else {
				var i = _v0.a;
				return A4(
					$elm$url$Url$chompBeforeQuery,
					protocol,
					$elm$core$Maybe$Just(
						A2($elm$core$String$dropLeft, i + 1, str)),
					frag,
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$url$Url$chompAfterProtocol = F2(
	function (protocol, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '#', str);
			if (!_v0.b) {
				return A3($elm$url$Url$chompBeforeFragment, protocol, $elm$core$Maybe$Nothing, str);
			} else {
				var i = _v0.a;
				return A3(
					$elm$url$Url$chompBeforeFragment,
					protocol,
					$elm$core$Maybe$Just(
						A2($elm$core$String$dropLeft, i + 1, str)),
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$core$String$startsWith = _String_startsWith;
var $elm$url$Url$fromString = function (str) {
	return A2($elm$core$String$startsWith, 'http://', str) ? A2(
		$elm$url$Url$chompAfterProtocol,
		0,
		A2($elm$core$String$dropLeft, 7, str)) : (A2($elm$core$String$startsWith, 'https://', str) ? A2(
		$elm$url$Url$chompAfterProtocol,
		1,
		A2($elm$core$String$dropLeft, 8, str)) : $elm$core$Maybe$Nothing);
};
var $elm$core$Basics$never = function (_v0) {
	never:
	while (true) {
		var nvr = _v0;
		var $temp$_v0 = nvr;
		_v0 = $temp$_v0;
		continue never;
	}
};
var $elm$core$Task$Perform = $elm$core$Basics$identity;
var $elm$core$Task$succeed = _Scheduler_succeed;
var $elm$core$Task$init = $elm$core$Task$succeed(0);
var $elm$core$List$foldrHelper = F4(
	function (fn, acc, ctr, ls) {
		if (!ls.b) {
			return acc;
		} else {
			var a = ls.a;
			var r1 = ls.b;
			if (!r1.b) {
				return A2(fn, a, acc);
			} else {
				var b = r1.a;
				var r2 = r1.b;
				if (!r2.b) {
					return A2(
						fn,
						a,
						A2(fn, b, acc));
				} else {
					var c = r2.a;
					var r3 = r2.b;
					if (!r3.b) {
						return A2(
							fn,
							a,
							A2(
								fn,
								b,
								A2(fn, c, acc)));
					} else {
						var d = r3.a;
						var r4 = r3.b;
						var res = (ctr > 500) ? A3(
							$elm$core$List$foldl,
							fn,
							acc,
							$elm$core$List$reverse(r4)) : A4($elm$core$List$foldrHelper, fn, acc, ctr + 1, r4);
						return A2(
							fn,
							a,
							A2(
								fn,
								b,
								A2(
									fn,
									c,
									A2(fn, d, res))));
					}
				}
			}
		}
	});
var $elm$core$List$foldr = F3(
	function (fn, acc, ls) {
		return A4($elm$core$List$foldrHelper, fn, acc, 0, ls);
	});
var $elm$core$List$map = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$foldr,
			F2(
				function (x, acc) {
					return A2(
						$elm$core$List$cons,
						f(x),
						acc);
				}),
			_List_Nil,
			xs);
	});
var $elm$core$Task$andThen = _Scheduler_andThen;
var $elm$core$Task$map = F2(
	function (func, taskA) {
		return A2(
			$elm$core$Task$andThen,
			function (a) {
				return $elm$core$Task$succeed(
					func(a));
			},
			taskA);
	});
var $elm$core$Task$map2 = F3(
	function (func, taskA, taskB) {
		return A2(
			$elm$core$Task$andThen,
			function (a) {
				return A2(
					$elm$core$Task$andThen,
					function (b) {
						return $elm$core$Task$succeed(
							A2(func, a, b));
					},
					taskB);
			},
			taskA);
	});
var $elm$core$Task$sequence = function (tasks) {
	return A3(
		$elm$core$List$foldr,
		$elm$core$Task$map2($elm$core$List$cons),
		$elm$core$Task$succeed(_List_Nil),
		tasks);
};
var $elm$core$Platform$sendToApp = _Platform_sendToApp;
var $elm$core$Task$spawnCmd = F2(
	function (router, _v0) {
		var task = _v0;
		return _Scheduler_spawn(
			A2(
				$elm$core$Task$andThen,
				$elm$core$Platform$sendToApp(router),
				task));
	});
var $elm$core$Task$onEffects = F3(
	function (router, commands, state) {
		return A2(
			$elm$core$Task$map,
			function (_v0) {
				return 0;
			},
			$elm$core$Task$sequence(
				A2(
					$elm$core$List$map,
					$elm$core$Task$spawnCmd(router),
					commands)));
	});
var $elm$core$Task$onSelfMsg = F3(
	function (_v0, _v1, _v2) {
		return $elm$core$Task$succeed(0);
	});
var $elm$core$Task$cmdMap = F2(
	function (tagger, _v0) {
		var task = _v0;
		return A2($elm$core$Task$map, tagger, task);
	});
_Platform_effectManagers['Task'] = _Platform_createManager($elm$core$Task$init, $elm$core$Task$onEffects, $elm$core$Task$onSelfMsg, $elm$core$Task$cmdMap);
var $elm$core$Task$command = _Platform_leaf('Task');
var $elm$core$Task$perform = F2(
	function (toMessage, task) {
		return $elm$core$Task$command(
			A2($elm$core$Task$map, toMessage, task));
	});
var $elm$browser$Browser$element = _Browser_element;
var $author$project$Mailer$NotSent = 0;
var $elm$core$Platform$Cmd$batch = _Platform_batch;
var $elm$core$Platform$Cmd$none = $elm$core$Platform$Cmd$batch(_List_Nil);
var $rtfeldman$elm_validate$Validate$Validator = $elm$core$Basics$identity;
var $rtfeldman$elm_validate$Validate$firstErrorHelp = F2(
	function (validators, subject) {
		firstErrorHelp:
		while (true) {
			if (!validators.b) {
				return _List_Nil;
			} else {
				var getErrors = validators.a;
				var rest = validators.b;
				var _v1 = getErrors(subject);
				if (!_v1.b) {
					var $temp$validators = rest,
						$temp$subject = subject;
					validators = $temp$validators;
					subject = $temp$subject;
					continue firstErrorHelp;
				} else {
					var errors = _v1;
					return errors;
				}
			}
		}
	});
var $rtfeldman$elm_validate$Validate$firstError = function (validators) {
	var getErrors = function (subject) {
		return A2($rtfeldman$elm_validate$Validate$firstErrorHelp, validators, subject);
	};
	return getErrors;
};
var $rtfeldman$elm_validate$Validate$ifTrue = F2(
	function (test, error) {
		var getErrors = function (subject) {
			return test(subject) ? _List_fromArray(
				[error]) : _List_Nil;
		};
		return getErrors;
	});
var $rtfeldman$elm_validate$Validate$isWhitespaceChar = function (_char) {
	return (_char === ' ') || ((_char === '\n') || ((_char === '\t') || (_char === '\u000D')));
};
var $rtfeldman$elm_validate$Validate$isBlank = function (str) {
	isBlank:
	while (true) {
		var _v0 = $elm$core$String$uncons(str);
		if (!_v0.$) {
			var _v1 = _v0.a;
			var _char = _v1.a;
			var rest = _v1.b;
			if ($rtfeldman$elm_validate$Validate$isWhitespaceChar(_char)) {
				var $temp$str = rest;
				str = $temp$str;
				continue isBlank;
			} else {
				return false;
			}
		} else {
			return true;
		}
	}
};
var $rtfeldman$elm_validate$Validate$ifBlank = F2(
	function (subjectToString, error) {
		return A2(
			$rtfeldman$elm_validate$Validate$ifTrue,
			function (subject) {
				return $rtfeldman$elm_validate$Validate$isBlank(
					subjectToString(subject));
			},
			error);
	});
var $elm$regex$Regex$Match = F4(
	function (match, index, number, submatches) {
		return {dA: index, dH: match, dV: number, ed: submatches};
	});
var $elm$regex$Regex$contains = _Regex_contains;
var $elm$regex$Regex$fromStringWith = _Regex_fromStringWith;
var $elm$regex$Regex$never = _Regex_never;
var $elm$core$Maybe$withDefault = F2(
	function (_default, maybe) {
		if (!maybe.$) {
			var value = maybe.a;
			return value;
		} else {
			return _default;
		}
	});
var $rtfeldman$elm_validate$Validate$validEmail = A2(
	$elm$core$Maybe$withDefault,
	$elm$regex$Regex$never,
	A2(
		$elm$regex$Regex$fromStringWith,
		{dh: true, dN: false},
		'^[a-zA-Z0-9.!#$%&\'*+\\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$'));
var $rtfeldman$elm_validate$Validate$isValidEmail = function (email) {
	return A2($elm$regex$Regex$contains, $rtfeldman$elm_validate$Validate$validEmail, email);
};
var $rtfeldman$elm_validate$Validate$ifInvalidEmail = F2(
	function (subjectToEmail, errorFromEmail) {
		var getErrors = function (subject) {
			var email = subjectToEmail(subject);
			return $rtfeldman$elm_validate$Validate$isValidEmail(email) ? _List_Nil : _List_fromArray(
				[
					errorFromEmail(email)
				]);
		};
		return getErrors;
	});
var $author$project$Mailer$emailValidator = $rtfeldman$elm_validate$Validate$firstError(
	_List_fromArray(
		[
			A2(
			$rtfeldman$elm_validate$Validate$ifInvalidEmail,
			$elm$core$Basics$identity,
			function (_v0) {
				return 'Please enter a valid email address.';
			}),
			A2($rtfeldman$elm_validate$Validate$ifBlank, $elm$core$Basics$identity, 'Please enter a valid email address.')
		]));
var $rtfeldman$elm_validate$Validate$Valid = $elm$core$Basics$identity;
var $rtfeldman$elm_validate$Validate$validate = F2(
	function (_v0, subject) {
		var getErrors = _v0;
		var _v1 = getErrors(subject);
		if (!_v1.b) {
			return $elm$core$Result$Ok(subject);
		} else {
			var errors = _v1;
			return $elm$core$Result$Err(errors);
		}
	});
var $author$project$Mailer$validateEmail = function (email) {
	return A2($rtfeldman$elm_validate$Validate$validate, $author$project$Mailer$emailValidator, email);
};
var $author$project$Mailer$init = function (_v0) {
	return _Utils_Tuple2(
		{
			a4: '',
			aa: 0,
			bk: $author$project$Mailer$validateEmail('')
		},
		$elm$core$Platform$Cmd$none);
};
var $elm$core$Platform$Sub$batch = _Platform_batch;
var $elm$core$Platform$Sub$none = $elm$core$Platform$Sub$batch(_List_Nil);
var $author$project$Mailer$subscriptions = function (model) {
	return $elm$core$Platform$Sub$none;
};
var $author$project$Mailer$Attempted = 1;
var $author$project$Mailer$Failure = 4;
var $author$project$Mailer$Sending = 2;
var $author$project$Mailer$Success = 3;
var $elm_community$result_extra$Result$Extra$isOk = function (x) {
	if (!x.$) {
		return true;
	} else {
		return false;
	}
};
var $elm$core$Basics$neq = _Utils_notEqual;
var $author$project$Mailer$Response = function (a) {
	return {$: 2, a: a};
};
var $elm$json$Json$Encode$object = function (pairs) {
	return _Json_wrap(
		A3(
			$elm$core$List$foldl,
			F2(
				function (_v0, obj) {
					var k = _v0.a;
					var v = _v0.b;
					return A3(_Json_addField, k, v, obj);
				}),
			_Json_emptyObject(0),
			pairs));
};
var $elm$json$Json$Encode$string = _Json_wrap;
var $author$project$Mailer$createRequestBody = function (email) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'action',
				$elm$json$Json$Encode$string('Subscribe')),
				_Utils_Tuple2(
				'email',
				$elm$json$Json$Encode$string(email))
			]));
};
var $elm$json$Json$Decode$andThen = _Json_andThen;
var $author$project$Mailer$RequestSuccess = 0;
var $elm$json$Json$Decode$fail = _Json_fail;
var $author$project$Mailer$decodeSuccess = function (value) {
	if (value === 'success') {
		return $elm$json$Json$Decode$succeed(0);
	} else {
		return $elm$json$Json$Decode$fail('Server error');
	}
};
var $elm$json$Json$Decode$field = _Json_decodeField;
var $elm$json$Json$Decode$string = _Json_decodeString;
var $author$project$Mailer$decodeResponse = A2(
	$elm$json$Json$Decode$andThen,
	$author$project$Mailer$decodeSuccess,
	A2($elm$json$Json$Decode$field, 'result', $elm$json$Json$Decode$string));
var $elm$json$Json$Decode$decodeString = _Json_runOnString;
var $elm$http$Http$BadStatus_ = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $elm$http$Http$BadUrl_ = function (a) {
	return {$: 0, a: a};
};
var $elm$http$Http$GoodStatus_ = F2(
	function (a, b) {
		return {$: 4, a: a, b: b};
	});
var $elm$http$Http$NetworkError_ = {$: 2};
var $elm$http$Http$Receiving = function (a) {
	return {$: 1, a: a};
};
var $elm$http$Http$Sending = function (a) {
	return {$: 0, a: a};
};
var $elm$http$Http$Timeout_ = {$: 1};
var $elm$core$Dict$RBEmpty_elm_builtin = {$: -2};
var $elm$core$Dict$empty = $elm$core$Dict$RBEmpty_elm_builtin;
var $elm$core$Maybe$isJust = function (maybe) {
	if (!maybe.$) {
		return true;
	} else {
		return false;
	}
};
var $elm$core$Platform$sendToSelf = _Platform_sendToSelf;
var $elm$core$Basics$compare = _Utils_compare;
var $elm$core$Dict$get = F2(
	function (targetKey, dict) {
		get:
		while (true) {
			if (dict.$ === -2) {
				return $elm$core$Maybe$Nothing;
			} else {
				var key = dict.b;
				var value = dict.c;
				var left = dict.d;
				var right = dict.e;
				var _v1 = A2($elm$core$Basics$compare, targetKey, key);
				switch (_v1) {
					case 0:
						var $temp$targetKey = targetKey,
							$temp$dict = left;
						targetKey = $temp$targetKey;
						dict = $temp$dict;
						continue get;
					case 1:
						return $elm$core$Maybe$Just(value);
					default:
						var $temp$targetKey = targetKey,
							$temp$dict = right;
						targetKey = $temp$targetKey;
						dict = $temp$dict;
						continue get;
				}
			}
		}
	});
var $elm$core$Dict$Black = 1;
var $elm$core$Dict$RBNode_elm_builtin = F5(
	function (a, b, c, d, e) {
		return {$: -1, a: a, b: b, c: c, d: d, e: e};
	});
var $elm$core$Dict$Red = 0;
var $elm$core$Dict$balance = F5(
	function (color, key, value, left, right) {
		if ((right.$ === -1) && (!right.a)) {
			var _v1 = right.a;
			var rK = right.b;
			var rV = right.c;
			var rLeft = right.d;
			var rRight = right.e;
			if ((left.$ === -1) && (!left.a)) {
				var _v3 = left.a;
				var lK = left.b;
				var lV = left.c;
				var lLeft = left.d;
				var lRight = left.e;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					0,
					key,
					value,
					A5($elm$core$Dict$RBNode_elm_builtin, 1, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 1, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					color,
					rK,
					rV,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, key, value, left, rLeft),
					rRight);
			}
		} else {
			if ((((left.$ === -1) && (!left.a)) && (left.d.$ === -1)) && (!left.d.a)) {
				var _v5 = left.a;
				var lK = left.b;
				var lV = left.c;
				var _v6 = left.d;
				var _v7 = _v6.a;
				var llK = _v6.b;
				var llV = _v6.c;
				var llLeft = _v6.d;
				var llRight = _v6.e;
				var lRight = left.e;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					0,
					lK,
					lV,
					A5($elm$core$Dict$RBNode_elm_builtin, 1, llK, llV, llLeft, llRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 1, key, value, lRight, right));
			} else {
				return A5($elm$core$Dict$RBNode_elm_builtin, color, key, value, left, right);
			}
		}
	});
var $elm$core$Dict$insertHelp = F3(
	function (key, value, dict) {
		if (dict.$ === -2) {
			return A5($elm$core$Dict$RBNode_elm_builtin, 0, key, value, $elm$core$Dict$RBEmpty_elm_builtin, $elm$core$Dict$RBEmpty_elm_builtin);
		} else {
			var nColor = dict.a;
			var nKey = dict.b;
			var nValue = dict.c;
			var nLeft = dict.d;
			var nRight = dict.e;
			var _v1 = A2($elm$core$Basics$compare, key, nKey);
			switch (_v1) {
				case 0:
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						A3($elm$core$Dict$insertHelp, key, value, nLeft),
						nRight);
				case 1:
					return A5($elm$core$Dict$RBNode_elm_builtin, nColor, nKey, value, nLeft, nRight);
				default:
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						nLeft,
						A3($elm$core$Dict$insertHelp, key, value, nRight));
			}
		}
	});
var $elm$core$Dict$insert = F3(
	function (key, value, dict) {
		var _v0 = A3($elm$core$Dict$insertHelp, key, value, dict);
		if ((_v0.$ === -1) && (!_v0.a)) {
			var _v1 = _v0.a;
			var k = _v0.b;
			var v = _v0.c;
			var l = _v0.d;
			var r = _v0.e;
			return A5($elm$core$Dict$RBNode_elm_builtin, 1, k, v, l, r);
		} else {
			var x = _v0;
			return x;
		}
	});
var $elm$core$Dict$getMin = function (dict) {
	getMin:
	while (true) {
		if ((dict.$ === -1) && (dict.d.$ === -1)) {
			var left = dict.d;
			var $temp$dict = left;
			dict = $temp$dict;
			continue getMin;
		} else {
			return dict;
		}
	}
};
var $elm$core$Dict$moveRedLeft = function (dict) {
	if (((dict.$ === -1) && (dict.d.$ === -1)) && (dict.e.$ === -1)) {
		if ((dict.e.d.$ === -1) && (!dict.e.d.a)) {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v1 = dict.d;
			var lClr = _v1.a;
			var lK = _v1.b;
			var lV = _v1.c;
			var lLeft = _v1.d;
			var lRight = _v1.e;
			var _v2 = dict.e;
			var rClr = _v2.a;
			var rK = _v2.b;
			var rV = _v2.c;
			var rLeft = _v2.d;
			var _v3 = rLeft.a;
			var rlK = rLeft.b;
			var rlV = rLeft.c;
			var rlL = rLeft.d;
			var rlR = rLeft.e;
			var rRight = _v2.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				0,
				rlK,
				rlV,
				A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					rlL),
				A5($elm$core$Dict$RBNode_elm_builtin, 1, rK, rV, rlR, rRight));
		} else {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v4 = dict.d;
			var lClr = _v4.a;
			var lK = _v4.b;
			var lV = _v4.c;
			var lLeft = _v4.d;
			var lRight = _v4.e;
			var _v5 = dict.e;
			var rClr = _v5.a;
			var rK = _v5.b;
			var rV = _v5.c;
			var rLeft = _v5.d;
			var rRight = _v5.e;
			if (clr === 1) {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			}
		}
	} else {
		return dict;
	}
};
var $elm$core$Dict$moveRedRight = function (dict) {
	if (((dict.$ === -1) && (dict.d.$ === -1)) && (dict.e.$ === -1)) {
		if ((dict.d.d.$ === -1) && (!dict.d.d.a)) {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v1 = dict.d;
			var lClr = _v1.a;
			var lK = _v1.b;
			var lV = _v1.c;
			var _v2 = _v1.d;
			var _v3 = _v2.a;
			var llK = _v2.b;
			var llV = _v2.c;
			var llLeft = _v2.d;
			var llRight = _v2.e;
			var lRight = _v1.e;
			var _v4 = dict.e;
			var rClr = _v4.a;
			var rK = _v4.b;
			var rV = _v4.c;
			var rLeft = _v4.d;
			var rRight = _v4.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				0,
				lK,
				lV,
				A5($elm$core$Dict$RBNode_elm_builtin, 1, llK, llV, llLeft, llRight),
				A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					lRight,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight)));
		} else {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v5 = dict.d;
			var lClr = _v5.a;
			var lK = _v5.b;
			var lV = _v5.c;
			var lLeft = _v5.d;
			var lRight = _v5.e;
			var _v6 = dict.e;
			var rClr = _v6.a;
			var rK = _v6.b;
			var rV = _v6.c;
			var rLeft = _v6.d;
			var rRight = _v6.e;
			if (clr === 1) {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			}
		}
	} else {
		return dict;
	}
};
var $elm$core$Dict$removeHelpPrepEQGT = F7(
	function (targetKey, dict, color, key, value, left, right) {
		if ((left.$ === -1) && (!left.a)) {
			var _v1 = left.a;
			var lK = left.b;
			var lV = left.c;
			var lLeft = left.d;
			var lRight = left.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				color,
				lK,
				lV,
				lLeft,
				A5($elm$core$Dict$RBNode_elm_builtin, 0, key, value, lRight, right));
		} else {
			_v2$2:
			while (true) {
				if ((right.$ === -1) && (right.a === 1)) {
					if (right.d.$ === -1) {
						if (right.d.a === 1) {
							var _v3 = right.a;
							var _v4 = right.d;
							var _v5 = _v4.a;
							return $elm$core$Dict$moveRedRight(dict);
						} else {
							break _v2$2;
						}
					} else {
						var _v6 = right.a;
						var _v7 = right.d;
						return $elm$core$Dict$moveRedRight(dict);
					}
				} else {
					break _v2$2;
				}
			}
			return dict;
		}
	});
var $elm$core$Dict$removeMin = function (dict) {
	if ((dict.$ === -1) && (dict.d.$ === -1)) {
		var color = dict.a;
		var key = dict.b;
		var value = dict.c;
		var left = dict.d;
		var lColor = left.a;
		var lLeft = left.d;
		var right = dict.e;
		if (lColor === 1) {
			if ((lLeft.$ === -1) && (!lLeft.a)) {
				var _v3 = lLeft.a;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					color,
					key,
					value,
					$elm$core$Dict$removeMin(left),
					right);
			} else {
				var _v4 = $elm$core$Dict$moveRedLeft(dict);
				if (_v4.$ === -1) {
					var nColor = _v4.a;
					var nKey = _v4.b;
					var nValue = _v4.c;
					var nLeft = _v4.d;
					var nRight = _v4.e;
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						$elm$core$Dict$removeMin(nLeft),
						nRight);
				} else {
					return $elm$core$Dict$RBEmpty_elm_builtin;
				}
			}
		} else {
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				color,
				key,
				value,
				$elm$core$Dict$removeMin(left),
				right);
		}
	} else {
		return $elm$core$Dict$RBEmpty_elm_builtin;
	}
};
var $elm$core$Dict$removeHelp = F2(
	function (targetKey, dict) {
		if (dict.$ === -2) {
			return $elm$core$Dict$RBEmpty_elm_builtin;
		} else {
			var color = dict.a;
			var key = dict.b;
			var value = dict.c;
			var left = dict.d;
			var right = dict.e;
			if (_Utils_cmp(targetKey, key) < 0) {
				if ((left.$ === -1) && (left.a === 1)) {
					var _v4 = left.a;
					var lLeft = left.d;
					if ((lLeft.$ === -1) && (!lLeft.a)) {
						var _v6 = lLeft.a;
						return A5(
							$elm$core$Dict$RBNode_elm_builtin,
							color,
							key,
							value,
							A2($elm$core$Dict$removeHelp, targetKey, left),
							right);
					} else {
						var _v7 = $elm$core$Dict$moveRedLeft(dict);
						if (_v7.$ === -1) {
							var nColor = _v7.a;
							var nKey = _v7.b;
							var nValue = _v7.c;
							var nLeft = _v7.d;
							var nRight = _v7.e;
							return A5(
								$elm$core$Dict$balance,
								nColor,
								nKey,
								nValue,
								A2($elm$core$Dict$removeHelp, targetKey, nLeft),
								nRight);
						} else {
							return $elm$core$Dict$RBEmpty_elm_builtin;
						}
					}
				} else {
					return A5(
						$elm$core$Dict$RBNode_elm_builtin,
						color,
						key,
						value,
						A2($elm$core$Dict$removeHelp, targetKey, left),
						right);
				}
			} else {
				return A2(
					$elm$core$Dict$removeHelpEQGT,
					targetKey,
					A7($elm$core$Dict$removeHelpPrepEQGT, targetKey, dict, color, key, value, left, right));
			}
		}
	});
var $elm$core$Dict$removeHelpEQGT = F2(
	function (targetKey, dict) {
		if (dict.$ === -1) {
			var color = dict.a;
			var key = dict.b;
			var value = dict.c;
			var left = dict.d;
			var right = dict.e;
			if (_Utils_eq(targetKey, key)) {
				var _v1 = $elm$core$Dict$getMin(right);
				if (_v1.$ === -1) {
					var minKey = _v1.b;
					var minValue = _v1.c;
					return A5(
						$elm$core$Dict$balance,
						color,
						minKey,
						minValue,
						left,
						$elm$core$Dict$removeMin(right));
				} else {
					return $elm$core$Dict$RBEmpty_elm_builtin;
				}
			} else {
				return A5(
					$elm$core$Dict$balance,
					color,
					key,
					value,
					left,
					A2($elm$core$Dict$removeHelp, targetKey, right));
			}
		} else {
			return $elm$core$Dict$RBEmpty_elm_builtin;
		}
	});
var $elm$core$Dict$remove = F2(
	function (key, dict) {
		var _v0 = A2($elm$core$Dict$removeHelp, key, dict);
		if ((_v0.$ === -1) && (!_v0.a)) {
			var _v1 = _v0.a;
			var k = _v0.b;
			var v = _v0.c;
			var l = _v0.d;
			var r = _v0.e;
			return A5($elm$core$Dict$RBNode_elm_builtin, 1, k, v, l, r);
		} else {
			var x = _v0;
			return x;
		}
	});
var $elm$core$Dict$update = F3(
	function (targetKey, alter, dictionary) {
		var _v0 = alter(
			A2($elm$core$Dict$get, targetKey, dictionary));
		if (!_v0.$) {
			var value = _v0.a;
			return A3($elm$core$Dict$insert, targetKey, value, dictionary);
		} else {
			return A2($elm$core$Dict$remove, targetKey, dictionary);
		}
	});
var $elm$core$Basics$composeR = F3(
	function (f, g, x) {
		return g(
			f(x));
	});
var $elm$http$Http$expectStringResponse = F2(
	function (toMsg, toResult) {
		return A3(
			_Http_expect,
			'',
			$elm$core$Basics$identity,
			A2($elm$core$Basics$composeR, toResult, toMsg));
	});
var $elm$core$Result$mapError = F2(
	function (f, result) {
		if (!result.$) {
			var v = result.a;
			return $elm$core$Result$Ok(v);
		} else {
			var e = result.a;
			return $elm$core$Result$Err(
				f(e));
		}
	});
var $elm$http$Http$BadBody = function (a) {
	return {$: 4, a: a};
};
var $elm$http$Http$BadStatus = function (a) {
	return {$: 3, a: a};
};
var $elm$http$Http$BadUrl = function (a) {
	return {$: 0, a: a};
};
var $elm$http$Http$NetworkError = {$: 2};
var $elm$http$Http$Timeout = {$: 1};
var $elm$http$Http$resolve = F2(
	function (toResult, response) {
		switch (response.$) {
			case 0:
				var url = response.a;
				return $elm$core$Result$Err(
					$elm$http$Http$BadUrl(url));
			case 1:
				return $elm$core$Result$Err($elm$http$Http$Timeout);
			case 2:
				return $elm$core$Result$Err($elm$http$Http$NetworkError);
			case 3:
				var metadata = response.a;
				return $elm$core$Result$Err(
					$elm$http$Http$BadStatus(metadata.d9));
			default:
				var body = response.b;
				return A2(
					$elm$core$Result$mapError,
					$elm$http$Http$BadBody,
					toResult(body));
		}
	});
var $elm$http$Http$expectJson = F2(
	function (toMsg, decoder) {
		return A2(
			$elm$http$Http$expectStringResponse,
			toMsg,
			$elm$http$Http$resolve(
				function (string) {
					return A2(
						$elm$core$Result$mapError,
						$elm$json$Json$Decode$errorToString,
						A2($elm$json$Json$Decode$decodeString, decoder, string));
				}));
	});
var $elm$http$Http$Request = function (a) {
	return {$: 1, a: a};
};
var $elm$http$Http$State = F2(
	function (reqs, subs) {
		return {cL: reqs, c2: subs};
	});
var $elm$http$Http$init = $elm$core$Task$succeed(
	A2($elm$http$Http$State, $elm$core$Dict$empty, _List_Nil));
var $elm$core$Process$kill = _Scheduler_kill;
var $elm$core$Process$spawn = _Scheduler_spawn;
var $elm$http$Http$updateReqs = F3(
	function (router, cmds, reqs) {
		updateReqs:
		while (true) {
			if (!cmds.b) {
				return $elm$core$Task$succeed(reqs);
			} else {
				var cmd = cmds.a;
				var otherCmds = cmds.b;
				if (!cmd.$) {
					var tracker = cmd.a;
					var _v2 = A2($elm$core$Dict$get, tracker, reqs);
					if (_v2.$ === 1) {
						var $temp$router = router,
							$temp$cmds = otherCmds,
							$temp$reqs = reqs;
						router = $temp$router;
						cmds = $temp$cmds;
						reqs = $temp$reqs;
						continue updateReqs;
					} else {
						var pid = _v2.a;
						return A2(
							$elm$core$Task$andThen,
							function (_v3) {
								return A3(
									$elm$http$Http$updateReqs,
									router,
									otherCmds,
									A2($elm$core$Dict$remove, tracker, reqs));
							},
							$elm$core$Process$kill(pid));
					}
				} else {
					var req = cmd.a;
					return A2(
						$elm$core$Task$andThen,
						function (pid) {
							var _v4 = req.c5;
							if (_v4.$ === 1) {
								return A3($elm$http$Http$updateReqs, router, otherCmds, reqs);
							} else {
								var tracker = _v4.a;
								return A3(
									$elm$http$Http$updateReqs,
									router,
									otherCmds,
									A3($elm$core$Dict$insert, tracker, pid, reqs));
							}
						},
						$elm$core$Process$spawn(
							A3(
								_Http_toTask,
								router,
								$elm$core$Platform$sendToApp(router),
								req)));
				}
			}
		}
	});
var $elm$http$Http$onEffects = F4(
	function (router, cmds, subs, state) {
		return A2(
			$elm$core$Task$andThen,
			function (reqs) {
				return $elm$core$Task$succeed(
					A2($elm$http$Http$State, reqs, subs));
			},
			A3($elm$http$Http$updateReqs, router, cmds, state.cL));
	});
var $elm$core$List$maybeCons = F3(
	function (f, mx, xs) {
		var _v0 = f(mx);
		if (!_v0.$) {
			var x = _v0.a;
			return A2($elm$core$List$cons, x, xs);
		} else {
			return xs;
		}
	});
var $elm$core$List$filterMap = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$foldr,
			$elm$core$List$maybeCons(f),
			_List_Nil,
			xs);
	});
var $elm$http$Http$maybeSend = F4(
	function (router, desiredTracker, progress, _v0) {
		var actualTracker = _v0.a;
		var toMsg = _v0.b;
		return _Utils_eq(desiredTracker, actualTracker) ? $elm$core$Maybe$Just(
			A2(
				$elm$core$Platform$sendToApp,
				router,
				toMsg(progress))) : $elm$core$Maybe$Nothing;
	});
var $elm$http$Http$onSelfMsg = F3(
	function (router, _v0, state) {
		var tracker = _v0.a;
		var progress = _v0.b;
		return A2(
			$elm$core$Task$andThen,
			function (_v1) {
				return $elm$core$Task$succeed(state);
			},
			$elm$core$Task$sequence(
				A2(
					$elm$core$List$filterMap,
					A3($elm$http$Http$maybeSend, router, tracker, progress),
					state.c2)));
	});
var $elm$http$Http$Cancel = function (a) {
	return {$: 0, a: a};
};
var $elm$http$Http$cmdMap = F2(
	function (func, cmd) {
		if (!cmd.$) {
			var tracker = cmd.a;
			return $elm$http$Http$Cancel(tracker);
		} else {
			var r = cmd.a;
			return $elm$http$Http$Request(
				{
					dd: r.dd,
					df: r.df,
					ds: A2(_Http_mapExpect, func, r.ds),
					z: r.z,
					ae: r.ae,
					E: r.E,
					c5: r.c5,
					ar: r.ar
				});
		}
	});
var $elm$http$Http$MySub = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$http$Http$subMap = F2(
	function (func, _v0) {
		var tracker = _v0.a;
		var toMsg = _v0.b;
		return A2(
			$elm$http$Http$MySub,
			tracker,
			A2($elm$core$Basics$composeR, toMsg, func));
	});
_Platform_effectManagers['Http'] = _Platform_createManager($elm$http$Http$init, $elm$http$Http$onEffects, $elm$http$Http$onSelfMsg, $elm$http$Http$cmdMap, $elm$http$Http$subMap);
var $elm$http$Http$command = _Platform_leaf('Http');
var $elm$http$Http$subscription = _Platform_leaf('Http');
var $elm$http$Http$request = function (r) {
	return $elm$http$Http$command(
		$elm$http$Http$Request(
			{dd: false, df: r.df, ds: r.ds, z: r.z, ae: r.ae, E: r.E, c5: r.c5, ar: r.ar}));
};
var $elm$http$Http$post = function (r) {
	return $elm$http$Http$request(
		{df: r.df, ds: r.ds, z: _List_Nil, ae: 'POST', E: $elm$core$Maybe$Nothing, c5: $elm$core$Maybe$Nothing, ar: r.ar});
};
var $elm$http$Http$stringBody = _Http_pair;
var $elm$url$Url$Builder$toQueryPair = function (_v0) {
	var key = _v0.a;
	var value = _v0.b;
	return key + ('=' + value);
};
var $elm$url$Url$Builder$toQuery = function (parameters) {
	if (!parameters.b) {
		return '';
	} else {
		return '?' + A2(
			$elm$core$String$join,
			'&',
			A2($elm$core$List$map, $elm$url$Url$Builder$toQueryPair, parameters));
	}
};
var $elm$url$Url$Builder$crossOrigin = F3(
	function (prePath, pathSegments, parameters) {
		return prePath + ('/' + (A2($elm$core$String$join, '/', pathSegments) + $elm$url$Url$Builder$toQuery(parameters)));
	});
var $author$project$Mailer$url = A3(
	$elm$url$Url$Builder$crossOrigin,
	'https://script.google.com',
	_List_fromArray(
		['macros', 's', 'AKfycbyGrqDjb5Fl7Fh08ov-ifqyK91sgWXmPhj3YHEA3_3KqVrMQ32ED8B9zRfl2Z4lwkZq', 'exec']),
	_List_Nil);
var $author$project$Mailer$postForm = function (email) {
	return $elm$http$Http$post(
		{
			df: A2(
				$elm$http$Http$stringBody,
				'text/plain',
				A2(
					$elm$json$Json$Encode$encode,
					0,
					$author$project$Mailer$createRequestBody(email))),
			ds: A2($elm$http$Http$expectJson, $author$project$Mailer$Response, $author$project$Mailer$decodeResponse),
			ar: $author$project$Mailer$url
		});
};
var $author$project$Mailer$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var value = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							a4: value,
							bk: $author$project$Mailer$validateEmail(value)
						}),
					$elm$core$Platform$Cmd$none);
			case 1:
				return ($elm_community$result_extra$Result$Extra$isOk(model.bk) && (model.aa !== 2)) ? _Utils_Tuple2(
					_Utils_update(
						model,
						{aa: 2}),
					$author$project$Mailer$postForm(model.a4)) : _Utils_Tuple2(
					_Utils_update(
						model,
						{aa: 1}),
					$elm$core$Platform$Cmd$none);
			default:
				var result = msg.a;
				if (!result.$) {
					var message = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{aa: 3}),
						$elm$core$Platform$Cmd$none);
				} else {
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{aa: 4}),
						$elm$core$Platform$Cmd$none);
				}
		}
	});
var $elm$html$Html$a = _VirtualDom_node('a');
var $elm$html$Html$Attributes$stringProperty = F2(
	function (key, string) {
		return A2(
			_VirtualDom_property,
			key,
			$elm$json$Json$Encode$string(string));
	});
var $elm$html$Html$Attributes$class = $elm$html$Html$Attributes$stringProperty('className');
var $elm$html$Html$div = _VirtualDom_node('div');
var $author$project$Mailer$Submit = {$: 1};
var $elm$core$Basics$always = F2(
	function (a, _v0) {
		return a;
	});
var $author$project$Mailer$Update = function (a) {
	return {$: 0, a: a};
};
var $elm$core$List$filter = F2(
	function (isGood, list) {
		return A3(
			$elm$core$List$foldr,
			F2(
				function (x, xs) {
					return isGood(x) ? A2($elm$core$List$cons, x, xs) : xs;
				}),
			_List_Nil,
			list);
	});
var $elm$core$Tuple$second = function (_v0) {
	var y = _v0.b;
	return y;
};
var $elm$html$Html$Attributes$classList = function (classes) {
	return $elm$html$Html$Attributes$class(
		A2(
			$elm$core$String$join,
			' ',
			A2(
				$elm$core$List$map,
				$elm$core$Tuple$first,
				A2($elm$core$List$filter, $elm$core$Tuple$second, classes))));
};
var $elm$json$Json$Encode$bool = _Json_wrap;
var $elm$html$Html$Attributes$boolProperty = F2(
	function (key, bool) {
		return A2(
			_VirtualDom_property,
			key,
			$elm$json$Json$Encode$bool(bool));
	});
var $elm$html$Html$Attributes$disabled = $elm$html$Html$Attributes$boolProperty('disabled');
var $elm$virtual_dom$VirtualDom$text = _VirtualDom_text;
var $elm$html$Html$text = $elm$virtual_dom$VirtualDom$text;
var $author$project$Mailer$errorView = function (error) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('f6 f5-l black-80 bg-washed-red ba b--light-red br2-ns pa3 lh-solid w-100 mv2')
			]),
		_List_fromArray(
			[
				$elm$html$Html$text(error)
			]));
};
var $elm$html$Html$input = _VirtualDom_node('input');
var $elm$core$List$isEmpty = function (xs) {
	if (!xs.b) {
		return true;
	} else {
		return false;
	}
};
var $elm$html$Html$label = _VirtualDom_node('label');
var $elm$core$Basics$not = _Basics_not;
var $elm$html$Html$Events$alwaysStop = function (x) {
	return _Utils_Tuple2(x, true);
};
var $elm$virtual_dom$VirtualDom$MayStopPropagation = function (a) {
	return {$: 1, a: a};
};
var $elm$virtual_dom$VirtualDom$on = _VirtualDom_on;
var $elm$html$Html$Events$stopPropagationOn = F2(
	function (event, decoder) {
		return A2(
			$elm$virtual_dom$VirtualDom$on,
			event,
			$elm$virtual_dom$VirtualDom$MayStopPropagation(decoder));
	});
var $elm$json$Json$Decode$at = F2(
	function (fields, decoder) {
		return A3($elm$core$List$foldr, $elm$json$Json$Decode$field, decoder, fields);
	});
var $elm$html$Html$Events$targetValue = A2(
	$elm$json$Json$Decode$at,
	_List_fromArray(
		['target', 'value']),
	$elm$json$Json$Decode$string);
var $elm$html$Html$Events$onInput = function (tagger) {
	return A2(
		$elm$html$Html$Events$stopPropagationOn,
		'input',
		A2(
			$elm$json$Json$Decode$map,
			$elm$html$Html$Events$alwaysStop,
			A2($elm$json$Json$Decode$map, tagger, $elm$html$Html$Events$targetValue)));
};
var $elm$html$Html$Attributes$placeholder = $elm$html$Html$Attributes$stringProperty('placeholder');
var $elm$html$Html$Attributes$type_ = $elm$html$Html$Attributes$stringProperty('type');
var $elm$html$Html$Attributes$value = $elm$html$Html$Attributes$stringProperty('value');
var $author$project$Mailer$emailInputView = F3(
	function (email, errors, isDisabled) {
		var children = _Utils_ap(
			_List_fromArray(
				[
					A2(
					$elm$html$Html$label,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('clip')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('Email Address')
						])),
					A2(
					$elm$html$Html$input,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$classList(
							_List_fromArray(
								[
									_Utils_Tuple2('f6 f5-l input-reset ba black-80 pa3 lh-solid w-100 br2-ns br--left-ns', true),
									_Utils_Tuple2(
									'b--light-red',
									!$elm$core$List$isEmpty(errors)),
									_Utils_Tuple2('b--white bg-white', !isDisabled),
									_Utils_Tuple2('b--moon-gray bg-moon-gray', isDisabled)
								])),
							$elm$html$Html$Attributes$type_('Text'),
							$elm$html$Html$Attributes$placeholder('Email Address'),
							$elm$html$Html$Attributes$value(email),
							$elm$html$Html$Attributes$disabled(isDisabled),
							$elm$html$Html$Events$onInput($author$project$Mailer$Update)
						]),
					_List_Nil)
				]),
			A2($elm$core$List$map, $author$project$Mailer$errorView, errors));
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('w-100 w-75-m w-80-l fl')
				]),
			children);
	});
var $elm$html$Html$fieldset = _VirtualDom_node('fieldset');
var $elm$html$Html$form = _VirtualDom_node('form');
var $elm$html$Html$Attributes$href = function (url) {
	return A2(
		$elm$html$Html$Attributes$stringProperty,
		'href',
		_VirtualDom_noJavaScriptUri(url));
};
var $elm$html$Html$legend = _VirtualDom_node('legend');
var $elm_community$result_extra$Result$Extra$mapBoth = F3(
	function (errFunc, okFunc, result) {
		if (!result.$) {
			var ok = result.a;
			return $elm$core$Result$Ok(
				okFunc(ok));
		} else {
			var err = result.a;
			return $elm$core$Result$Err(
				errFunc(err));
		}
	});
var $elm_community$result_extra$Result$Extra$merge = function (r) {
	if (!r.$) {
		var rr = r.a;
		return rr;
	} else {
		var rr = r.a;
		return rr;
	}
};
var $elm$html$Html$Events$alwaysPreventDefault = function (msg) {
	return _Utils_Tuple2(msg, true);
};
var $elm$virtual_dom$VirtualDom$MayPreventDefault = function (a) {
	return {$: 2, a: a};
};
var $elm$html$Html$Events$preventDefaultOn = F2(
	function (event, decoder) {
		return A2(
			$elm$virtual_dom$VirtualDom$on,
			event,
			$elm$virtual_dom$VirtualDom$MayPreventDefault(decoder));
	});
var $elm$html$Html$Events$onSubmit = function (msg) {
	return A2(
		$elm$html$Html$Events$preventDefaultOn,
		'submit',
		A2(
			$elm$json$Json$Decode$map,
			$elm$html$Html$Events$alwaysPreventDefault,
			$elm$json$Json$Decode$succeed(msg)));
};
var $elm$html$Html$small = _VirtualDom_node('small');
var $elm$html$Html$button = _VirtualDom_node('button');
var $author$project$Mailer$submitButtonView = function (isDisabled) {
	var buttonText = isDisabled ? 'Sending...' : 'Subscribe';
	return A2(
		$elm$html$Html$button,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$classList(
				_List_fromArray(
					[
						_Utils_Tuple2('f6 f5-l button-reset ba b--mid-gray fl lh-solid pv3 tc bg-animate white bg-mid-gray w-100 w-25-m w-20-l br2-ns br--right-ns', true),
						_Utils_Tuple2('hover-bg-gray pointer', !isDisabled)
					])),
				$elm$html$Html$Attributes$disabled(isDisabled)
			]),
		_List_fromArray(
			[
				$elm$html$Html$text(buttonText)
			]));
};
var $author$project$Mailer$formView = F2(
	function (model, isSending) {
		var showErrors = model.aa === 1;
		var errors = showErrors ? $elm_community$result_extra$Result$Extra$merge(
			A3(
				$elm_community$result_extra$Result$Extra$mapBoth,
				$elm$core$List$map($elm$core$Basics$identity),
				$elm$core$Basics$always(_List_Nil),
				model.bk)) : _List_Nil;
		return A2(
			$elm$html$Html$form,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class(''),
					$elm$html$Html$Events$onSubmit($author$project$Mailer$Submit)
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$fieldset,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('cf bn ma0 pa0')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$legend,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('f5 f4-ns fw2 mb3 pa0')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('Join the mailing list to be the first to know about sales and product updates.')
								])),
							A2(
							$elm$html$Html$small,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('f6 fw2')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('We don\'t send many emails and you can unsubscribe at any time. More information can be found in our '),
									A2(
									$elm$html$Html$a,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('link white underline dim'),
											$elm$html$Html$Attributes$href('/terms#privacy')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('privacy policy')
										])),
									$elm$html$Html$text('.')
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('cf mt3')
								]),
							_List_fromArray(
								[
									A3($author$project$Mailer$emailInputView, model.a4, errors, isSending),
									$author$project$Mailer$submitButtonView(isSending)
								]))
						]))
				]));
	});
var $elm$html$Html$h2 = _VirtualDom_node('h2');
var $elm$html$Html$p = _VirtualDom_node('p');
var $author$project$Mailer$view = function (model) {
	var child = function () {
		var _v0 = model.aa;
		switch (_v0) {
			case 0:
				return A2($author$project$Mailer$formView, model, false);
			case 1:
				return A2($author$project$Mailer$formView, model, false);
			case 2:
				return A2($author$project$Mailer$formView, model, true);
			case 3:
				return A2(
					$elm$html$Html$p,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('f5 f4-ns fw2 mb3 pa0')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('You\'ve successfully subscribed to be the first to know about sales and product updates. Thanks!')
						]));
			default:
				return A2(
					$elm$html$Html$p,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('f5 f4-ns fw2 mb3 pa0')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('Something unexpected has gone wrong. If you would still like to subscribe you can email us at '),
							A2(
							$elm$html$Html$a,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('white tl link dim dib ul'),
									$elm$html$Html$Attributes$href('mailto:hello@formeleven.com')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('hello@formeleven.com')
								])),
							$elm$html$Html$text('.')
						]));
		}
	}();
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('mw6 center')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$h2,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('f2 fw2 mt4 mt5-ns mb4 bb b--silver')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Mailing List')
					])),
				child
			]));
};
var $author$project$Mailer$main = $elm$browser$Browser$element(
	{dB: $author$project$Mailer$init, ee: $author$project$Mailer$subscriptions, ek: $author$project$Mailer$update, el: $author$project$Mailer$view});
var $author$project$Consent$Loading = function (a) {
	return {$: 0, a: a};
};
var $author$project$Consent$UpdateTime = function (a) {
	return {$: 5, a: a};
};
var $author$project$Consent$consentUpdate = _Platform_outgoingPort('consentUpdate', $elm$core$Basics$identity);
var $elm$json$Json$Decode$bool = _Json_decodeBool;
var $author$project$Consent$createConsentData = F2(
	function (dateTime, performance) {
		return {bp: dateTime, al: performance};
	});
var $elm$json$Json$Decode$int = _Json_decodeInt;
var $elm$time$Time$Posix = $elm$core$Basics$identity;
var $elm$time$Time$millisToPosix = $elm$core$Basics$identity;
var $author$project$Consent$consentDataDecoder = A3(
	$elm$json$Json$Decode$map2,
	$author$project$Consent$createConsentData,
	A2(
		$elm$json$Json$Decode$map,
		$elm$time$Time$millisToPosix,
		A2($elm$json$Json$Decode$field, 'dateTime', $elm$json$Json$Decode$int)),
	A2($elm$json$Json$Decode$field, 'performance', $elm$json$Json$Decode$bool));
var $author$project$Consent$decodeConsentData = function (value) {
	return A2($elm$json$Json$Decode$decodeString, $author$project$Consent$consentDataDecoder, value);
};
var $elm$json$Json$Encode$int = _Json_wrap;
var $elm$time$Time$posixToMillis = function (_v0) {
	var millis = _v0;
	return millis;
};
var $author$project$Consent$encodeConsentData = function (data) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'dateTime',
				$elm$json$Json$Encode$int(
					$elm$time$Time$posixToMillis(data.bp))),
				_Utils_Tuple2(
				'performance',
				$elm$json$Json$Encode$bool(data.al))
			]));
};
var $elm$core$Maybe$map = F2(
	function (f, maybe) {
		if (!maybe.$) {
			var value = maybe.a;
			return $elm$core$Maybe$Just(
				f(value));
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $elm$json$Json$Encode$null = _Json_encodeNull;
var $author$project$Consent$encodeMaybeConsentData = function (data) {
	return A2(
		$elm$core$Maybe$withDefault,
		$elm$json$Json$Encode$null,
		A2($elm$core$Maybe$map, $author$project$Consent$encodeConsentData, data));
};
var $elm$time$Time$Name = function (a) {
	return {$: 0, a: a};
};
var $elm$time$Time$Offset = function (a) {
	return {$: 1, a: a};
};
var $elm$time$Time$Zone = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$time$Time$customZone = $elm$time$Time$Zone;
var $elm$time$Time$now = _Time_now($elm$time$Time$millisToPosix);
var $elm$core$Result$toMaybe = function (result) {
	if (!result.$) {
		var v = result.a;
		return $elm$core$Maybe$Just(v);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Consent$init = function (flags) {
	var value = $elm$core$Result$toMaybe(
		$author$project$Consent$decodeConsentData(
			A2($elm$core$Maybe$withDefault, '', flags)));
	return _Utils_Tuple2(
		$author$project$Consent$Loading(value),
		$elm$core$Platform$Cmd$batch(
			_List_fromArray(
				[
					A2($elm$core$Task$perform, $author$project$Consent$UpdateTime, $elm$time$Time$now),
					$author$project$Consent$consentUpdate(
					$author$project$Consent$encodeMaybeConsentData(value))
				])));
};
var $elm$json$Json$Decode$null = _Json_decodeNull;
var $elm$json$Json$Decode$oneOf = _Json_oneOf;
var $author$project$Consent$subscriptions = function (_v0) {
	return $elm$core$Platform$Sub$none;
};
var $author$project$Consent$Loaded = function (a) {
	return {$: 1, a: a};
};
var $author$project$Consent$UIChoose = 2;
var $author$project$Consent$UIClosed = 0;
var $author$project$Consent$UIOpen = 1;
var $author$project$Consent$defaultConsent = function (time) {
	return A2($author$project$Consent$createConsentData, time, false);
};
var $author$project$Consent$updateTime = F2(
	function (time, consent) {
		return _Utils_update(
			consent,
			{bp: time});
	});
var $author$project$Consent$load = F2(
	function (time, loadedConsent) {
		if (!loadedConsent.$) {
			var consent = loadedConsent.a;
			return {
				O: A2($author$project$Consent$updateTime, time, consent),
				bi: 0
			};
		} else {
			return {
				O: $author$project$Consent$defaultConsent(time),
				bi: 1
			};
		}
	});
var $author$project$Consent$update = F2(
	function (msg, model) {
		if (!model.$) {
			var state = model.a;
			if (msg.$ === 5) {
				var time = msg.a;
				return _Utils_Tuple2(
					$author$project$Consent$Loaded(
						A2($author$project$Consent$load, time, state)),
					$elm$core$Platform$Cmd$none);
			} else {
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			}
		} else {
			var state = model.a;
			switch (msg.$) {
				case 6:
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				case 5:
					var time = msg.a;
					return _Utils_Tuple2(
						$author$project$Consent$Loaded(
							_Utils_update(
								state,
								{
									O: A2($author$project$Consent$updateTime, time, state.O)
								})),
						$elm$core$Platform$Cmd$none);
				case 0:
					return _Utils_Tuple2(
						$author$project$Consent$Loaded(
							_Utils_update(
								state,
								{bi: 2})),
						$elm$core$Platform$Cmd$none);
				case 1:
					return _Utils_Tuple2(
						$author$project$Consent$Loaded(
							_Utils_update(
								state,
								{bi: 2})),
						$elm$core$Platform$Cmd$none);
				case 3:
					var updateConsent = msg.a;
					var newConsent = updateConsent(state.O);
					return _Utils_Tuple2(
						$author$project$Consent$Loaded(
							_Utils_update(
								state,
								{O: newConsent, bi: 0})),
						$author$project$Consent$consentUpdate(
							$author$project$Consent$encodeConsentData(newConsent)));
				case 2:
					var updateConsent = msg.a;
					return _Utils_Tuple2(
						$author$project$Consent$Loaded(
							_Utils_update(
								state,
								{
									O: updateConsent(state.O)
								})),
						$elm$core$Platform$Cmd$none);
				default:
					return _Utils_Tuple2(
						$author$project$Consent$Loaded(
							_Utils_update(
								state,
								{bi: 0})),
						$author$project$Consent$consentUpdate(
							$author$project$Consent$encodeConsentData(state.O)));
			}
		}
	});
var $author$project$Consent$Close = {$: 4};
var $author$project$Consent$Noop = {$: 6};
var $author$project$Consent$Update = function (a) {
	return {$: 2, a: a};
};
var $author$project$Consent$UpdateAndClose = function (a) {
	return {$: 3, a: a};
};
var $author$project$Consent$chooseAll = function (consent) {
	return _Utils_update(
		consent,
		{al: true});
};
var $elm$html$Html$h3 = _VirtualDom_node('h3');
var $author$project$Components$h3 = function (attributes) {
	return $elm$html$Html$h3(
		A2(
			$elm$core$List$cons,
			$elm$html$Html$Attributes$class('f3 fw4 mh2 measure'),
			attributes));
};
var $elm$html$Html$h4 = _VirtualDom_node('h4');
var $author$project$Components$h4 = function (attributes) {
	return $elm$html$Html$h4(
		A2(
			$elm$core$List$cons,
			$elm$html$Html$Attributes$class('f4 fw4 mh2 measure'),
			attributes));
};
var $elm$virtual_dom$VirtualDom$Normal = function (a) {
	return {$: 0, a: a};
};
var $elm$html$Html$Events$on = F2(
	function (event, decoder) {
		return A2(
			$elm$virtual_dom$VirtualDom$on,
			event,
			$elm$virtual_dom$VirtualDom$Normal(decoder));
	});
var $elm$html$Html$Events$onClick = function (msg) {
	return A2(
		$elm$html$Html$Events$on,
		'click',
		$elm$json$Json$Decode$succeed(msg));
};
var $author$project$Components$p = function (content) {
	return A2(
		$elm$html$Html$p,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('f4 fw2 mh2 measure')
			]),
		_List_fromArray(
			[
				$elm$html$Html$text(content)
			]));
};
var $author$project$Consent$performanceToggler = function (consent) {
	return _Utils_update(
		consent,
		{al: !consent.al});
};
var $elm$core$List$append = F2(
	function (xs, ys) {
		if (!ys.b) {
			return xs;
		} else {
			return A3($elm$core$List$foldr, $elm$core$List$cons, ys, xs);
		}
	});
var $author$project$Consent$baseButton = F2(
	function (attributes, text) {
		var newAttributes = A2(
			$elm$core$List$append,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('f5 dim no-underline br-pill ph3 pt2 pb1 mb2 dib mh2')
				]),
			attributes);
		return A2(
			$elm$html$Html$button,
			newAttributes,
			_List_fromArray(
				[
					$elm$html$Html$text(text)
				]));
	});
var $author$project$Consent$primaryButton = F2(
	function (text, tagger) {
		return A2(
			$author$project$Consent$baseButton,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('bn white bg-black'),
					$elm$html$Html$Events$onClick(tagger)
				]),
			text);
	});
var $elm$html$Html$Attributes$checked = $elm$html$Html$Attributes$boolProperty('checked');
var $elm$html$Html$Attributes$for = $elm$html$Html$Attributes$stringProperty('htmlFor');
var $elm$html$Html$Attributes$id = $elm$html$Html$Attributes$stringProperty('id');
var $elm$html$Html$Attributes$name = $elm$html$Html$Attributes$stringProperty('name');
var $elm$html$Html$Events$targetChecked = A2(
	$elm$json$Json$Decode$at,
	_List_fromArray(
		['target', 'checked']),
	$elm$json$Json$Decode$bool);
var $elm$html$Html$Events$onCheck = function (tagger) {
	return A2(
		$elm$html$Html$Events$on,
		'change',
		A2($elm$json$Json$Decode$map, tagger, $elm$html$Html$Events$targetChecked));
};
var $elm$html$Html$span = _VirtualDom_node('span');
var $elm$virtual_dom$VirtualDom$style = _VirtualDom_style;
var $elm$html$Html$Attributes$style = $elm$virtual_dom$VirtualDom$style;
var $author$project$Consent$toggle = F5(
	function (id, label, tagger, disabled, checked) {
		var tickCheckedClass = checked ? 'black' : 'white';
		var nobCheckedStyle = checked ? 'translate(100%, 0%)' : 'translate(0%, 0%)';
		var labelDisabledClass = disabled ? 'bg-moon-gray moon-gray' : 'pointer';
		var labelCheckedClass = checked ? 'bg-black black' : 'bg-silver silver';
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('dib mh2')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$input,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('dn'),
							$elm$html$Html$Attributes$id(id),
							$elm$html$Html$Attributes$name(id),
							$elm$html$Html$Attributes$type_('checkbox'),
							$elm$html$Html$Attributes$checked(checked),
							$elm$html$Html$Attributes$disabled(disabled),
							$elm$html$Html$Events$onCheck(tagger)
						]),
					_List_Nil),
					A2(
					$elm$html$Html$label,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('dib w3 h2 br-pill ba bw1 flex'),
							$elm$html$Html$Attributes$class(labelCheckedClass),
							$elm$html$Html$Attributes$class(labelDisabledClass),
							A2($elm$html$Html$Attributes$style, 'transition', 'background .2s cubic-bezier(0.4, 0, 0.4, 1)'),
							$elm$html$Html$Attributes$for(id)
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$span,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('dib w2 h2 br-pill bg-white flex justify-center items-center'),
									A2($elm$html$Html$Attributes$style, 'transition', 'transform .2s cubic-bezier(0.4, 0, 0.4, 1)'),
									A2($elm$html$Html$Attributes$style, 'transform', nobCheckedStyle)
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$span,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('db w-25 h-50 br bb bw1 mb1'),
											$elm$html$Html$Attributes$class(tickCheckedClass),
											A2($elm$html$Html$Attributes$style, 'transform', 'rotate(45deg)'),
											A2($elm$html$Html$Attributes$style, 'transition', 'color .2s cubic-bezier(0.4, 0, 0.4, 1)')
										]),
									_List_Nil)
								])),
							A2(
							$elm$html$Html$span,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('dn')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(label)
								]))
						]))
				]));
	});
var $author$project$Consent$Open = {$: 0};
var $elm$html$Html$Attributes$title = $elm$html$Html$Attributes$stringProperty('title');
var $author$project$Components$consentLink = function (attributes) {
	var newAttributes = A2(
		$elm$core$List$append,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$id('cookie-consent'),
				$elm$html$Html$Attributes$class('link white underline dim bg-transparent bn pointer'),
				$elm$html$Html$Attributes$title('Consent Preferences')
			]),
		attributes);
	return A2(
		$elm$html$Html$button,
		newAttributes,
		_List_fromArray(
			[
				$elm$html$Html$text('Cookie Consent')
			]));
};
var $author$project$Consent$wrapper = function (content) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('dib')
			]),
		A2(
			$elm$core$List$append,
			_List_fromArray(
				[
					$author$project$Components$consentLink(
					_List_fromArray(
						[
							$elm$html$Html$Events$onClick($author$project$Consent$Open)
						]))
				]),
			content));
};
var $author$project$Consent$viewChoose = function (consent) {
	return $author$project$Consent$wrapper(
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('fixed bottom-0 left-0 black bg-white ba tl vh-100')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$button,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('absolute top-1 right-1 f5 link dim black ph2 pt2 pb1 ba border-box bg-white z-1'),
								$elm$html$Html$Events$onClick($author$project$Consent$Close)
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('Close')
							])),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('overflow-scroll vh-100 pa3')
							]),
						_List_fromArray(
							[
								A2(
								$author$project$Components$h3,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('Consent Preferences')
									])),
								$author$project$Components$p('\nWhen you visit any website, it may store or retrieve information on your\nbrowser, mostly in the form of cookies. This information might be about you,\nyour preferences or your device and is mostly used to make the site work as you\nexpect it to. The information does not usually directly identify you, but it\ncan give you a more personalised web experience. Because we respect your right\nto privacy, you can choose not to allow some types of cookies. Click on the\ndifferent category headings to find out more and change our default settings.\n'),
								A2(
								$author$project$Consent$primaryButton,
								'Allow All',
								$author$project$Consent$UpdateAndClose($author$project$Consent$chooseAll)),
								A2(
								$author$project$Components$h3,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('Manage Consent Preferences')
									])),
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('mv4 flex justify-between items-center')
									]),
								_List_fromArray(
									[
										A2(
										$author$project$Components$h4,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('mv0')
											]),
										_List_fromArray(
											[
												$elm$html$Html$text('Strictly Necessary Cookies')
											])),
										A5(
										$author$project$Consent$toggle,
										'input-necessary-cookies',
										'Strictly Necessary Cookies',
										$elm$core$Basics$always($author$project$Consent$Noop),
										true,
										true)
									])),
								$author$project$Components$p('\nThese cookies are necessary for the website to function and cannot be switched\noff in our systems. They are usually only set in response to actions made by\nyou which amount to a request for services, such as setting your privacy\npreferences, adding products to your basket or checking out. You can set your\nbrowser to block or alert you about these cookies, but some parts of the site\nwill not then work. These cookies do not store any personally identifiable\ninformation.\n'),
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('mv4 flex justify-between items-center')
									]),
								_List_fromArray(
									[
										A2(
										$author$project$Components$h4,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('mv0')
											]),
										_List_fromArray(
											[
												$elm$html$Html$text('Performance Cookies')
											])),
										A5(
										$author$project$Consent$toggle,
										'input-performance-cookies',
										'Performance Cookies',
										$elm$core$Basics$always(
											$author$project$Consent$Update($author$project$Consent$performanceToggler)),
										false,
										consent.al)
									])),
								$author$project$Components$p('\nThese cookies allow us to count visits and traffic sources, so we can measure\nand improve the performance of our site. They help us know which pages are the\nmost and least popular and see how visitors move around the site. All\ninformation these cookies collect is aggregated and therefore anonymous. If you\ndo not allow these cookies, we will not know when you have visited our site.\n'),
								A2($author$project$Consent$primaryButton, 'Save Preferences', $author$project$Consent$Close)
							]))
					]))
			]));
};
var $author$project$Consent$viewOnlyConsentLink = $author$project$Consent$wrapper(_List_Nil);
var $author$project$Consent$Choose = {$: 1};
var $author$project$Consent$secondaryButton = F2(
	function (text, tagger) {
		return A2(
			$author$project$Consent$baseButton,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('ba black bg-white'),
					$elm$html$Html$Events$onClick(tagger)
				]),
			text);
	});
var $author$project$Consent$viewOpen = $author$project$Consent$wrapper(
	_List_fromArray(
		[
			A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('fixed bottom-0 left-0 black bg-white ba tl pa2')
				]),
			_List_fromArray(
				[
					A2(
					$author$project$Components$h4,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text('You are in control of your own cookies')
						])),
					$author$project$Components$p('\nWe use cookies on this site. Some are strictly necessary to run the site while\nothers like those for measuring how the site is used are optional.\n'),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('flex flex-wrap')
						]),
					_List_fromArray(
						[
							A2(
							$author$project$Consent$primaryButton,
							'Accept All Cookies',
							$author$project$Consent$UpdateAndClose($author$project$Consent$chooseAll)),
							A2($author$project$Consent$secondaryButton, 'Cookie Settings', $author$project$Consent$Choose)
						]))
				]))
		]));
var $author$project$Consent$view = function (model) {
	if (!model.$) {
		var x = model.a;
		return $author$project$Consent$viewOnlyConsentLink;
	} else {
		var uiState = model.a.bi;
		var consent = model.a.O;
		switch (uiState) {
			case 0:
				return $author$project$Consent$viewOnlyConsentLink;
			case 1:
				return $author$project$Consent$viewOpen;
			default:
				return $author$project$Consent$viewChoose(consent);
		}
	}
};
var $author$project$Consent$main = $elm$browser$Browser$element(
	{dB: $author$project$Consent$init, ee: $author$project$Consent$subscriptions, ek: $author$project$Consent$update, el: $author$project$Consent$view});
var $author$project$Cart$CartUpdateFailed = {$: 8};
var $author$project$Cart$CartUpdated = function (a) {
	return {$: 7, a: a};
};
var $elm$core$Maybe$andThen = F2(
	function (callback, maybeValue) {
		if (!maybeValue.$) {
			var value = maybeValue.a;
			return callback(value);
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $elm$core$Platform$Cmd$map = _Platform_map;
var $author$project$Cart$cartApiResultToMsg = F3(
	function (happyMapper, unhappyMsg, cmd) {
		return A2(
			$elm$core$Platform$Cmd$map,
			$elm$core$Maybe$withDefault(unhappyMsg),
			A2(
				$elm$core$Platform$Cmd$map,
				$elm$core$Maybe$map(happyMapper),
				A2(
					$elm$core$Platform$Cmd$map,
					$elm$core$Maybe$andThen($elm$core$Basics$identity),
					A2($elm$core$Platform$Cmd$map, $elm$core$Result$toMaybe, cmd))));
	});
var $author$project$Shopify$Scalar$Id = $elm$core$Basics$identity;
var $elm$json$Json$Decode$nullable = function (decoder) {
	return $elm$json$Json$Decode$oneOf(
		_List_fromArray(
			[
				$elm$json$Json$Decode$null($elm$core$Maybe$Nothing),
				A2($elm$json$Json$Decode$map, $elm$core$Maybe$Just, decoder)
			]));
};
var $dillonkearns$elm_graphql$Graphql$SelectionSet$SelectionSet = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $dillonkearns$elm_graphql$Graphql$RawField$Composite = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$composite = F3(
	function (fieldName, args, fields) {
		return A3($dillonkearns$elm_graphql$Graphql$RawField$Composite, fieldName, args, fields);
	});
var $elm$core$String$concat = function (strings) {
	return A2($elm$core$String$join, '', strings);
};
var $dillonkearns$elm_graphql$Graphql$Document$Hash$HashData = F4(
	function (shift, seed, hash, charsProcessed) {
		return {az: charsProcessed, aH: hash, aq: seed, aV: shift};
	});
var $dillonkearns$elm_graphql$Graphql$Document$Hash$c1 = 3432918353;
var $dillonkearns$elm_graphql$Graphql$Document$Hash$c2 = 461845907;
var $elm$core$Bitwise$and = _Bitwise_and;
var $elm$core$Bitwise$shiftLeftBy = _Bitwise_shiftLeftBy;
var $elm$core$Bitwise$shiftRightZfBy = _Bitwise_shiftRightZfBy;
var $dillonkearns$elm_graphql$Graphql$Document$Hash$multiplyBy = F2(
	function (b, a) {
		return ((a & 65535) * b) + ((((a >>> 16) * b) & 65535) << 16);
	});
var $elm$core$Bitwise$or = _Bitwise_or;
var $dillonkearns$elm_graphql$Graphql$Document$Hash$rotlBy = F2(
	function (b, a) {
		return (a << b) | (a >>> (32 - b));
	});
var $elm$core$Bitwise$xor = _Bitwise_xor;
var $dillonkearns$elm_graphql$Graphql$Document$Hash$finalize = function (data) {
	var acc = (!(!data.aH)) ? (data.aq ^ A2(
		$dillonkearns$elm_graphql$Graphql$Document$Hash$multiplyBy,
		$dillonkearns$elm_graphql$Graphql$Document$Hash$c2,
		A2(
			$dillonkearns$elm_graphql$Graphql$Document$Hash$rotlBy,
			15,
			A2($dillonkearns$elm_graphql$Graphql$Document$Hash$multiplyBy, $dillonkearns$elm_graphql$Graphql$Document$Hash$c1, data.aH)))) : data.aq;
	var h0 = acc ^ data.az;
	var h1 = A2($dillonkearns$elm_graphql$Graphql$Document$Hash$multiplyBy, 2246822507, h0 ^ (h0 >>> 16));
	var h2 = A2($dillonkearns$elm_graphql$Graphql$Document$Hash$multiplyBy, 3266489909, h1 ^ (h1 >>> 13));
	return (h2 ^ (h2 >>> 16)) >>> 0;
};
var $elm$core$String$foldl = _String_foldl;
var $dillonkearns$elm_graphql$Graphql$Document$Hash$mix = F2(
	function (h1, k1) {
		return A2(
			$dillonkearns$elm_graphql$Graphql$Document$Hash$multiplyBy,
			5,
			A2(
				$dillonkearns$elm_graphql$Graphql$Document$Hash$rotlBy,
				13,
				h1 ^ A2(
					$dillonkearns$elm_graphql$Graphql$Document$Hash$multiplyBy,
					$dillonkearns$elm_graphql$Graphql$Document$Hash$c2,
					A2(
						$dillonkearns$elm_graphql$Graphql$Document$Hash$rotlBy,
						15,
						A2($dillonkearns$elm_graphql$Graphql$Document$Hash$multiplyBy, $dillonkearns$elm_graphql$Graphql$Document$Hash$c1, k1))))) + 3864292196;
	});
var $dillonkearns$elm_graphql$Graphql$Document$Hash$hashFold = F2(
	function (c, data) {
		var res = data.aH | ((255 & $elm$core$Char$toCode(c)) << data.aV);
		var _v0 = data.aV;
		if (_v0 === 24) {
			return {
				az: data.az + 1,
				aH: 0,
				aq: A2($dillonkearns$elm_graphql$Graphql$Document$Hash$mix, data.aq, res),
				aV: 0
			};
		} else {
			return {az: data.az + 1, aH: res, aq: data.aq, aV: data.aV + 8};
		}
	});
var $dillonkearns$elm_graphql$Graphql$Document$Hash$hashString = F2(
	function (seed, str) {
		return $dillonkearns$elm_graphql$Graphql$Document$Hash$finalize(
			A3(
				$elm$core$String$foldl,
				$dillonkearns$elm_graphql$Graphql$Document$Hash$hashFold,
				A4($dillonkearns$elm_graphql$Graphql$Document$Hash$HashData, 0, seed, 0, 0),
				str));
	});
var $dillonkearns$elm_graphql$Graphql$Internal$Encode$serialize = function (value) {
	switch (value.$) {
		case 0:
			var enumValue = value.a;
			return enumValue;
		case 1:
			var json = value.a;
			return A2($elm$json$Json$Encode$encode, 0, json);
		case 2:
			var values = value.a;
			return '[' + (A2(
				$elm$core$String$join,
				', ',
				A2($elm$core$List$map, $dillonkearns$elm_graphql$Graphql$Internal$Encode$serialize, values)) + ']');
		default:
			var keyValuePairs = value.a;
			return '{' + (A2(
				$elm$core$String$join,
				', ',
				A2(
					$elm$core$List$map,
					function (_v1) {
						var key = _v1.a;
						var objectValue = _v1.b;
						return key + (': ' + $dillonkearns$elm_graphql$Graphql$Internal$Encode$serialize(objectValue));
					},
					keyValuePairs)) + '}');
	}
};
var $dillonkearns$elm_graphql$Graphql$Document$Argument$argToString = function (_v0) {
	var name = _v0.a;
	var value = _v0.b;
	return name + (': ' + $dillonkearns$elm_graphql$Graphql$Internal$Encode$serialize(value));
};
var $dillonkearns$elm_graphql$Graphql$Document$Argument$serialize = function (args) {
	if (!args.b) {
		return '';
	} else {
		var nonemptyArgs = args;
		return '(' + (A2(
			$elm$core$String$join,
			', ',
			A2($elm$core$List$map, $dillonkearns$elm_graphql$Graphql$Document$Argument$argToString, nonemptyArgs)) + ')');
	}
};
var $elm$core$List$singleton = function (value) {
	return _List_fromArray(
		[value]);
};
var $dillonkearns$elm_graphql$Graphql$Document$Field$maybeAliasHash = function (field) {
	return A2(
		$elm$core$Maybe$map,
		$dillonkearns$elm_graphql$Graphql$Document$Hash$hashString(0),
		function () {
			if (!field.$) {
				var name = field.a;
				var _arguments = field.b;
				var children = field.c;
				return $elm$core$List$isEmpty(_arguments) ? $elm$core$Maybe$Nothing : $elm$core$Maybe$Just(
					$dillonkearns$elm_graphql$Graphql$Document$Argument$serialize(_arguments));
			} else {
				var typeString = field.a.ej;
				var fieldName = field.a.cc;
				var _arguments = field.b;
				return (fieldName === '__typename') ? $elm$core$Maybe$Nothing : $elm$core$Maybe$Just(
					$elm$core$String$concat(
						A2(
							$elm$core$List$append,
							_List_fromArray(
								[typeString]),
							$elm$core$List$singleton(
								$dillonkearns$elm_graphql$Graphql$Document$Argument$serialize(_arguments)))));
			}
		}());
};
var $dillonkearns$elm_graphql$Graphql$RawField$name = function (field) {
	if (!field.$) {
		var fieldName = field.a;
		var argumentList = field.b;
		var fieldList = field.c;
		return fieldName;
	} else {
		var typeString = field.a.ej;
		var fieldName = field.a.cc;
		var argumentList = field.b;
		return fieldName;
	}
};
var $dillonkearns$elm_graphql$Graphql$Document$Field$alias = function (field) {
	return A2(
		$elm$core$Maybe$map,
		function (aliasHash) {
			return _Utils_ap(
				$dillonkearns$elm_graphql$Graphql$RawField$name(field),
				$elm$core$String$fromInt(aliasHash));
		},
		$dillonkearns$elm_graphql$Graphql$Document$Field$maybeAliasHash(field));
};
var $dillonkearns$elm_graphql$Graphql$Document$Field$hashedAliasName = function (field) {
	return A2(
		$elm$core$Maybe$withDefault,
		$dillonkearns$elm_graphql$Graphql$RawField$name(field),
		$dillonkearns$elm_graphql$Graphql$Document$Field$alias(field));
};
var $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField = F4(
	function (fieldName, args, _v0, decoderTransform) {
		var fields = _v0.a;
		var decoder = _v0.b;
		return A2(
			$dillonkearns$elm_graphql$Graphql$SelectionSet$SelectionSet,
			_List_fromArray(
				[
					A3($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$composite, fieldName, args, fields)
				]),
			$elm$json$Json$Decode$oneOf(
				_List_fromArray(
					[
						A2(
						$elm$json$Json$Decode$field,
						fieldName,
						decoderTransform(decoder)),
						A2(
						$elm$json$Json$Decode$field,
						$dillonkearns$elm_graphql$Graphql$Document$Field$hashedAliasName(
							A3($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$composite, fieldName, args, fields)),
						decoderTransform(decoder))
					])));
	});
var $author$project$Shopify$Object$CartLinesAddPayload$cart = function (object____) {
	return A4(
		$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField,
		'cart',
		_List_Nil,
		object____,
		A2($elm$core$Basics$composeR, $elm$core$Basics$identity, $elm$json$Json$Decode$nullable));
};
var $author$project$Api$Cart = F4(
	function (id, checkoutUrl, lines, subTotal) {
		return {dj: checkoutUrl, aI: id, bt: lines, c1: subTotal};
	});
var $author$project$Money$Money = F2(
	function (amount, currencyCode) {
		return {bS: amount, dp: currencyCode};
	});
var $author$project$Shopify$Scalar$DateTime = $elm$core$Basics$identity;
var $author$project$Shopify$Scalar$Decimal = $elm$core$Basics$identity;
var $author$project$Shopify$Scalar$Html = $elm$core$Basics$identity;
var $author$project$Shopify$Scalar$Json = $elm$core$Basics$identity;
var $author$project$Shopify$Scalar$Money = $elm$core$Basics$identity;
var $author$project$Shopify$Scalar$Url = $elm$core$Basics$identity;
var $elm$json$Json$Decode$float = _Json_decodeFloat;
var $elm$core$String$fromFloat = _String_fromNumber;
var $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$scalarDecoder = $elm$json$Json$Decode$oneOf(
	_List_fromArray(
		[
			$elm$json$Json$Decode$string,
			A2($elm$json$Json$Decode$map, $elm$core$String$fromFloat, $elm$json$Json$Decode$float),
			A2($elm$json$Json$Decode$map, $elm$core$String$fromInt, $elm$json$Json$Decode$int),
			A2(
			$elm$json$Json$Decode$map,
			function (bool) {
				return bool ? 'true' : 'false';
			},
			$elm$json$Json$Decode$bool)
		]));
var $author$project$Shopify$Scalar$defaultCodecs = {
	bY: {
		bq: A2($elm$json$Json$Decode$map, $elm$core$Basics$identity, $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$scalarDecoder),
		ad: function (_v0) {
			var raw = _v0;
			return $elm$json$Json$Encode$string(raw);
		}
	},
	dl: {
		bq: A2($elm$json$Json$Decode$map, $elm$core$Basics$identity, $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$scalarDecoder),
		ad: function (_v1) {
			var raw = _v1;
			return $elm$json$Json$Encode$string(raw);
		}
	},
	dm: {
		bq: A2($elm$json$Json$Decode$map, $elm$core$Basics$identity, $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$scalarDecoder),
		ad: function (_v2) {
			var raw = _v2;
			return $elm$json$Json$Encode$string(raw);
		}
	},
	ac: {
		bq: A2($elm$json$Json$Decode$map, $elm$core$Basics$identity, $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$scalarDecoder),
		ad: function (_v3) {
			var raw = _v3;
			return $elm$json$Json$Encode$string(raw);
		}
	},
	bZ: {
		bq: A2($elm$json$Json$Decode$map, $elm$core$Basics$identity, $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$scalarDecoder),
		ad: function (_v4) {
			var raw = _v4;
			return $elm$json$Json$Encode$string(raw);
		}
	},
	b_: {
		bq: A2($elm$json$Json$Decode$map, $elm$core$Basics$identity, $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$scalarDecoder),
		ad: function (_v5) {
			var raw = _v5;
			return $elm$json$Json$Encode$string(raw);
		}
	},
	b$: {
		bq: A2($elm$json$Json$Decode$map, $elm$core$Basics$identity, $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$scalarDecoder),
		ad: function (_v6) {
			var raw = _v6;
			return $elm$json$Json$Encode$string(raw);
		}
	}
};
var $author$project$Shopify$Scalar$Codecs = $elm$core$Basics$identity;
var $author$project$Shopify$Scalar$defineCodecs = function (definitions) {
	return definitions;
};
var $author$project$Shopify$ScalarCodecs$codecs = $author$project$Shopify$Scalar$defineCodecs(
	{bY: $author$project$Shopify$Scalar$defaultCodecs.bY, dl: $author$project$Shopify$Scalar$defaultCodecs.dl, dm: $author$project$Shopify$Scalar$defaultCodecs.dm, ac: $author$project$Shopify$Scalar$defaultCodecs.ac, bZ: $author$project$Shopify$Scalar$defaultCodecs.bZ, b_: $author$project$Shopify$Scalar$defaultCodecs.b_, b$: $author$project$Shopify$Scalar$defaultCodecs.b$});
var $dillonkearns$elm_graphql$Graphql$RawField$Leaf = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$leaf = F2(
	function (details, args) {
		return A2($dillonkearns$elm_graphql$Graphql$RawField$Leaf, details, args);
	});
var $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField = F4(
	function (typeString, fieldName, args, decoder) {
		var newLeaf = A2(
			$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$leaf,
			{cc: fieldName, ej: typeString},
			args);
		return A2(
			$dillonkearns$elm_graphql$Graphql$SelectionSet$SelectionSet,
			_List_fromArray(
				[newLeaf]),
			$elm$json$Json$Decode$oneOf(
				_List_fromArray(
					[
						A2($elm$json$Json$Decode$field, fieldName, decoder),
						A2(
						$elm$json$Json$Decode$field,
						$dillonkearns$elm_graphql$Graphql$Document$Field$hashedAliasName(newLeaf),
						decoder)
					])));
	});
var $author$project$Shopify$Scalar$unwrapCodecs = function (_v0) {
	var unwrappedCodecs = _v0;
	return unwrappedCodecs;
};
var $author$project$Shopify$Object$MoneyV2$amount = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'ScalarCodecs.Decimal',
	'amount',
	_List_Nil,
	$author$project$Shopify$Scalar$unwrapCodecs($author$project$Shopify$ScalarCodecs$codecs).dl.bq);
var $author$project$Shopify$Enum$CurrencyCode$Aed = 131;
var $author$project$Shopify$Enum$CurrencyCode$Afn = 4;
var $author$project$Shopify$Enum$CurrencyCode$All = 5;
var $author$project$Shopify$Enum$CurrencyCode$Amd = 9;
var $author$project$Shopify$Enum$CurrencyCode$Ang = 89;
var $author$project$Shopify$Enum$CurrencyCode$Aoa = 7;
var $author$project$Shopify$Enum$CurrencyCode$Ars = 8;
var $author$project$Shopify$Enum$CurrencyCode$Aud = 11;
var $author$project$Shopify$Enum$CurrencyCode$Awg = 10;
var $author$project$Shopify$Enum$CurrencyCode$Azn = 13;
var $author$project$Shopify$Enum$CurrencyCode$Bam = 21;
var $author$project$Shopify$Enum$CurrencyCode$Bbd = 12;
var $author$project$Shopify$Enum$CurrencyCode$Bdt = 14;
var $author$project$Shopify$Enum$CurrencyCode$Bgn = 26;
var $author$project$Shopify$Enum$CurrencyCode$Bhd = 16;
var $author$project$Shopify$Enum$CurrencyCode$Bif = 17;
var $author$project$Shopify$Enum$CurrencyCode$Bmd = 19;
var $author$project$Shopify$Enum$CurrencyCode$Bnd = 25;
var $author$project$Shopify$Enum$CurrencyCode$Bob = 23;
var $author$project$Shopify$Enum$CurrencyCode$Brl = 22;
var $author$project$Shopify$Enum$CurrencyCode$Bsd = 15;
var $author$project$Shopify$Enum$CurrencyCode$Btn = 20;
var $author$project$Shopify$Enum$CurrencyCode$Bwp = 24;
var $author$project$Shopify$Enum$CurrencyCode$Byn = 139;
var $author$project$Shopify$Enum$CurrencyCode$Byr = 140;
var $author$project$Shopify$Enum$CurrencyCode$Bzd = 18;
var $author$project$Shopify$Enum$CurrencyCode$Cad = 3;
var $author$project$Shopify$Enum$CurrencyCode$Cdf = 36;
var $author$project$Shopify$Enum$CurrencyCode$Chf = 121;
var $author$project$Shopify$Enum$CurrencyCode$Clp = 32;
var $author$project$Shopify$Enum$CurrencyCode$Cny = 33;
var $author$project$Shopify$Enum$CurrencyCode$Cop = 34;
var $author$project$Shopify$Enum$CurrencyCode$Crc = 37;
var $author$project$Shopify$Enum$CurrencyCode$Cve = 29;
var $author$project$Shopify$Enum$CurrencyCode$Czk = 39;
var $author$project$Shopify$Enum$CurrencyCode$Djf = 141;
var $author$project$Shopify$Enum$CurrencyCode$Dkk = 40;
var $author$project$Shopify$Enum$CurrencyCode$Dop = 41;
var $author$project$Shopify$Enum$CurrencyCode$Dzd = 6;
var $author$project$Shopify$Enum$CurrencyCode$Egp = 43;
var $author$project$Shopify$Enum$CurrencyCode$Ern = 142;
var $author$project$Shopify$Enum$CurrencyCode$Etb = 44;
var $author$project$Shopify$Enum$CurrencyCode$Eur = 1;
var $author$project$Shopify$Enum$CurrencyCode$Fjd = 46;
var $author$project$Shopify$Enum$CurrencyCode$Fkp = 143;
var $author$project$Shopify$Enum$CurrencyCode$Gbp = 2;
var $author$project$Shopify$Enum$CurrencyCode$Gel = 51;
var $author$project$Shopify$Enum$CurrencyCode$Ghs = 48;
var $author$project$Shopify$Enum$CurrencyCode$Gip = 144;
var $author$project$Shopify$Enum$CurrencyCode$Gmd = 47;
var $author$project$Shopify$Enum$CurrencyCode$Gnf = 145;
var $author$project$Shopify$Enum$CurrencyCode$Gtq = 49;
var $author$project$Shopify$Enum$CurrencyCode$Gyd = 50;
var $author$project$Shopify$Enum$CurrencyCode$Hkd = 54;
var $author$project$Shopify$Enum$CurrencyCode$Hnl = 53;
var $author$project$Shopify$Enum$CurrencyCode$Hrk = 38;
var $author$project$Shopify$Enum$CurrencyCode$Htg = 52;
var $author$project$Shopify$Enum$CurrencyCode$Huf = 55;
var $author$project$Shopify$Enum$CurrencyCode$Idr = 58;
var $author$project$Shopify$Enum$CurrencyCode$Ils = 59;
var $author$project$Shopify$Enum$CurrencyCode$Inr = 57;
var $author$project$Shopify$Enum$CurrencyCode$Iqd = 60;
var $author$project$Shopify$Enum$CurrencyCode$Irr = 146;
var $author$project$Shopify$Enum$CurrencyCode$Isk = 56;
var $author$project$Shopify$Enum$CurrencyCode$Jep = 63;
var $author$project$Shopify$Enum$CurrencyCode$Jmd = 61;
var $author$project$Shopify$Enum$CurrencyCode$Jod = 64;
var $author$project$Shopify$Enum$CurrencyCode$Jpy = 62;
var $author$project$Shopify$Enum$CurrencyCode$Kes = 66;
var $author$project$Shopify$Enum$CurrencyCode$Kgs = 68;
var $author$project$Shopify$Enum$CurrencyCode$Khr = 28;
var $author$project$Shopify$Enum$CurrencyCode$Kid = 147;
var $author$project$Shopify$Enum$CurrencyCode$Kmf = 35;
var $author$project$Shopify$Enum$CurrencyCode$Krw = 114;
var $author$project$Shopify$Enum$CurrencyCode$Kwd = 67;
var $author$project$Shopify$Enum$CurrencyCode$Kyd = 30;
var $author$project$Shopify$Enum$CurrencyCode$Kzt = 65;
var $author$project$Shopify$Enum$CurrencyCode$Lak = 69;
var $author$project$Shopify$Enum$CurrencyCode$Lbp = 71;
var $author$project$Shopify$Enum$CurrencyCode$Lkr = 117;
var $author$project$Shopify$Enum$CurrencyCode$Lrd = 73;
var $author$project$Shopify$Enum$CurrencyCode$Lsl = 72;
var $author$project$Shopify$Enum$CurrencyCode$Ltl = 74;
var $author$project$Shopify$Enum$CurrencyCode$Lvl = 70;
var $author$project$Shopify$Enum$CurrencyCode$Lyd = 148;
var $author$project$Shopify$Enum$CurrencyCode$Mad = 84;
var $author$project$Shopify$Enum$CurrencyCode$Mdl = 83;
var $author$project$Shopify$Enum$CurrencyCode$Mga = 75;
var $author$project$Shopify$Enum$CurrencyCode$Mkd = 76;
var $author$project$Shopify$Enum$CurrencyCode$Mmk = 27;
var $author$project$Shopify$Enum$CurrencyCode$Mnt = 85;
var $author$project$Shopify$Enum$CurrencyCode$Mop = 77;
var $author$project$Shopify$Enum$CurrencyCode$Mru = 149;
var $author$project$Shopify$Enum$CurrencyCode$Mur = 82;
var $author$project$Shopify$Enum$CurrencyCode$Mvr = 79;
var $author$project$Shopify$Enum$CurrencyCode$Mwk = 78;
var $author$project$Shopify$Enum$CurrencyCode$Mxn = 80;
var $author$project$Shopify$Enum$CurrencyCode$Myr = 81;
var $author$project$Shopify$Enum$CurrencyCode$Mzn = 86;
var $author$project$Shopify$Enum$CurrencyCode$Nad = 87;
var $author$project$Shopify$Enum$CurrencyCode$Ngn = 92;
var $author$project$Shopify$Enum$CurrencyCode$Nio = 91;
var $author$project$Shopify$Enum$CurrencyCode$Nok = 93;
var $author$project$Shopify$Enum$CurrencyCode$Npr = 88;
var $author$project$Shopify$Enum$CurrencyCode$Nzd = 90;
var $author$project$Shopify$Enum$CurrencyCode$Omr = 94;
var $author$project$Shopify$Enum$CurrencyCode$Pab = 95;
var $author$project$Shopify$Enum$CurrencyCode$Pen = 99;
var $author$project$Shopify$Enum$CurrencyCode$Pgk = 97;
var $author$project$Shopify$Enum$CurrencyCode$Php = 100;
var $author$project$Shopify$Enum$CurrencyCode$Pkr = 96;
var $author$project$Shopify$Enum$CurrencyCode$Pln = 101;
var $author$project$Shopify$Enum$CurrencyCode$Pyg = 98;
var $author$project$Shopify$Enum$CurrencyCode$Qar = 102;
var $author$project$Shopify$Enum$CurrencyCode$Ron = 103;
var $author$project$Shopify$Enum$CurrencyCode$Rsd = 108;
var $author$project$Shopify$Enum$CurrencyCode$Rub = 104;
var $author$project$Shopify$Enum$CurrencyCode$Rwf = 105;
var $author$project$Shopify$Enum$CurrencyCode$Sar = 107;
var $author$project$Shopify$Enum$CurrencyCode$Sbd = 116;
var $author$project$Shopify$Enum$CurrencyCode$Scr = 109;
var $author$project$Shopify$Enum$CurrencyCode$Sdg = 111;
var $author$project$Shopify$Enum$CurrencyCode$Sek = 120;
var $author$project$Shopify$Enum$CurrencyCode$Sgd = 110;
var $author$project$Shopify$Enum$CurrencyCode$Shp = 151;
var $author$project$Shopify$Enum$CurrencyCode$Sll = 150;
var $author$project$Shopify$Enum$CurrencyCode$Sos = 152;
var $author$project$Shopify$Enum$CurrencyCode$Srd = 118;
var $author$project$Shopify$Enum$CurrencyCode$Ssp = 115;
var $author$project$Shopify$Enum$CurrencyCode$Std = 153;
var $author$project$Shopify$Enum$CurrencyCode$Stn = 154;
var $author$project$Shopify$Enum$CurrencyCode$Syp = 112;
var $author$project$Shopify$Enum$CurrencyCode$Szl = 119;
var $author$project$Shopify$Enum$CurrencyCode$Thb = 123;
var $author$project$Shopify$Enum$CurrencyCode$Tjs = 155;
var $author$project$Shopify$Enum$CurrencyCode$Tmt = 128;
var $author$project$Shopify$Enum$CurrencyCode$Tnd = 126;
var $author$project$Shopify$Enum$CurrencyCode$Top = 156;
var $author$project$Shopify$Enum$CurrencyCode$Try = 127;
var $author$project$Shopify$Enum$CurrencyCode$Ttd = 125;
var $author$project$Shopify$Enum$CurrencyCode$Twd = 122;
var $author$project$Shopify$Enum$CurrencyCode$Tzs = 124;
var $author$project$Shopify$Enum$CurrencyCode$Uah = 130;
var $author$project$Shopify$Enum$CurrencyCode$Ugx = 129;
var $author$project$Shopify$Enum$CurrencyCode$Usd = 0;
var $author$project$Shopify$Enum$CurrencyCode$Uyu = 132;
var $author$project$Shopify$Enum$CurrencyCode$Uzs = 133;
var $author$project$Shopify$Enum$CurrencyCode$Ved = 157;
var $author$project$Shopify$Enum$CurrencyCode$Vef = 158;
var $author$project$Shopify$Enum$CurrencyCode$Ves = 159;
var $author$project$Shopify$Enum$CurrencyCode$Vnd = 135;
var $author$project$Shopify$Enum$CurrencyCode$Vuv = 134;
var $author$project$Shopify$Enum$CurrencyCode$Wst = 106;
var $author$project$Shopify$Enum$CurrencyCode$Xaf = 31;
var $author$project$Shopify$Enum$CurrencyCode$Xcd = 42;
var $author$project$Shopify$Enum$CurrencyCode$Xof = 136;
var $author$project$Shopify$Enum$CurrencyCode$Xpf = 45;
var $author$project$Shopify$Enum$CurrencyCode$Xxx = 160;
var $author$project$Shopify$Enum$CurrencyCode$Yer = 137;
var $author$project$Shopify$Enum$CurrencyCode$Zar = 113;
var $author$project$Shopify$Enum$CurrencyCode$Zmw = 138;
var $author$project$Shopify$Enum$CurrencyCode$decoder = A2(
	$elm$json$Json$Decode$andThen,
	function (string) {
		switch (string) {
			case 'USD':
				return $elm$json$Json$Decode$succeed(0);
			case 'EUR':
				return $elm$json$Json$Decode$succeed(1);
			case 'GBP':
				return $elm$json$Json$Decode$succeed(2);
			case 'CAD':
				return $elm$json$Json$Decode$succeed(3);
			case 'AFN':
				return $elm$json$Json$Decode$succeed(4);
			case 'ALL':
				return $elm$json$Json$Decode$succeed(5);
			case 'DZD':
				return $elm$json$Json$Decode$succeed(6);
			case 'AOA':
				return $elm$json$Json$Decode$succeed(7);
			case 'ARS':
				return $elm$json$Json$Decode$succeed(8);
			case 'AMD':
				return $elm$json$Json$Decode$succeed(9);
			case 'AWG':
				return $elm$json$Json$Decode$succeed(10);
			case 'AUD':
				return $elm$json$Json$Decode$succeed(11);
			case 'BBD':
				return $elm$json$Json$Decode$succeed(12);
			case 'AZN':
				return $elm$json$Json$Decode$succeed(13);
			case 'BDT':
				return $elm$json$Json$Decode$succeed(14);
			case 'BSD':
				return $elm$json$Json$Decode$succeed(15);
			case 'BHD':
				return $elm$json$Json$Decode$succeed(16);
			case 'BIF':
				return $elm$json$Json$Decode$succeed(17);
			case 'BZD':
				return $elm$json$Json$Decode$succeed(18);
			case 'BMD':
				return $elm$json$Json$Decode$succeed(19);
			case 'BTN':
				return $elm$json$Json$Decode$succeed(20);
			case 'BAM':
				return $elm$json$Json$Decode$succeed(21);
			case 'BRL':
				return $elm$json$Json$Decode$succeed(22);
			case 'BOB':
				return $elm$json$Json$Decode$succeed(23);
			case 'BWP':
				return $elm$json$Json$Decode$succeed(24);
			case 'BND':
				return $elm$json$Json$Decode$succeed(25);
			case 'BGN':
				return $elm$json$Json$Decode$succeed(26);
			case 'MMK':
				return $elm$json$Json$Decode$succeed(27);
			case 'KHR':
				return $elm$json$Json$Decode$succeed(28);
			case 'CVE':
				return $elm$json$Json$Decode$succeed(29);
			case 'KYD':
				return $elm$json$Json$Decode$succeed(30);
			case 'XAF':
				return $elm$json$Json$Decode$succeed(31);
			case 'CLP':
				return $elm$json$Json$Decode$succeed(32);
			case 'CNY':
				return $elm$json$Json$Decode$succeed(33);
			case 'COP':
				return $elm$json$Json$Decode$succeed(34);
			case 'KMF':
				return $elm$json$Json$Decode$succeed(35);
			case 'CDF':
				return $elm$json$Json$Decode$succeed(36);
			case 'CRC':
				return $elm$json$Json$Decode$succeed(37);
			case 'HRK':
				return $elm$json$Json$Decode$succeed(38);
			case 'CZK':
				return $elm$json$Json$Decode$succeed(39);
			case 'DKK':
				return $elm$json$Json$Decode$succeed(40);
			case 'DOP':
				return $elm$json$Json$Decode$succeed(41);
			case 'XCD':
				return $elm$json$Json$Decode$succeed(42);
			case 'EGP':
				return $elm$json$Json$Decode$succeed(43);
			case 'ETB':
				return $elm$json$Json$Decode$succeed(44);
			case 'XPF':
				return $elm$json$Json$Decode$succeed(45);
			case 'FJD':
				return $elm$json$Json$Decode$succeed(46);
			case 'GMD':
				return $elm$json$Json$Decode$succeed(47);
			case 'GHS':
				return $elm$json$Json$Decode$succeed(48);
			case 'GTQ':
				return $elm$json$Json$Decode$succeed(49);
			case 'GYD':
				return $elm$json$Json$Decode$succeed(50);
			case 'GEL':
				return $elm$json$Json$Decode$succeed(51);
			case 'HTG':
				return $elm$json$Json$Decode$succeed(52);
			case 'HNL':
				return $elm$json$Json$Decode$succeed(53);
			case 'HKD':
				return $elm$json$Json$Decode$succeed(54);
			case 'HUF':
				return $elm$json$Json$Decode$succeed(55);
			case 'ISK':
				return $elm$json$Json$Decode$succeed(56);
			case 'INR':
				return $elm$json$Json$Decode$succeed(57);
			case 'IDR':
				return $elm$json$Json$Decode$succeed(58);
			case 'ILS':
				return $elm$json$Json$Decode$succeed(59);
			case 'IQD':
				return $elm$json$Json$Decode$succeed(60);
			case 'JMD':
				return $elm$json$Json$Decode$succeed(61);
			case 'JPY':
				return $elm$json$Json$Decode$succeed(62);
			case 'JEP':
				return $elm$json$Json$Decode$succeed(63);
			case 'JOD':
				return $elm$json$Json$Decode$succeed(64);
			case 'KZT':
				return $elm$json$Json$Decode$succeed(65);
			case 'KES':
				return $elm$json$Json$Decode$succeed(66);
			case 'KWD':
				return $elm$json$Json$Decode$succeed(67);
			case 'KGS':
				return $elm$json$Json$Decode$succeed(68);
			case 'LAK':
				return $elm$json$Json$Decode$succeed(69);
			case 'LVL':
				return $elm$json$Json$Decode$succeed(70);
			case 'LBP':
				return $elm$json$Json$Decode$succeed(71);
			case 'LSL':
				return $elm$json$Json$Decode$succeed(72);
			case 'LRD':
				return $elm$json$Json$Decode$succeed(73);
			case 'LTL':
				return $elm$json$Json$Decode$succeed(74);
			case 'MGA':
				return $elm$json$Json$Decode$succeed(75);
			case 'MKD':
				return $elm$json$Json$Decode$succeed(76);
			case 'MOP':
				return $elm$json$Json$Decode$succeed(77);
			case 'MWK':
				return $elm$json$Json$Decode$succeed(78);
			case 'MVR':
				return $elm$json$Json$Decode$succeed(79);
			case 'MXN':
				return $elm$json$Json$Decode$succeed(80);
			case 'MYR':
				return $elm$json$Json$Decode$succeed(81);
			case 'MUR':
				return $elm$json$Json$Decode$succeed(82);
			case 'MDL':
				return $elm$json$Json$Decode$succeed(83);
			case 'MAD':
				return $elm$json$Json$Decode$succeed(84);
			case 'MNT':
				return $elm$json$Json$Decode$succeed(85);
			case 'MZN':
				return $elm$json$Json$Decode$succeed(86);
			case 'NAD':
				return $elm$json$Json$Decode$succeed(87);
			case 'NPR':
				return $elm$json$Json$Decode$succeed(88);
			case 'ANG':
				return $elm$json$Json$Decode$succeed(89);
			case 'NZD':
				return $elm$json$Json$Decode$succeed(90);
			case 'NIO':
				return $elm$json$Json$Decode$succeed(91);
			case 'NGN':
				return $elm$json$Json$Decode$succeed(92);
			case 'NOK':
				return $elm$json$Json$Decode$succeed(93);
			case 'OMR':
				return $elm$json$Json$Decode$succeed(94);
			case 'PAB':
				return $elm$json$Json$Decode$succeed(95);
			case 'PKR':
				return $elm$json$Json$Decode$succeed(96);
			case 'PGK':
				return $elm$json$Json$Decode$succeed(97);
			case 'PYG':
				return $elm$json$Json$Decode$succeed(98);
			case 'PEN':
				return $elm$json$Json$Decode$succeed(99);
			case 'PHP':
				return $elm$json$Json$Decode$succeed(100);
			case 'PLN':
				return $elm$json$Json$Decode$succeed(101);
			case 'QAR':
				return $elm$json$Json$Decode$succeed(102);
			case 'RON':
				return $elm$json$Json$Decode$succeed(103);
			case 'RUB':
				return $elm$json$Json$Decode$succeed(104);
			case 'RWF':
				return $elm$json$Json$Decode$succeed(105);
			case 'WST':
				return $elm$json$Json$Decode$succeed(106);
			case 'SAR':
				return $elm$json$Json$Decode$succeed(107);
			case 'RSD':
				return $elm$json$Json$Decode$succeed(108);
			case 'SCR':
				return $elm$json$Json$Decode$succeed(109);
			case 'SGD':
				return $elm$json$Json$Decode$succeed(110);
			case 'SDG':
				return $elm$json$Json$Decode$succeed(111);
			case 'SYP':
				return $elm$json$Json$Decode$succeed(112);
			case 'ZAR':
				return $elm$json$Json$Decode$succeed(113);
			case 'KRW':
				return $elm$json$Json$Decode$succeed(114);
			case 'SSP':
				return $elm$json$Json$Decode$succeed(115);
			case 'SBD':
				return $elm$json$Json$Decode$succeed(116);
			case 'LKR':
				return $elm$json$Json$Decode$succeed(117);
			case 'SRD':
				return $elm$json$Json$Decode$succeed(118);
			case 'SZL':
				return $elm$json$Json$Decode$succeed(119);
			case 'SEK':
				return $elm$json$Json$Decode$succeed(120);
			case 'CHF':
				return $elm$json$Json$Decode$succeed(121);
			case 'TWD':
				return $elm$json$Json$Decode$succeed(122);
			case 'THB':
				return $elm$json$Json$Decode$succeed(123);
			case 'TZS':
				return $elm$json$Json$Decode$succeed(124);
			case 'TTD':
				return $elm$json$Json$Decode$succeed(125);
			case 'TND':
				return $elm$json$Json$Decode$succeed(126);
			case 'TRY':
				return $elm$json$Json$Decode$succeed(127);
			case 'TMT':
				return $elm$json$Json$Decode$succeed(128);
			case 'UGX':
				return $elm$json$Json$Decode$succeed(129);
			case 'UAH':
				return $elm$json$Json$Decode$succeed(130);
			case 'AED':
				return $elm$json$Json$Decode$succeed(131);
			case 'UYU':
				return $elm$json$Json$Decode$succeed(132);
			case 'UZS':
				return $elm$json$Json$Decode$succeed(133);
			case 'VUV':
				return $elm$json$Json$Decode$succeed(134);
			case 'VND':
				return $elm$json$Json$Decode$succeed(135);
			case 'XOF':
				return $elm$json$Json$Decode$succeed(136);
			case 'YER':
				return $elm$json$Json$Decode$succeed(137);
			case 'ZMW':
				return $elm$json$Json$Decode$succeed(138);
			case 'BYN':
				return $elm$json$Json$Decode$succeed(139);
			case 'BYR':
				return $elm$json$Json$Decode$succeed(140);
			case 'DJF':
				return $elm$json$Json$Decode$succeed(141);
			case 'ERN':
				return $elm$json$Json$Decode$succeed(142);
			case 'FKP':
				return $elm$json$Json$Decode$succeed(143);
			case 'GIP':
				return $elm$json$Json$Decode$succeed(144);
			case 'GNF':
				return $elm$json$Json$Decode$succeed(145);
			case 'IRR':
				return $elm$json$Json$Decode$succeed(146);
			case 'KID':
				return $elm$json$Json$Decode$succeed(147);
			case 'LYD':
				return $elm$json$Json$Decode$succeed(148);
			case 'MRU':
				return $elm$json$Json$Decode$succeed(149);
			case 'SLL':
				return $elm$json$Json$Decode$succeed(150);
			case 'SHP':
				return $elm$json$Json$Decode$succeed(151);
			case 'SOS':
				return $elm$json$Json$Decode$succeed(152);
			case 'STD':
				return $elm$json$Json$Decode$succeed(153);
			case 'STN':
				return $elm$json$Json$Decode$succeed(154);
			case 'TJS':
				return $elm$json$Json$Decode$succeed(155);
			case 'TOP':
				return $elm$json$Json$Decode$succeed(156);
			case 'VED':
				return $elm$json$Json$Decode$succeed(157);
			case 'VEF':
				return $elm$json$Json$Decode$succeed(158);
			case 'VES':
				return $elm$json$Json$Decode$succeed(159);
			case 'XXX':
				return $elm$json$Json$Decode$succeed(160);
			default:
				return $elm$json$Json$Decode$fail('Invalid CurrencyCode type, ' + (string + ' try re-running the @dillonkearns/elm-graphql CLI '));
		}
	},
	$elm$json$Json$Decode$string);
var $author$project$Shopify$Object$MoneyV2$currencyCode = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'Enum.CurrencyCode.CurrencyCode', 'currencyCode', _List_Nil, $author$project$Shopify$Enum$CurrencyCode$decoder);
var $dillonkearns$elm_graphql$Graphql$SelectionSet$map = F2(
	function (mapFunction, _v0) {
		var selectionFields = _v0.a;
		var selectionDecoder = _v0.b;
		return A2(
			$dillonkearns$elm_graphql$Graphql$SelectionSet$SelectionSet,
			selectionFields,
			A2($elm$json$Json$Decode$map, mapFunction, selectionDecoder));
	});
var $dillonkearns$elm_graphql$Graphql$SelectionSet$mapOrFail = F2(
	function (mapFunction, _v0) {
		var field = _v0.a;
		var decoder = _v0.b;
		return A2(
			$dillonkearns$elm_graphql$Graphql$SelectionSet$SelectionSet,
			field,
			A2(
				$elm$json$Json$Decode$andThen,
				function (result) {
					if (!result.$) {
						var value = result.a;
						return $elm$json$Json$Decode$succeed(value);
					} else {
						var errorMessage = result.a;
						return $elm$json$Json$Decode$fail('Check your code for calls to mapOrFail, your map function returned an `Err` with the message: ' + errorMessage);
					}
				},
				A2($elm$json$Json$Decode$map, mapFunction, decoder)));
	});
var $dillonkearns$elm_graphql$Graphql$SelectionSet$succeed = function (constructor) {
	return A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$SelectionSet,
		_List_Nil,
		$elm$json$Json$Decode$succeed(constructor));
};
var $author$project$Shopify$Enum$CurrencyCode$toString = function (enum____) {
	switch (enum____) {
		case 0:
			return 'USD';
		case 1:
			return 'EUR';
		case 2:
			return 'GBP';
		case 3:
			return 'CAD';
		case 4:
			return 'AFN';
		case 5:
			return 'ALL';
		case 6:
			return 'DZD';
		case 7:
			return 'AOA';
		case 8:
			return 'ARS';
		case 9:
			return 'AMD';
		case 10:
			return 'AWG';
		case 11:
			return 'AUD';
		case 12:
			return 'BBD';
		case 13:
			return 'AZN';
		case 14:
			return 'BDT';
		case 15:
			return 'BSD';
		case 16:
			return 'BHD';
		case 17:
			return 'BIF';
		case 18:
			return 'BZD';
		case 19:
			return 'BMD';
		case 20:
			return 'BTN';
		case 21:
			return 'BAM';
		case 22:
			return 'BRL';
		case 23:
			return 'BOB';
		case 24:
			return 'BWP';
		case 25:
			return 'BND';
		case 26:
			return 'BGN';
		case 27:
			return 'MMK';
		case 28:
			return 'KHR';
		case 29:
			return 'CVE';
		case 30:
			return 'KYD';
		case 31:
			return 'XAF';
		case 32:
			return 'CLP';
		case 33:
			return 'CNY';
		case 34:
			return 'COP';
		case 35:
			return 'KMF';
		case 36:
			return 'CDF';
		case 37:
			return 'CRC';
		case 38:
			return 'HRK';
		case 39:
			return 'CZK';
		case 40:
			return 'DKK';
		case 41:
			return 'DOP';
		case 42:
			return 'XCD';
		case 43:
			return 'EGP';
		case 44:
			return 'ETB';
		case 45:
			return 'XPF';
		case 46:
			return 'FJD';
		case 47:
			return 'GMD';
		case 48:
			return 'GHS';
		case 49:
			return 'GTQ';
		case 50:
			return 'GYD';
		case 51:
			return 'GEL';
		case 52:
			return 'HTG';
		case 53:
			return 'HNL';
		case 54:
			return 'HKD';
		case 55:
			return 'HUF';
		case 56:
			return 'ISK';
		case 57:
			return 'INR';
		case 58:
			return 'IDR';
		case 59:
			return 'ILS';
		case 60:
			return 'IQD';
		case 61:
			return 'JMD';
		case 62:
			return 'JPY';
		case 63:
			return 'JEP';
		case 64:
			return 'JOD';
		case 65:
			return 'KZT';
		case 66:
			return 'KES';
		case 67:
			return 'KWD';
		case 68:
			return 'KGS';
		case 69:
			return 'LAK';
		case 70:
			return 'LVL';
		case 71:
			return 'LBP';
		case 72:
			return 'LSL';
		case 73:
			return 'LRD';
		case 74:
			return 'LTL';
		case 75:
			return 'MGA';
		case 76:
			return 'MKD';
		case 77:
			return 'MOP';
		case 78:
			return 'MWK';
		case 79:
			return 'MVR';
		case 80:
			return 'MXN';
		case 81:
			return 'MYR';
		case 82:
			return 'MUR';
		case 83:
			return 'MDL';
		case 84:
			return 'MAD';
		case 85:
			return 'MNT';
		case 86:
			return 'MZN';
		case 87:
			return 'NAD';
		case 88:
			return 'NPR';
		case 89:
			return 'ANG';
		case 90:
			return 'NZD';
		case 91:
			return 'NIO';
		case 92:
			return 'NGN';
		case 93:
			return 'NOK';
		case 94:
			return 'OMR';
		case 95:
			return 'PAB';
		case 96:
			return 'PKR';
		case 97:
			return 'PGK';
		case 98:
			return 'PYG';
		case 99:
			return 'PEN';
		case 100:
			return 'PHP';
		case 101:
			return 'PLN';
		case 102:
			return 'QAR';
		case 103:
			return 'RON';
		case 104:
			return 'RUB';
		case 105:
			return 'RWF';
		case 106:
			return 'WST';
		case 107:
			return 'SAR';
		case 108:
			return 'RSD';
		case 109:
			return 'SCR';
		case 110:
			return 'SGD';
		case 111:
			return 'SDG';
		case 112:
			return 'SYP';
		case 113:
			return 'ZAR';
		case 114:
			return 'KRW';
		case 115:
			return 'SSP';
		case 116:
			return 'SBD';
		case 117:
			return 'LKR';
		case 118:
			return 'SRD';
		case 119:
			return 'SZL';
		case 120:
			return 'SEK';
		case 121:
			return 'CHF';
		case 122:
			return 'TWD';
		case 123:
			return 'THB';
		case 124:
			return 'TZS';
		case 125:
			return 'TTD';
		case 126:
			return 'TND';
		case 127:
			return 'TRY';
		case 128:
			return 'TMT';
		case 129:
			return 'UGX';
		case 130:
			return 'UAH';
		case 131:
			return 'AED';
		case 132:
			return 'UYU';
		case 133:
			return 'UZS';
		case 134:
			return 'VUV';
		case 135:
			return 'VND';
		case 136:
			return 'XOF';
		case 137:
			return 'YER';
		case 138:
			return 'ZMW';
		case 139:
			return 'BYN';
		case 140:
			return 'BYR';
		case 141:
			return 'DJF';
		case 142:
			return 'ERN';
		case 143:
			return 'FKP';
		case 144:
			return 'GIP';
		case 145:
			return 'GNF';
		case 146:
			return 'IRR';
		case 147:
			return 'KID';
		case 148:
			return 'LYD';
		case 149:
			return 'MRU';
		case 150:
			return 'SLL';
		case 151:
			return 'SHP';
		case 152:
			return 'SOS';
		case 153:
			return 'STD';
		case 154:
			return 'STN';
		case 155:
			return 'TJS';
		case 156:
			return 'TOP';
		case 157:
			return 'VED';
		case 158:
			return 'VEF';
		case 159:
			return 'VES';
		default:
			return 'XXX';
	}
};
var $elm$core$Result$fromMaybe = F2(
	function (err, maybe) {
		if (!maybe.$) {
			var v = maybe.a;
			return $elm$core$Result$Ok(v);
		} else {
			return $elm$core$Result$Err(err);
		}
	});
var $prikhi$decimal$Decimal$Decimal = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $cmditch$elm_bigint$BigInt$Negative = 1;
var $cmditch$elm_bigint$BigInt$Positive = 0;
var $cmditch$elm_bigint$BigInt$Magnitude = $elm$core$Basics$identity;
var $elm_community$maybe_extra$Maybe$Extra$combineHelp = F2(
	function (list, acc) {
		combineHelp:
		while (true) {
			if (list.b) {
				var head = list.a;
				var tail = list.b;
				if (!head.$) {
					var a = head.a;
					var $temp$list = tail,
						$temp$acc = A2($elm$core$List$cons, a, acc);
					list = $temp$list;
					acc = $temp$acc;
					continue combineHelp;
				} else {
					return $elm$core$Maybe$Nothing;
				}
			} else {
				return $elm$core$Maybe$Just(
					$elm$core$List$reverse(acc));
			}
		}
	});
var $elm_community$maybe_extra$Maybe$Extra$combine = function (list) {
	return A2($elm_community$maybe_extra$Maybe$Extra$combineHelp, list, _List_Nil);
};
var $elm$core$Basics$composeL = F3(
	function (g, f, x) {
		return g(
			f(x));
	});
var $elm_community$list_extra$List$Extra$dropWhile = F2(
	function (predicate, list) {
		dropWhile:
		while (true) {
			if (!list.b) {
				return _List_Nil;
			} else {
				var x = list.a;
				var xs = list.b;
				if (predicate(x)) {
					var $temp$predicate = predicate,
						$temp$list = xs;
					predicate = $temp$predicate;
					list = $temp$list;
					continue dropWhile;
				} else {
					return list;
				}
			}
		}
	});
var $cmditch$elm_bigint$BigInt$emptyZero = function (_v0) {
	var xs = _v0;
	var _v1 = A2(
		$elm_community$list_extra$List$Extra$dropWhile,
		$elm$core$Basics$eq(0),
		xs);
	if (!_v1.b) {
		return _List_Nil;
	} else {
		return xs;
	}
};
var $elm$core$String$fromList = _String_fromList;
var $elm$core$List$drop = F2(
	function (n, list) {
		drop:
		while (true) {
			if (n <= 0) {
				return list;
			} else {
				if (!list.b) {
					return list;
				} else {
					var x = list.a;
					var xs = list.b;
					var $temp$n = n - 1,
						$temp$list = xs;
					n = $temp$n;
					list = $temp$list;
					continue drop;
				}
			}
		}
	});
var $elm$core$List$takeReverse = F3(
	function (n, list, kept) {
		takeReverse:
		while (true) {
			if (n <= 0) {
				return kept;
			} else {
				if (!list.b) {
					return kept;
				} else {
					var x = list.a;
					var xs = list.b;
					var $temp$n = n - 1,
						$temp$list = xs,
						$temp$kept = A2($elm$core$List$cons, x, kept);
					n = $temp$n;
					list = $temp$list;
					kept = $temp$kept;
					continue takeReverse;
				}
			}
		}
	});
var $elm$core$List$takeTailRec = F2(
	function (n, list) {
		return $elm$core$List$reverse(
			A3($elm$core$List$takeReverse, n, list, _List_Nil));
	});
var $elm$core$List$takeFast = F3(
	function (ctr, n, list) {
		if (n <= 0) {
			return _List_Nil;
		} else {
			var _v0 = _Utils_Tuple2(n, list);
			_v0$1:
			while (true) {
				_v0$5:
				while (true) {
					if (!_v0.b.b) {
						return list;
					} else {
						if (_v0.b.b.b) {
							switch (_v0.a) {
								case 1:
									break _v0$1;
								case 2:
									var _v2 = _v0.b;
									var x = _v2.a;
									var _v3 = _v2.b;
									var y = _v3.a;
									return _List_fromArray(
										[x, y]);
								case 3:
									if (_v0.b.b.b.b) {
										var _v4 = _v0.b;
										var x = _v4.a;
										var _v5 = _v4.b;
										var y = _v5.a;
										var _v6 = _v5.b;
										var z = _v6.a;
										return _List_fromArray(
											[x, y, z]);
									} else {
										break _v0$5;
									}
								default:
									if (_v0.b.b.b.b && _v0.b.b.b.b.b) {
										var _v7 = _v0.b;
										var x = _v7.a;
										var _v8 = _v7.b;
										var y = _v8.a;
										var _v9 = _v8.b;
										var z = _v9.a;
										var _v10 = _v9.b;
										var w = _v10.a;
										var tl = _v10.b;
										return (ctr > 1000) ? A2(
											$elm$core$List$cons,
											x,
											A2(
												$elm$core$List$cons,
												y,
												A2(
													$elm$core$List$cons,
													z,
													A2(
														$elm$core$List$cons,
														w,
														A2($elm$core$List$takeTailRec, n - 4, tl))))) : A2(
											$elm$core$List$cons,
											x,
											A2(
												$elm$core$List$cons,
												y,
												A2(
													$elm$core$List$cons,
													z,
													A2(
														$elm$core$List$cons,
														w,
														A3($elm$core$List$takeFast, ctr + 1, n - 4, tl)))));
									} else {
										break _v0$5;
									}
							}
						} else {
							if (_v0.a === 1) {
								break _v0$1;
							} else {
								break _v0$5;
							}
						}
					}
				}
				return list;
			}
			var _v1 = _v0.b;
			var x = _v1.a;
			return _List_fromArray(
				[x]);
		}
	});
var $elm$core$List$take = F2(
	function (n, list) {
		return A3($elm$core$List$takeFast, 0, n, list);
	});
var $elm_community$list_extra$List$Extra$greedyGroupsOfWithStep = F3(
	function (size, step, list) {
		if ((size <= 0) || (step <= 0)) {
			return _List_Nil;
		} else {
			var go = F2(
				function (xs, acc) {
					go:
					while (true) {
						if ($elm$core$List$isEmpty(xs)) {
							return $elm$core$List$reverse(acc);
						} else {
							var $temp$xs = A2($elm$core$List$drop, step, xs),
								$temp$acc = A2(
								$elm$core$List$cons,
								A2($elm$core$List$take, size, xs),
								acc);
							xs = $temp$xs;
							acc = $temp$acc;
							continue go;
						}
					}
				});
			return A2(go, list, _List_Nil);
		}
	});
var $elm_community$list_extra$List$Extra$greedyGroupsOf = F2(
	function (size, xs) {
		return A3($elm_community$list_extra$List$Extra$greedyGroupsOfWithStep, size, size, xs);
	});
var $cmditch$elm_bigint$Constants$maxDigitMagnitude = 7;
var $cmditch$elm_bigint$BigInt$fromString_ = function (x) {
	return A2(
		$elm$core$Maybe$map,
		A2($elm$core$Basics$composeL, $cmditch$elm_bigint$BigInt$emptyZero, $elm$core$Basics$identity),
		$elm_community$maybe_extra$Maybe$Extra$combine(
			A2(
				$elm$core$List$map,
				A2(
					$elm$core$Basics$composeR,
					$elm$core$List$reverse,
					A2($elm$core$Basics$composeR, $elm$core$String$fromList, $elm$core$String$toInt)),
				A2(
					$elm_community$list_extra$List$Extra$greedyGroupsOf,
					$cmditch$elm_bigint$Constants$maxDigitMagnitude,
					$elm$core$List$reverse(x)))));
};
var $cmditch$elm_bigint$BigInt$Neg = function (a) {
	return {$: 1, a: a};
};
var $cmditch$elm_bigint$BigInt$Pos = function (a) {
	return {$: 0, a: a};
};
var $cmditch$elm_bigint$BigInt$Zer = {$: 2};
var $cmditch$elm_bigint$BigInt$mkBigInt = F2(
	function (s, mag) {
		var digits = mag;
		if ($elm$core$List$isEmpty(digits)) {
			return $cmditch$elm_bigint$BigInt$Zer;
		} else {
			switch (s) {
				case 2:
					return $cmditch$elm_bigint$BigInt$Zer;
				case 0:
					return $cmditch$elm_bigint$BigInt$Pos(mag);
				default:
					return $cmditch$elm_bigint$BigInt$Neg(mag);
			}
		}
	});
var $elm$core$String$foldr = _String_foldr;
var $elm$core$String$toList = function (string) {
	return A3($elm$core$String$foldr, $elm$core$List$cons, _List_Nil, string);
};
var $elm$core$String$toLower = _String_toLower;
var $cmditch$elm_bigint$BigInt$fromString = function (x) {
	var _v0 = $elm$core$String$toList(
		$elm$core$String$toLower(x));
	if (!_v0.b) {
		return $elm$core$Maybe$Nothing;
	} else {
		switch (_v0.a) {
			case '-':
				if (!_v0.b.b) {
					return $elm$core$Maybe$Nothing;
				} else {
					var xs = _v0.b;
					return A2(
						$elm$core$Maybe$map,
						$cmditch$elm_bigint$BigInt$mkBigInt(1),
						$cmditch$elm_bigint$BigInt$fromString_(xs));
				}
			case '+':
				if (!_v0.b.b) {
					return $elm$core$Maybe$Nothing;
				} else {
					var xs = _v0.b;
					return A2(
						$elm$core$Maybe$map,
						$cmditch$elm_bigint$BigInt$mkBigInt(0),
						$cmditch$elm_bigint$BigInt$fromString_(xs));
				}
			default:
				var xs = _v0;
				return A2(
					$elm$core$Maybe$map,
					$cmditch$elm_bigint$BigInt$mkBigInt(0),
					$cmditch$elm_bigint$BigInt$fromString_(xs));
		}
	}
};
var $elm$core$Basics$negate = function (n) {
	return -n;
};
var $prikhi$decimal$Decimal$fromString = function (str) {
	var stringToDecimal = function (s) {
		var stringIntToDecimal = F2(
			function (s_, e) {
				var _v8 = $cmditch$elm_bigint$BigInt$fromString(s_);
				if (_v8.$ === 1) {
					return $elm$core$Maybe$Nothing;
				} else {
					var a = _v8.a;
					return $elm$core$Maybe$Just(
						A2($prikhi$decimal$Decimal$Decimal, a, e));
				}
			});
		var _v6 = A2($elm$core$String$split, '.', s);
		_v6$2:
		while (true) {
			if (_v6.b) {
				if (_v6.b.b) {
					if (!_v6.b.b.b) {
						var a = _v6.a;
						var _v7 = _v6.b;
						var b = _v7.a;
						return A2(
							stringIntToDecimal,
							_Utils_ap(a, b),
							-$elm$core$String$length(b));
					} else {
						break _v6$2;
					}
				} else {
					var a = _v6.a;
					return A2(stringIntToDecimal, a, 0);
				}
			} else {
				break _v6$2;
			}
		}
		return $elm$core$Maybe$Nothing;
	};
	var splitMantissaExponent = function (s) {
		var _v4 = A2(
			$elm$core$String$split,
			'e',
			$elm$core$String$toLower(s));
		_v4$2:
		while (true) {
			if (_v4.b) {
				if (!_v4.b.b) {
					var s1 = _v4.a;
					return _Utils_Tuple2(
						stringToDecimal(s1),
						$elm$core$Maybe$Just(0));
				} else {
					if (!_v4.b.b.b) {
						var s1 = _v4.a;
						var _v5 = _v4.b;
						var s2 = _v5.a;
						return _Utils_Tuple2(
							stringToDecimal(s1),
							$elm$core$String$toInt(s2));
					} else {
						break _v4$2;
					}
				}
			} else {
				break _v4$2;
			}
		}
		return _Utils_Tuple2($elm$core$Maybe$Nothing, $elm$core$Maybe$Nothing);
	};
	var makeMantissa = function (s) {
		var _v2 = A2($elm$core$String$split, '.', s);
		_v2$2:
		while (true) {
			if (_v2.b) {
				if (!_v2.b.b) {
					var s1 = _v2.a;
					return stringToDecimal(s1);
				} else {
					if (!_v2.b.b.b) {
						var s1 = _v2.a;
						var _v3 = _v2.b;
						var s2 = _v3.a;
						return stringToDecimal(
							A2(
								$elm$core$String$join,
								'',
								_List_fromArray(
									[s1, s2])));
					} else {
						break _v2$2;
					}
				}
			} else {
				break _v2$2;
			}
		}
		return $elm$core$Maybe$Nothing;
	};
	var _v0 = splitMantissaExponent(str);
	if ((!_v0.a.$) && (!_v0.b.$)) {
		var _v1 = _v0.a.a;
		var m = _v1.a;
		var a = _v1.b;
		var e = _v0.b.a;
		return $elm$core$Maybe$Just(
			A2($prikhi$decimal$Decimal$Decimal, m, e + a));
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Api$unwrapDecimal = function (wrapped) {
	var value = wrapped;
	return value;
};
var $author$project$Api$transformDecimal = function (value) {
	var stringValue = $author$project$Api$unwrapDecimal(value);
	return A2(
		$elm$core$Result$fromMaybe,
		'Unable to parse \"' + (stringValue + '\" as Decimal'),
		$prikhi$decimal$Decimal$fromString(stringValue));
};
var $dillonkearns$elm_graphql$Graphql$SelectionSet$with = F2(
	function (_v0, _v1) {
		var selectionFields1 = _v0.a;
		var selectionDecoder1 = _v0.b;
		var selectionFields2 = _v1.a;
		var selectionDecoder2 = _v1.b;
		return A2(
			$dillonkearns$elm_graphql$Graphql$SelectionSet$SelectionSet,
			_Utils_ap(selectionFields1, selectionFields2),
			A3($elm$json$Json$Decode$map2, $elm$core$Basics$apR, selectionDecoder1, selectionDecoder2));
	});
var $author$project$Api$moneyV2Selection = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	A2($dillonkearns$elm_graphql$Graphql$SelectionSet$map, $author$project$Shopify$Enum$CurrencyCode$toString, $author$project$Shopify$Object$MoneyV2$currencyCode),
	A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
		A2($dillonkearns$elm_graphql$Graphql$SelectionSet$mapOrFail, $author$project$Api$transformDecimal, $author$project$Shopify$Object$MoneyV2$amount),
		$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Money$Money)));
var $author$project$Shopify$Object$CartCost$subtotalAmount = function (object____) {
	return A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField, 'subtotalAmount', _List_Nil, object____, $elm$core$Basics$identity);
};
var $author$project$Api$cartCostSelection = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$Shopify$Object$CartCost$subtotalAmount($author$project$Api$moneyV2Selection),
	$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($elm$core$Basics$identity));
var $dillonkearns$elm_graphql$Graphql$OptionalArgument$Present = function (a) {
	return {$: 0, a: a};
};
var $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent = {$: 1};
var $dillonkearns$elm_graphql$Graphql$Internal$Encode$Json = function (a) {
	return {$: 1, a: a};
};
var $dillonkearns$elm_graphql$Graphql$Internal$Encode$bool = function (value) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$Json(
		$elm$json$Json$Encode$bool(value));
};
var $dillonkearns$elm_graphql$Graphql$Internal$Encode$int = function (value) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$Json(
		$elm$json$Json$Encode$int(value));
};
var $dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$Argument = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $dillonkearns$elm_graphql$Graphql$Internal$Encode$null = $dillonkearns$elm_graphql$Graphql$Internal$Encode$Json($elm$json$Json$Encode$null);
var $dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$optional = F3(
	function (fieldName, maybeValue, toValue) {
		switch (maybeValue.$) {
			case 0:
				var value = maybeValue.a;
				return $elm$core$Maybe$Just(
					A2(
						$dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$Argument,
						fieldName,
						toValue(value)));
			case 1:
				return $elm$core$Maybe$Nothing;
			default:
				return $elm$core$Maybe$Just(
					A2($dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$Argument, fieldName, $dillonkearns$elm_graphql$Graphql$Internal$Encode$null));
		}
	});
var $dillonkearns$elm_graphql$Graphql$Internal$Encode$string = function (value) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$Json(
		$elm$json$Json$Encode$string(value));
};
var $author$project$Shopify$Object$Cart$lines = F2(
	function (fillInOptionals____, object____) {
		var filledInOptionals____ = fillInOptionals____(
			{aw: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, ay: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, dw: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, aK: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, aT: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent});
		var optionalArgs____ = A2(
			$elm$core$List$filterMap,
			$elm$core$Basics$identity,
			_List_fromArray(
				[
					A3($dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$optional, 'first', filledInOptionals____.dw, $dillonkearns$elm_graphql$Graphql$Internal$Encode$int),
					A3($dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$optional, 'after', filledInOptionals____.aw, $dillonkearns$elm_graphql$Graphql$Internal$Encode$string),
					A3($dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$optional, 'last', filledInOptionals____.aK, $dillonkearns$elm_graphql$Graphql$Internal$Encode$int),
					A3($dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$optional, 'before', filledInOptionals____.ay, $dillonkearns$elm_graphql$Graphql$Internal$Encode$string),
					A3($dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$optional, 'reverse', filledInOptionals____.aT, $dillonkearns$elm_graphql$Graphql$Internal$Encode$bool)
				]));
		return A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField, 'lines', optionalArgs____, object____, $elm$core$Basics$identity);
	});
var $author$project$Api$cartLinesFirstTen = $author$project$Shopify$Object$Cart$lines(
	function (optionals) {
		return _Utils_update(
			optionals,
			{
				dw: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Present(10)
			});
	});
var $author$project$Shopify$Object$Cart$checkoutUrl = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'ScalarCodecs.Url',
	'checkoutUrl',
	_List_Nil,
	$author$project$Shopify$Scalar$unwrapCodecs($author$project$Shopify$ScalarCodecs$codecs).b$.bq);
var $author$project$Shopify$Object$Cart$cost = function (object____) {
	return A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField, 'cost', _List_Nil, object____, $elm$core$Basics$identity);
};
var $author$project$Shopify$Object$Cart$id = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'ScalarCodecs.Id',
	'id',
	_List_Nil,
	$author$project$Shopify$Scalar$unwrapCodecs($author$project$Shopify$ScalarCodecs$codecs).ac.bq);
var $author$project$Api$CartLine = F4(
	function (id, productVariant, quantity, subTotal) {
		return {aI: id, d2: productVariant, am: quantity, c1: subTotal};
	});
var $author$project$Shopify$Object$CartLineCost$subtotalAmount = function (object____) {
	return A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField, 'subtotalAmount', _List_Nil, object____, $elm$core$Basics$identity);
};
var $author$project$Api$cartLineCostSelection = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$Shopify$Object$CartLineCost$subtotalAmount($author$project$Api$moneyV2Selection),
	$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($elm$core$Basics$identity));
var $author$project$Shopify$Object$CartLine$cost = function (object____) {
	return A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField, 'cost', _List_Nil, object____, $elm$core$Basics$identity);
};
var $author$project$Shopify$Object$CartLine$id = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'ScalarCodecs.Id',
	'id',
	_List_Nil,
	$author$project$Shopify$Scalar$unwrapCodecs($author$project$Shopify$ScalarCodecs$codecs).ac.bq);
var $author$project$Shopify$Object$CartLine$merchandise = function (object____) {
	return A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField, 'merchandise', _List_Nil, object____, $elm$core$Basics$identity);
};
var $dillonkearns$elm_graphql$Graphql$SelectionSet$FragmentSelectionSet = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$buildFragment = F2(
	function (fragmentTypeName, _v0) {
		var fields = _v0.a;
		var decoder = _v0.b;
		return A3($dillonkearns$elm_graphql$Graphql$SelectionSet$FragmentSelectionSet, fragmentTypeName, fields, decoder);
	});
var $elm$core$String$dropRight = F2(
	function (n, string) {
		return (n < 1) ? string : A3($elm$core$String$slice, 0, -n, string);
	});
var $elm$core$Array$bitMask = 4294967295 >>> (32 - $elm$core$Array$shiftStep);
var $elm$core$Basics$ge = _Utils_ge;
var $elm$core$Elm$JsArray$unsafeGet = _JsArray_unsafeGet;
var $elm$core$Array$getHelp = F3(
	function (shift, index, tree) {
		getHelp:
		while (true) {
			var pos = $elm$core$Array$bitMask & (index >>> shift);
			var _v0 = A2($elm$core$Elm$JsArray$unsafeGet, pos, tree);
			if (!_v0.$) {
				var subTree = _v0.a;
				var $temp$shift = shift - $elm$core$Array$shiftStep,
					$temp$index = index,
					$temp$tree = subTree;
				shift = $temp$shift;
				index = $temp$index;
				tree = $temp$tree;
				continue getHelp;
			} else {
				var values = _v0.a;
				return A2($elm$core$Elm$JsArray$unsafeGet, $elm$core$Array$bitMask & index, values);
			}
		}
	});
var $elm$core$Array$tailIndex = function (len) {
	return (len >>> 5) << 5;
};
var $elm$core$Array$get = F2(
	function (index, _v0) {
		var len = _v0.a;
		var startShift = _v0.b;
		var tree = _v0.c;
		var tail = _v0.d;
		return ((index < 0) || (_Utils_cmp(index, len) > -1)) ? $elm$core$Maybe$Nothing : ((_Utils_cmp(
			index,
			$elm$core$Array$tailIndex(len)) > -1) ? $elm$core$Maybe$Just(
			A2($elm$core$Elm$JsArray$unsafeGet, $elm$core$Array$bitMask & index, tail)) : $elm$core$Maybe$Just(
			A3($elm$core$Array$getHelp, startShift, index, tree)));
	});
var $lukewestby$elm_string_interpolate$String$Interpolate$applyInterpolation = F2(
	function (replacements, _v0) {
		var match = _v0.dH;
		var ordinalString = A2(
			$elm$core$Basics$composeL,
			$elm$core$String$dropLeft(1),
			$elm$core$String$dropRight(1))(match);
		return A2(
			$elm$core$Maybe$withDefault,
			'',
			A2(
				$elm$core$Maybe$andThen,
				function (value) {
					return A2($elm$core$Array$get, value, replacements);
				},
				$elm$core$String$toInt(ordinalString)));
	});
var $elm$core$Array$fromListHelp = F3(
	function (list, nodeList, nodeListSize) {
		fromListHelp:
		while (true) {
			var _v0 = A2($elm$core$Elm$JsArray$initializeFromList, $elm$core$Array$branchFactor, list);
			var jsArray = _v0.a;
			var remainingItems = _v0.b;
			if (_Utils_cmp(
				$elm$core$Elm$JsArray$length(jsArray),
				$elm$core$Array$branchFactor) < 0) {
				return A2(
					$elm$core$Array$builderToArray,
					true,
					{s: nodeList, m: nodeListSize, r: jsArray});
			} else {
				var $temp$list = remainingItems,
					$temp$nodeList = A2(
					$elm$core$List$cons,
					$elm$core$Array$Leaf(jsArray),
					nodeList),
					$temp$nodeListSize = nodeListSize + 1;
				list = $temp$list;
				nodeList = $temp$nodeList;
				nodeListSize = $temp$nodeListSize;
				continue fromListHelp;
			}
		}
	});
var $elm$core$Array$fromList = function (list) {
	if (!list.b) {
		return $elm$core$Array$empty;
	} else {
		return A3($elm$core$Array$fromListHelp, list, _List_Nil, 0);
	}
};
var $elm$regex$Regex$fromString = function (string) {
	return A2(
		$elm$regex$Regex$fromStringWith,
		{dh: false, dN: false},
		string);
};
var $lukewestby$elm_string_interpolate$String$Interpolate$interpolationRegex = A2(
	$elm$core$Maybe$withDefault,
	$elm$regex$Regex$never,
	$elm$regex$Regex$fromString('\\{\\d+\\}'));
var $elm$regex$Regex$replace = _Regex_replaceAtMost(_Regex_infinity);
var $lukewestby$elm_string_interpolate$String$Interpolate$interpolate = F2(
	function (string, args) {
		var asArray = $elm$core$Array$fromList(args);
		return A3(
			$elm$regex$Regex$replace,
			$lukewestby$elm_string_interpolate$String$Interpolate$interpolationRegex,
			$lukewestby$elm_string_interpolate$String$Interpolate$applyInterpolation(asArray),
			string);
	});
var $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$exhaustiveFailureMessage = F2(
	function (typeSpecificSelections, typeName) {
		return A2(
			$lukewestby$elm_string_interpolate$String$Interpolate$interpolate,
			'Unhandled type `{0}` in exhaustive fragment handling. The following types had handlers registered to handle them: [{1}]. This happens if you are parsing either a Union or Interface. Do you need to rerun the `@dillonkearns/elm-graphql` command line tool?',
			_List_fromArray(
				[
					typeName,
					A2(
					$elm$core$String$join,
					', ',
					A2(
						$elm$core$List$map,
						function (_v0) {
							var fragmentType = _v0.a;
							var fields = _v0.b;
							var decoder = _v0.c;
							return fragmentType;
						},
						typeSpecificSelections))
				]));
	});
var $elm$core$Dict$fromList = function (assocs) {
	return A3(
		$elm$core$List$foldl,
		F2(
			function (_v0, dict) {
				var key = _v0.a;
				var value = _v0.b;
				return A3($elm$core$Dict$insert, key, value, dict);
			}),
		$elm$core$Dict$empty,
		assocs);
};
var $dillonkearns$elm_graphql$Graphql$RawField$typename = A2(
	$dillonkearns$elm_graphql$Graphql$RawField$Leaf,
	{cc: '__typename', ej: ''},
	_List_Nil);
var $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$exhaustiveFragmentSelection = function (typeSpecificSelections) {
	var selections = A2(
		$elm$core$List$map,
		function (_v1) {
			var typeName = _v1.a;
			var fields = _v1.b;
			var decoder = _v1.c;
			return A3($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$composite, '...on ' + typeName, _List_Nil, fields);
		},
		typeSpecificSelections);
	return A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$SelectionSet,
		A2($elm$core$List$cons, $dillonkearns$elm_graphql$Graphql$RawField$typename, selections),
		A2(
			$elm$json$Json$Decode$andThen,
			function (typeName) {
				return A2(
					$elm$core$Maybe$withDefault,
					$elm$json$Json$Decode$fail(
						A2($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$exhaustiveFailureMessage, typeSpecificSelections, typeName)),
					A2(
						$elm$core$Dict$get,
						typeName,
						$elm$core$Dict$fromList(
							A2(
								$elm$core$List$map,
								function (_v0) {
									var thisTypeName = _v0.a;
									var fields = _v0.b;
									var decoder = _v0.c;
									return _Utils_Tuple2(thisTypeName, decoder);
								},
								typeSpecificSelections))));
			},
			A2(
				$elm$json$Json$Decode$field,
				$dillonkearns$elm_graphql$Graphql$Document$Field$hashedAliasName($dillonkearns$elm_graphql$Graphql$RawField$typename),
				$elm$json$Json$Decode$string)));
};
var $author$project$Shopify$Union$Merchandise$fragments = function (selections____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$exhaustiveFragmentSelection(
		_List_fromArray(
			[
				A2($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$buildFragment, 'ProductVariant', selections____.dW)
			]));
};
var $author$project$Api$ProductVariant = F5(
	function (id, image, price, product, quantityAvailable) {
		return {aI: id, dy: image, d0: price, d1: product, d3: quantityAvailable};
	});
var $author$project$Shopify$Object$ProductVariant$id = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'ScalarCodecs.Id',
	'id',
	_List_Nil,
	$author$project$Shopify$Scalar$unwrapCodecs($author$project$Shopify$ScalarCodecs$codecs).ac.bq);
var $author$project$Shopify$Object$ProductVariant$image = function (object____) {
	return A4(
		$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField,
		'image',
		_List_Nil,
		object____,
		A2($elm$core$Basics$composeR, $elm$core$Basics$identity, $elm$json$Json$Decode$nullable));
};
var $author$project$Api$Image = function (url) {
	return {ar: url};
};
var $author$project$Shopify$InputObject$buildImageTransformInput = function (fillOptionals____) {
	var optionals____ = fillOptionals____(
		{aD: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, dI: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, dJ: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, aO: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, aU: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent});
	return {aD: optionals____.aD, dI: optionals____.dI, dJ: optionals____.dJ, aO: optionals____.aO, aU: optionals____.aU};
};
var $author$project$Api$unwrapUrl = function (wrapped) {
	var value = wrapped;
	return value;
};
var $dillonkearns$elm_graphql$Graphql$Internal$Encode$EnumValue = function (a) {
	return {$: 0, a: a};
};
var $dillonkearns$elm_graphql$Graphql$Internal$Encode$enum = F2(
	function (enumToString, value) {
		return $dillonkearns$elm_graphql$Graphql$Internal$Encode$EnumValue(
			enumToString(value));
	});
var $dillonkearns$elm_graphql$Graphql$Internal$Encode$Object = function (a) {
	return {$: 3, a: a};
};
var $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject = function (maybeValues) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$Object(
		A2(
			$elm$core$List$filterMap,
			function (_v0) {
				var key = _v0.a;
				var value = _v0.b;
				return A2(
					$elm$core$Maybe$andThen,
					function (actualValue) {
						return $elm$core$Maybe$Just(
							_Utils_Tuple2(key, actualValue));
					},
					value);
			},
			maybeValues));
};
var $dillonkearns$elm_graphql$Graphql$Internal$Encode$optional = F2(
	function (optionalValue, toValue) {
		switch (optionalValue.$) {
			case 0:
				var value = optionalValue.a;
				return $elm$core$Maybe$Just(
					toValue(value));
			case 1:
				return $elm$core$Maybe$Nothing;
			default:
				return $elm$core$Maybe$Just($dillonkearns$elm_graphql$Graphql$Internal$Encode$null);
		}
	});
var $author$project$Shopify$Enum$CropRegion$toString = function (enum____) {
	switch (enum____) {
		case 0:
			return 'CENTER';
		case 1:
			return 'TOP';
		case 2:
			return 'BOTTOM';
		case 3:
			return 'LEFT';
		default:
			return 'RIGHT';
	}
};
var $author$project$Shopify$Enum$ImageContentType$toString = function (enum____) {
	switch (enum____) {
		case 0:
			return 'PNG';
		case 1:
			return 'JPG';
		default:
			return 'WEBP';
	}
};
var $author$project$Shopify$InputObject$encodeImageTransformInput = function (input____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'crop',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.aD,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Shopify$Enum$CropRegion$toString))),
				_Utils_Tuple2(
				'maxWidth',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.dJ, $dillonkearns$elm_graphql$Graphql$Internal$Encode$int)),
				_Utils_Tuple2(
				'maxHeight',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.dI, $dillonkearns$elm_graphql$Graphql$Internal$Encode$int)),
				_Utils_Tuple2(
				'scale',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.aU, $dillonkearns$elm_graphql$Graphql$Internal$Encode$int)),
				_Utils_Tuple2(
				'preferredContentType',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.aO,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Shopify$Enum$ImageContentType$toString)))
			]));
};
var $author$project$Shopify$Object$Image$url = function (fillInOptionals____) {
	var filledInOptionals____ = fillInOptionals____(
		{ei: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent});
	var optionalArgs____ = A2(
		$elm$core$List$filterMap,
		$elm$core$Basics$identity,
		_List_fromArray(
			[
				A3($dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$optional, 'transform', filledInOptionals____.ei, $author$project$Shopify$InputObject$encodeImageTransformInput)
			]));
	return A4(
		$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
		'ScalarCodecs.Url',
		'url',
		optionalArgs____,
		$author$project$Shopify$Scalar$unwrapCodecs($author$project$Shopify$ScalarCodecs$codecs).b$.bq);
};
var $author$project$Api$imageSelection = F2(
	function (maxWidth, maxHeight) {
		var imageTransformInput = $author$project$Shopify$InputObject$buildImageTransformInput(
			function (optionals) {
				return _Utils_update(
					optionals,
					{
						dI: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Present(maxHeight),
						dJ: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Present(maxWidth)
					});
			});
		var selectUrl = $author$project$Shopify$Object$Image$url(
			function (optionals) {
				return _Utils_update(
					optionals,
					{
						ei: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Present(imageTransformInput)
					});
			});
		return A2(
			$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
			A2($dillonkearns$elm_graphql$Graphql$SelectionSet$map, $author$project$Api$unwrapUrl, selectUrl),
			$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Api$Image));
	});
var $author$project$Shopify$Object$ProductVariant$priceV2 = function (object____) {
	return A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField, 'priceV2', _List_Nil, object____, $elm$core$Basics$identity);
};
var $author$project$Shopify$Object$ProductVariant$product = function (object____) {
	return A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField, 'product', _List_Nil, object____, $elm$core$Basics$identity);
};
var $author$project$Api$Product = F2(
	function (id, title) {
		return {aI: id, eh: title};
	});
var $author$project$Shopify$Object$Product$id = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'ScalarCodecs.Id',
	'id',
	_List_Nil,
	$author$project$Shopify$Scalar$unwrapCodecs($author$project$Shopify$ScalarCodecs$codecs).ac.bq);
var $author$project$Shopify$Object$Product$title = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'title', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Api$unwrapId = function (wrapped) {
	var value = wrapped;
	return value;
};
var $author$project$Api$productInfoSelection = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$Shopify$Object$Product$title,
	A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
		A2($dillonkearns$elm_graphql$Graphql$SelectionSet$map, $author$project$Api$unwrapId, $author$project$Shopify$Object$Product$id),
		$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Api$Product)));
var $author$project$Shopify$Object$ProductVariant$quantityAvailable = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'(Maybe Int)',
	'quantityAvailable',
	_List_Nil,
	$elm$json$Json$Decode$nullable($elm$json$Json$Decode$int));
var $author$project$Api$productVariantSelection = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$map,
		$elm$core$Maybe$withDefault(0),
		$author$project$Shopify$Object$ProductVariant$quantityAvailable),
	A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
		$author$project$Shopify$Object$ProductVariant$product($author$project$Api$productInfoSelection),
		A2(
			$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
			$author$project$Shopify$Object$ProductVariant$priceV2($author$project$Api$moneyV2Selection),
			A2(
				$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
				$author$project$Shopify$Object$ProductVariant$image(
					A2($author$project$Api$imageSelection, 128, 128)),
				A2(
					$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
					A2($dillonkearns$elm_graphql$Graphql$SelectionSet$map, $author$project$Api$unwrapId, $author$project$Shopify$Object$ProductVariant$id),
					$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Api$ProductVariant))))));
var $author$project$Api$merchandiseSelection = $author$project$Shopify$Union$Merchandise$fragments(
	{dW: $author$project$Api$productVariantSelection});
var $author$project$Shopify$Object$CartLine$quantity = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'Int', 'quantity', _List_Nil, $elm$json$Json$Decode$int);
var $author$project$Api$lineInfoSelection = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$Shopify$Object$CartLine$cost($author$project$Api$cartLineCostSelection),
	A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
		$author$project$Shopify$Object$CartLine$quantity,
		A2(
			$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
			$author$project$Shopify$Object$CartLine$merchandise($author$project$Api$merchandiseSelection),
			A2(
				$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
				A2($dillonkearns$elm_graphql$Graphql$SelectionSet$map, $author$project$Api$unwrapId, $author$project$Shopify$Object$CartLine$id),
				$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Api$CartLine)))));
var $elm$json$Json$Decode$list = _Json_decodeList;
var $author$project$Shopify$Object$CartLineConnection$nodes = function (object____) {
	return A4(
		$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField,
		'nodes',
		_List_Nil,
		object____,
		A2($elm$core$Basics$composeR, $elm$core$Basics$identity, $elm$json$Json$Decode$list));
};
var $author$project$Api$linesInfoSelection = $author$project$Shopify$Object$CartLineConnection$nodes($author$project$Api$lineInfoSelection);
var $author$project$Api$cartInfoSelection = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$Shopify$Object$Cart$cost($author$project$Api$cartCostSelection),
	A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
		$author$project$Api$cartLinesFirstTen($author$project$Api$linesInfoSelection),
		A2(
			$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
			A2($dillonkearns$elm_graphql$Graphql$SelectionSet$map, $author$project$Api$unwrapUrl, $author$project$Shopify$Object$Cart$checkoutUrl),
			A2(
				$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
				A2($dillonkearns$elm_graphql$Graphql$SelectionSet$map, $author$project$Api$unwrapId, $author$project$Shopify$Object$Cart$id),
				$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Api$Cart)))));
var $author$project$Shopify$InputObject$buildCartLineInput = F2(
	function (required____, fillOptionals____) {
		var optionals____ = fillOptionals____(
			{n: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, am: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, M: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent});
		return {n: optionals____.n, dK: required____.dK, am: optionals____.am, M: optionals____.M};
	});
var $author$project$Api$cartLineInput = function (_v0) {
	var productVariantId = _v0.a;
	var quantity = _v0.b;
	return A2(
		$author$project$Shopify$InputObject$buildCartLineInput,
		{dK: productVariantId},
		function (optionals) {
			return _Utils_update(
				optionals,
				{
					am: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Present(quantity)
				});
		});
};
var $author$project$Shopify$InputObject$encodeAttributeInput = function (input____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'key',
				$elm$core$Maybe$Just(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$string(input____.v))),
				_Utils_Tuple2(
				'value',
				$elm$core$Maybe$Just(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$string(input____.l)))
			]));
};
var $dillonkearns$elm_graphql$Graphql$Internal$Encode$List = function (a) {
	return {$: 2, a: a};
};
var $dillonkearns$elm_graphql$Graphql$Internal$Encode$list = F2(
	function (toValue, value) {
		return $dillonkearns$elm_graphql$Graphql$Internal$Encode$List(
			A2($elm$core$List$map, toValue, value));
	});
var $dillonkearns$elm_graphql$Graphql$Internal$Encode$fromJson = function (jsonValue) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$Json(jsonValue);
};
var $author$project$Shopify$Scalar$unwrapEncoder = F2(
	function (getter, _v0) {
		var unwrappedCodecs = _v0;
		return A2(
			$elm$core$Basics$composeR,
			getter(unwrappedCodecs).ad,
			$dillonkearns$elm_graphql$Graphql$Internal$Encode$fromJson);
	});
var $author$project$Shopify$InputObject$encodeCartLineInput = function (input____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'attributes',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.n,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$list($author$project$Shopify$InputObject$encodeAttributeInput))),
				_Utils_Tuple2(
				'quantity',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.am, $dillonkearns$elm_graphql$Graphql$Internal$Encode$int)),
				_Utils_Tuple2(
				'merchandiseId',
				$elm$core$Maybe$Just(
					A2(
						$author$project$Shopify$Scalar$unwrapEncoder,
						function ($) {
							return $.ac;
						},
						$author$project$Shopify$ScalarCodecs$codecs)(input____.dK))),
				_Utils_Tuple2(
				'sellingPlanId',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.M,
					A2(
						$author$project$Shopify$Scalar$unwrapEncoder,
						function ($) {
							return $.ac;
						},
						$author$project$Shopify$ScalarCodecs$codecs)))
			]));
};
var $dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$required = F3(
	function (fieldName, raw, encode) {
		return A2(
			$dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$Argument,
			fieldName,
			encode(raw));
	});
var $author$project$Shopify$Mutation$cartLinesAdd = F2(
	function (requiredArgs____, object____) {
		return A4(
			$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField,
			'cartLinesAdd',
			_List_fromArray(
				[
					A3(
					$dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$required,
					'lines',
					requiredArgs____.bt,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$list($author$project$Shopify$InputObject$encodeCartLineInput)),
					A3(
					$dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$required,
					'cartId',
					requiredArgs____.Z,
					A2(
						$author$project$Shopify$Scalar$unwrapEncoder,
						function ($) {
							return $.ac;
						},
						$author$project$Shopify$ScalarCodecs$codecs))
				]),
			object____,
			A2($elm$core$Basics$composeR, $elm$core$Basics$identity, $elm$json$Json$Decode$nullable));
	});
var $author$project$Api$cartLinesAddMutation = function (_v0) {
	var cartId = _v0.Z;
	var productVariantId = _v0.cI;
	var quantity = _v0.am;
	return A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$map,
		$elm$core$Maybe$andThen($elm$core$Basics$identity),
		A2(
			$author$project$Shopify$Mutation$cartLinesAdd,
			{
				Z: cartId,
				bt: _List_fromArray(
					[
						$author$project$Api$cartLineInput(
						_Utils_Tuple2(productVariantId, quantity))
					])
			},
			$author$project$Shopify$Object$CartLinesAddPayload$cart($author$project$Api$cartInfoSelection)));
};
var $dillonkearns$elm_graphql$Graphql$Http$Mutation = function (a) {
	return {$: 1, a: a};
};
var $dillonkearns$elm_graphql$Graphql$Http$Request = $elm$core$Basics$identity;
var $dillonkearns$elm_graphql$Graphql$Document$decoder = function (_v0) {
	var fields = _v0.a;
	var decoder_ = _v0.b;
	return A2($elm$json$Json$Decode$field, 'data', decoder_);
};
var $dillonkearns$elm_graphql$Graphql$Http$mutationRequest = F2(
	function (baseUrl, mutationSelectionSet) {
		return {
			a3: baseUrl,
			br: $dillonkearns$elm_graphql$Graphql$Http$Mutation(mutationSelectionSet),
			ds: $dillonkearns$elm_graphql$Graphql$Document$decoder(mutationSelectionSet),
			z: _List_Nil,
			aj: $elm$core$Maybe$Nothing,
			an: _List_Nil,
			E: $elm$core$Maybe$Nothing,
			at: false
		};
	});
var $elm$http$Http$riskyRequest = function (r) {
	return $elm$http$Http$command(
		$elm$http$Http$Request(
			{dd: true, df: r.df, ds: r.ds, z: r.z, ae: r.ae, E: r.E, c5: r.c5, ar: r.ar}));
};
var $dillonkearns$elm_graphql$Graphql$Http$GraphqlError = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $dillonkearns$elm_graphql$Graphql$Http$HttpError = function (a) {
	return {$: 1, a: a};
};
var $dillonkearns$elm_graphql$Graphql$Http$convertResult = function (httpResult) {
	if (!httpResult.$) {
		var successOrError = httpResult.a;
		if (!successOrError.$) {
			var value = successOrError.a;
			return $elm$core$Result$Ok(value);
		} else {
			var _v2 = successOrError.a;
			var possiblyParsedData = _v2.a;
			var error = _v2.b;
			return $elm$core$Result$Err(
				A2($dillonkearns$elm_graphql$Graphql$Http$GraphqlError, possiblyParsedData, error));
		}
	} else {
		var httpError = httpResult.a;
		return $elm$core$Result$Err(
			$dillonkearns$elm_graphql$Graphql$Http$HttpError(httpError));
	}
};
var $dillonkearns$elm_graphql$Graphql$Http$BadPayload = function (a) {
	return {$: 4, a: a};
};
var $dillonkearns$elm_graphql$Graphql$Http$BadStatus = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $dillonkearns$elm_graphql$Graphql$Http$BadUrl = function (a) {
	return {$: 0, a: a};
};
var $dillonkearns$elm_graphql$Graphql$Http$NetworkError = {$: 2};
var $dillonkearns$elm_graphql$Graphql$Http$Timeout = {$: 1};
var $dillonkearns$elm_graphql$Graphql$Http$expectJson = F2(
	function (toMsg, decoder) {
		return A2(
			$elm$http$Http$expectStringResponse,
			toMsg,
			function (response) {
				switch (response.$) {
					case 0:
						var url = response.a;
						return $elm$core$Result$Err(
							$dillonkearns$elm_graphql$Graphql$Http$BadUrl(url));
					case 1:
						return $elm$core$Result$Err($dillonkearns$elm_graphql$Graphql$Http$Timeout);
					case 2:
						return $elm$core$Result$Err($dillonkearns$elm_graphql$Graphql$Http$NetworkError);
					case 3:
						var metadata = response.a;
						var body = response.b;
						return $elm$core$Result$Err(
							A2($dillonkearns$elm_graphql$Graphql$Http$BadStatus, metadata, body));
					default:
						var metadata = response.a;
						var body = response.b;
						var _v1 = A2($elm$json$Json$Decode$decodeString, decoder, body);
						if (!_v1.$) {
							var value = _v1.a;
							return $elm$core$Result$Ok(value);
						} else {
							var err = _v1.a;
							return $elm$core$Result$Err(
								$dillonkearns$elm_graphql$Graphql$Http$BadPayload(err));
						}
				}
			});
	});
var $dillonkearns$elm_graphql$Graphql$Http$QueryHelper$Get = 0;
var $dillonkearns$elm_graphql$Graphql$Http$QueryHelper$Post = 1;
var $elm$http$Http$emptyBody = _Http_emptyBody;
var $elm$http$Http$jsonBody = function (value) {
	return A2(
		_Http_pair,
		'application/json',
		A2($elm$json$Json$Encode$encode, 0, value));
};
var $dillonkearns$elm_graphql$Graphql$Http$QueryHelper$maxLength = 2000;
var $j_maas$elm_ordered_containers$OrderedDict$OrderedDict = $elm$core$Basics$identity;
var $j_maas$elm_ordered_containers$OrderedDict$empty = {j: $elm$core$Dict$empty, t: _List_Nil};
var $j_maas$elm_ordered_containers$OrderedDict$get = F2(
	function (key, _v0) {
		var orderedDict = _v0;
		return A2($elm$core$Dict$get, key, orderedDict.j);
	});
var $elm$core$Dict$member = F2(
	function (key, dict) {
		var _v0 = A2($elm$core$Dict$get, key, dict);
		if (!_v0.$) {
			return true;
		} else {
			return false;
		}
	});
var $j_maas$elm_ordered_containers$OrderedDict$insert = F3(
	function (key, value, _v0) {
		var orderedDict = _v0;
		var filteredOrder = A2($elm$core$Dict$member, key, orderedDict.j) ? A2(
			$elm$core$List$filter,
			function (k) {
				return !_Utils_eq(k, key);
			},
			orderedDict.t) : orderedDict.t;
		var newOrder = _Utils_ap(
			filteredOrder,
			_List_fromArray(
				[key]));
		return {
			j: A3($elm$core$Dict$insert, key, value, orderedDict.j),
			t: newOrder
		};
	});
var $j_maas$elm_ordered_containers$OrderedDict$remove = F2(
	function (key, _v0) {
		var orderedDict = _v0;
		return {
			j: A2($elm$core$Dict$remove, key, orderedDict.j),
			t: A2($elm$core$Dict$member, key, orderedDict.j) ? A2(
				$elm$core$List$filter,
				function (k) {
					return !_Utils_eq(k, key);
				},
				orderedDict.t) : orderedDict.t
		};
	});
var $j_maas$elm_ordered_containers$OrderedDict$update = F3(
	function (key, alter, original) {
		var orderedDict = original;
		var _v0 = A2($elm$core$Dict$get, key, orderedDict.j);
		if (!_v0.$) {
			var oldItem = _v0.a;
			var _v1 = alter(
				$elm$core$Maybe$Just(oldItem));
			if (!_v1.$) {
				var newItem = _v1.a;
				return {
					j: A3(
						$elm$core$Dict$update,
						key,
						$elm$core$Basics$always(
							$elm$core$Maybe$Just(newItem)),
						orderedDict.j),
					t: orderedDict.t
				};
			} else {
				return A2($j_maas$elm_ordered_containers$OrderedDict$remove, key, original);
			}
		} else {
			var _v2 = alter($elm$core$Maybe$Nothing);
			if (!_v2.$) {
				var newItem = _v2.a;
				return A3($j_maas$elm_ordered_containers$OrderedDict$insert, key, newItem, original);
			} else {
				return original;
			}
		}
	});
var $dillonkearns$elm_graphql$Graphql$Document$Field$canAllowHashing = function (rawFields) {
	var fieldCounts = A3(
		$elm$core$List$foldl,
		F2(
			function (fld, acc) {
				return A3(
					$j_maas$elm_ordered_containers$OrderedDict$update,
					fld,
					function (val) {
						return $elm$core$Maybe$Just(
							function () {
								if (val.$ === 1) {
									return 0;
								} else {
									var count = val.a;
									return count + 1;
								}
							}());
					},
					acc);
			}),
		$j_maas$elm_ordered_containers$OrderedDict$empty,
		A2($elm$core$List$map, $dillonkearns$elm_graphql$Graphql$RawField$name, rawFields));
	return A2(
		$elm$core$List$map,
		function (field) {
			return _Utils_Tuple2(
				field,
				(!A2(
					$elm$core$Maybe$withDefault,
					0,
					A2(
						$j_maas$elm_ordered_containers$OrderedDict$get,
						$dillonkearns$elm_graphql$Graphql$RawField$name(field),
						fieldCounts))) ? $elm$core$Maybe$Nothing : $dillonkearns$elm_graphql$Graphql$Document$Field$alias(field));
		},
		rawFields);
};
var $elm$core$Bitwise$shiftRightBy = _Bitwise_shiftRightBy;
var $elm$core$String$repeatHelp = F3(
	function (n, chunk, result) {
		return (n <= 0) ? result : A3(
			$elm$core$String$repeatHelp,
			n >> 1,
			_Utils_ap(chunk, chunk),
			(!(n & 1)) ? result : _Utils_ap(result, chunk));
	});
var $elm$core$String$repeat = F2(
	function (n, chunk) {
		return A3($elm$core$String$repeatHelp, n, chunk, '');
	});
var $dillonkearns$elm_graphql$Graphql$Document$Indent$generate = function (indentationLevel) {
	return A2($elm$core$String$repeat, indentationLevel, '  ');
};
var $dillonkearns$elm_graphql$Graphql$Document$Field$mergeFields = function (rawFields) {
	return A3(
		$elm$core$List$foldl,
		F2(
			function (field, mergedSoFar) {
				if (!field.$) {
					var newChildren = field.c;
					return A3(
						$j_maas$elm_ordered_containers$OrderedDict$update,
						$dillonkearns$elm_graphql$Graphql$Document$Field$hashedAliasName(field),
						function (maybeChildrenSoFar) {
							if (maybeChildrenSoFar.$ === 1) {
								return $elm$core$Maybe$Just(field);
							} else {
								if (!maybeChildrenSoFar.a.$) {
									var _v2 = maybeChildrenSoFar.a;
									var existingFieldName = _v2.a;
									var existingArgs = _v2.b;
									var existingChildren = _v2.c;
									return $elm$core$Maybe$Just(
										A3(
											$dillonkearns$elm_graphql$Graphql$RawField$Composite,
											existingFieldName,
											existingArgs,
											_Utils_ap(existingChildren, newChildren)));
								} else {
									return $elm$core$Maybe$Just(field);
								}
							}
						},
						mergedSoFar);
				} else {
					return A3(
						$j_maas$elm_ordered_containers$OrderedDict$update,
						$dillonkearns$elm_graphql$Graphql$Document$Field$hashedAliasName(field),
						function (maybeChildrenSoFar) {
							return $elm$core$Maybe$Just(
								A2($elm$core$Maybe$withDefault, field, maybeChildrenSoFar));
						},
						mergedSoFar);
				}
			}),
		$j_maas$elm_ordered_containers$OrderedDict$empty,
		rawFields);
};
var $j_maas$elm_ordered_containers$OrderedDict$values = function (_v0) {
	var orderedDict = _v0;
	return A2(
		$elm$core$List$filterMap,
		function (key) {
			return A2($elm$core$Dict$get, key, orderedDict.j);
		},
		orderedDict.t);
};
var $dillonkearns$elm_graphql$Graphql$Document$Field$mergedFields = function (children) {
	return $j_maas$elm_ordered_containers$OrderedDict$values(
		$dillonkearns$elm_graphql$Graphql$Document$Field$mergeFields(children));
};
var $dillonkearns$elm_graphql$Graphql$Document$Field$nonemptyChildren = function (children) {
	return $elm$core$List$isEmpty(children) ? A2($elm$core$List$cons, $dillonkearns$elm_graphql$Graphql$RawField$typename, children) : children;
};
var $dillonkearns$elm_graphql$Graphql$Document$Field$serialize = F3(
	function (aliasName, mIndentationLevel, field) {
		var prefix = function () {
			if (!aliasName.$) {
				var aliasName_ = aliasName.a;
				return _Utils_ap(
					aliasName_,
					function () {
						if (!mIndentationLevel.$) {
							return ': ';
						} else {
							return ':';
						}
					}());
			} else {
				return '';
			}
		}();
		return A2(
			$elm$core$Maybe$map,
			function (string) {
				return _Utils_ap(
					$dillonkearns$elm_graphql$Graphql$Document$Indent$generate(
						A2($elm$core$Maybe$withDefault, 0, mIndentationLevel)),
					_Utils_ap(prefix, string));
			},
			function () {
				if (!field.$) {
					var fieldName = field.a;
					var args = field.b;
					var children = field.c;
					if (mIndentationLevel.$ === 1) {
						return $elm$core$Maybe$Just(
							(fieldName + ($dillonkearns$elm_graphql$Graphql$Document$Argument$serialize(args) + ('{' + A2($dillonkearns$elm_graphql$Graphql$Document$Field$serializeChildren, $elm$core$Maybe$Nothing, children)))) + '}');
					} else {
						var indentationLevel = mIndentationLevel.a;
						return $elm$core$Maybe$Just(
							(fieldName + ($dillonkearns$elm_graphql$Graphql$Document$Argument$serialize(args) + (' {\n' + A2(
								$dillonkearns$elm_graphql$Graphql$Document$Field$serializeChildren,
								$elm$core$Maybe$Just(indentationLevel),
								children)))) + ('\n' + ($dillonkearns$elm_graphql$Graphql$Document$Indent$generate(indentationLevel) + '}')));
					}
				} else {
					var fieldName = field.a.cc;
					var args = field.b;
					return $elm$core$Maybe$Just(
						_Utils_ap(
							fieldName,
							$dillonkearns$elm_graphql$Graphql$Document$Argument$serialize(args)));
				}
			}());
	});
var $dillonkearns$elm_graphql$Graphql$Document$Field$serializeChildren = F2(
	function (indentationLevel, children) {
		return A2(
			$elm$core$String$join,
			function () {
				if (!indentationLevel.$) {
					return '\n';
				} else {
					return ' ';
				}
			}(),
			A2(
				$elm$core$List$filterMap,
				$elm$core$Basics$identity,
				A2(
					$elm$core$List$map,
					function (_v0) {
						var field = _v0.a;
						var maybeAlias = _v0.b;
						return A3(
							$dillonkearns$elm_graphql$Graphql$Document$Field$serialize,
							maybeAlias,
							A2(
								$elm$core$Maybe$map,
								$elm$core$Basics$add(1),
								indentationLevel),
							field);
					},
					$dillonkearns$elm_graphql$Graphql$Document$Field$canAllowHashing(
						$dillonkearns$elm_graphql$Graphql$Document$Field$nonemptyChildren(
							$dillonkearns$elm_graphql$Graphql$Document$Field$mergedFields(children))))));
	});
var $dillonkearns$elm_graphql$Graphql$Document$serialize = F2(
	function (operationType, queries) {
		return A2(
			$lukewestby$elm_string_interpolate$String$Interpolate$interpolate,
			'{0} {\n{1}\n}',
			_List_fromArray(
				[
					operationType,
					A2(
					$dillonkearns$elm_graphql$Graphql$Document$Field$serializeChildren,
					$elm$core$Maybe$Just(0),
					queries)
				]));
	});
var $dillonkearns$elm_graphql$Graphql$Document$serializeQuery = function (_v0) {
	var fields = _v0.a;
	var decoder_ = _v0.b;
	return A2($dillonkearns$elm_graphql$Graphql$Document$serialize, 'query', fields);
};
var $dillonkearns$elm_graphql$Graphql$Document$serializeQueryForUrl = function (_v0) {
	var fields = _v0.a;
	var decoder_ = _v0.b;
	return '{' + (A2($dillonkearns$elm_graphql$Graphql$Document$Field$serializeChildren, $elm$core$Maybe$Nothing, fields) + '}');
};
var $dillonkearns$elm_graphql$Graphql$Document$serializeQueryForUrlWithOperationName = F2(
	function (operationName, _v0) {
		var fields = _v0.a;
		var decoder_ = _v0.b;
		return 'query ' + (operationName + (' {' + (A2($dillonkearns$elm_graphql$Graphql$Document$Field$serializeChildren, $elm$core$Maybe$Nothing, fields) + '}')));
	});
var $dillonkearns$elm_graphql$Graphql$Document$serializeWithOperationName = F3(
	function (operationType, operationName, queries) {
		return A2(
			$lukewestby$elm_string_interpolate$String$Interpolate$interpolate,
			'{0} {1} {\n{2}\n}',
			_List_fromArray(
				[
					operationType,
					operationName,
					A2(
					$dillonkearns$elm_graphql$Graphql$Document$Field$serializeChildren,
					$elm$core$Maybe$Just(0),
					queries)
				]));
	});
var $dillonkearns$elm_graphql$Graphql$Document$serializeQueryWithOperationName = F2(
	function (operationName, _v0) {
		var fields = _v0.a;
		var decoder_ = _v0.b;
		return A3($dillonkearns$elm_graphql$Graphql$Document$serializeWithOperationName, 'query', operationName, fields);
	});
var $elm$url$Url$percentEncode = _Url_percentEncode;
var $dillonkearns$elm_graphql$Graphql$Http$QueryParams$replace = F2(
	function (old, _new) {
		return A2(
			$elm$core$Basics$composeR,
			$elm$core$String$split(old),
			$elm$core$String$join(_new));
	});
var $dillonkearns$elm_graphql$Graphql$Http$QueryParams$queryEscape = A2(
	$elm$core$Basics$composeR,
	$elm$url$Url$percentEncode,
	A2($dillonkearns$elm_graphql$Graphql$Http$QueryParams$replace, '%20', '+'));
var $dillonkearns$elm_graphql$Graphql$Http$QueryParams$queryPair = function (_v0) {
	var key = _v0.a;
	var value = _v0.b;
	return $dillonkearns$elm_graphql$Graphql$Http$QueryParams$queryEscape(key) + ('=' + $dillonkearns$elm_graphql$Graphql$Http$QueryParams$queryEscape(value));
};
var $dillonkearns$elm_graphql$Graphql$Http$QueryParams$joinUrlEncoded = function (args) {
	return A2(
		$elm$core$String$join,
		'&',
		A2($elm$core$List$map, $dillonkearns$elm_graphql$Graphql$Http$QueryParams$queryPair, args));
};
var $dillonkearns$elm_graphql$Graphql$Http$QueryParams$urlWithQueryParams = F2(
	function (queryParams, url) {
		return $elm$core$List$isEmpty(queryParams) ? url : (url + ('?' + $dillonkearns$elm_graphql$Graphql$Http$QueryParams$joinUrlEncoded(queryParams)));
	});
var $dillonkearns$elm_graphql$Graphql$Http$QueryHelper$build = F5(
	function (forceMethod, url, queryParams, maybeOperationName, queryDocument) {
		var _v0 = function () {
			if (!maybeOperationName.$) {
				var operationName = maybeOperationName.a;
				return _Utils_Tuple2(
					A2($dillonkearns$elm_graphql$Graphql$Document$serializeQueryForUrlWithOperationName, operationName, queryDocument),
					_List_fromArray(
						[
							_Utils_Tuple2('operationName', operationName)
						]));
			} else {
				return _Utils_Tuple2(
					$dillonkearns$elm_graphql$Graphql$Document$serializeQueryForUrl(queryDocument),
					_List_Nil);
			}
		}();
		var serializedQueryForGetRequest = _v0.a;
		var operationNameParamForGetRequest = _v0.b;
		var urlForGetRequest = A2(
			$dillonkearns$elm_graphql$Graphql$Http$QueryParams$urlWithQueryParams,
			_Utils_ap(
				queryParams,
				A2(
					$elm$core$List$cons,
					_Utils_Tuple2('query', serializedQueryForGetRequest),
					operationNameParamForGetRequest)),
			url);
		if (_Utils_eq(
			forceMethod,
			$elm$core$Maybe$Just(1)) || ((_Utils_cmp(
			$elm$core$String$length(urlForGetRequest),
			$dillonkearns$elm_graphql$Graphql$Http$QueryHelper$maxLength) > -1) && (!_Utils_eq(
			forceMethod,
			$elm$core$Maybe$Just(0))))) {
			var _v2 = function () {
				if (!maybeOperationName.$) {
					var operationName = maybeOperationName.a;
					return _Utils_Tuple2(
						A2($dillonkearns$elm_graphql$Graphql$Document$serializeQueryWithOperationName, operationName, queryDocument),
						_List_fromArray(
							[
								_Utils_Tuple2(
								'operationName',
								$elm$json$Json$Encode$string(operationName))
							]));
				} else {
					return _Utils_Tuple2(
						$dillonkearns$elm_graphql$Graphql$Document$serializeQuery(queryDocument),
						_List_Nil);
				}
			}();
			var serializedQuery = _v2.a;
			var operationNameParamForPostRequest = _v2.b;
			return {
				df: $elm$http$Http$jsonBody(
					$elm$json$Json$Encode$object(
						A2(
							$elm$core$List$cons,
							_Utils_Tuple2(
								'query',
								$elm$json$Json$Encode$string(serializedQuery)),
							operationNameParamForPostRequest))),
				ae: 1,
				ar: A2($dillonkearns$elm_graphql$Graphql$Http$QueryParams$urlWithQueryParams, queryParams, url)
			};
		} else {
			return {df: $elm$http$Http$emptyBody, ae: 0, ar: urlForGetRequest};
		}
	});
var $dillonkearns$elm_graphql$Graphql$Http$GraphqlError$ParsedData = function (a) {
	return {$: 0, a: a};
};
var $dillonkearns$elm_graphql$Graphql$Http$GraphqlError$UnparsedData = function (a) {
	return {$: 1, a: a};
};
var $dillonkearns$elm_graphql$Graphql$Http$GraphqlError$GraphqlError = F3(
	function (message, locations, details) {
		return {br: details, dG: locations, dL: message};
	});
var $elm$json$Json$Decode$keyValuePairs = _Json_decodeKeyValuePairs;
var $elm$json$Json$Decode$dict = function (decoder) {
	return A2(
		$elm$json$Json$Decode$map,
		$elm$core$Dict$fromList,
		$elm$json$Json$Decode$keyValuePairs(decoder));
};
var $dillonkearns$elm_graphql$Graphql$Http$GraphqlError$Location = F2(
	function (line, column) {
		return {dn: column, dE: line};
	});
var $dillonkearns$elm_graphql$Graphql$Http$GraphqlError$locationDecoder = A3(
	$elm$json$Json$Decode$map2,
	$dillonkearns$elm_graphql$Graphql$Http$GraphqlError$Location,
	A2($elm$json$Json$Decode$field, 'line', $elm$json$Json$Decode$int),
	A2($elm$json$Json$Decode$field, 'column', $elm$json$Json$Decode$int));
var $elm$json$Json$Decode$map3 = _Json_map3;
var $elm$json$Json$Decode$maybe = function (decoder) {
	return $elm$json$Json$Decode$oneOf(
		_List_fromArray(
			[
				A2($elm$json$Json$Decode$map, $elm$core$Maybe$Just, decoder),
				$elm$json$Json$Decode$succeed($elm$core$Maybe$Nothing)
			]));
};
var $elm$json$Json$Decode$value = _Json_decodeValue;
var $dillonkearns$elm_graphql$Graphql$Http$GraphqlError$decoder = A2(
	$elm$json$Json$Decode$field,
	'errors',
	$elm$json$Json$Decode$list(
		A4(
			$elm$json$Json$Decode$map3,
			$dillonkearns$elm_graphql$Graphql$Http$GraphqlError$GraphqlError,
			A2($elm$json$Json$Decode$field, 'message', $elm$json$Json$Decode$string),
			$elm$json$Json$Decode$maybe(
				A2(
					$elm$json$Json$Decode$field,
					'locations',
					$elm$json$Json$Decode$list($dillonkearns$elm_graphql$Graphql$Http$GraphqlError$locationDecoder))),
			A2(
				$elm$json$Json$Decode$map,
				$elm$core$Dict$remove('locations'),
				A2(
					$elm$json$Json$Decode$map,
					$elm$core$Dict$remove('message'),
					$elm$json$Json$Decode$dict($elm$json$Json$Decode$value))))));
var $elm$core$Tuple$pair = F2(
	function (a, b) {
		return _Utils_Tuple2(a, b);
	});
var $dillonkearns$elm_graphql$Graphql$Http$decodeErrorWithData = function (data) {
	return A2(
		$elm$json$Json$Decode$map,
		$elm$core$Result$Err,
		A2(
			$elm$json$Json$Decode$map,
			$elm$core$Tuple$pair(data),
			$dillonkearns$elm_graphql$Graphql$Http$GraphqlError$decoder));
};
var $dillonkearns$elm_graphql$Graphql$Http$nullJsonValue = function (a) {
	nullJsonValue:
	while (true) {
		var _v0 = A2($elm$json$Json$Decode$decodeString, $elm$json$Json$Decode$value, 'null');
		if (!_v0.$) {
			var value = _v0.a;
			return value;
		} else {
			var $temp$a = 0;
			a = $temp$a;
			continue nullJsonValue;
		}
	}
};
var $dillonkearns$elm_graphql$Graphql$Http$errorDecoder = function (decoder) {
	return $elm$json$Json$Decode$oneOf(
		_List_fromArray(
			[
				A2(
				$elm$json$Json$Decode$andThen,
				$dillonkearns$elm_graphql$Graphql$Http$decodeErrorWithData,
				A2($elm$json$Json$Decode$map, $dillonkearns$elm_graphql$Graphql$Http$GraphqlError$ParsedData, decoder)),
				A2(
				$elm$json$Json$Decode$andThen,
				$dillonkearns$elm_graphql$Graphql$Http$decodeErrorWithData,
				A2(
					$elm$json$Json$Decode$map,
					$dillonkearns$elm_graphql$Graphql$Http$GraphqlError$UnparsedData,
					A2($elm$json$Json$Decode$field, 'data', $elm$json$Json$Decode$value))),
				A2(
				$elm$json$Json$Decode$andThen,
				$dillonkearns$elm_graphql$Graphql$Http$decodeErrorWithData,
				$elm$json$Json$Decode$succeed(
					$dillonkearns$elm_graphql$Graphql$Http$GraphqlError$UnparsedData(
						$dillonkearns$elm_graphql$Graphql$Http$nullJsonValue(0))))
			]));
};
var $dillonkearns$elm_graphql$Graphql$Http$decoderOrError = function (decoder) {
	return $elm$json$Json$Decode$oneOf(
		_List_fromArray(
			[
				$dillonkearns$elm_graphql$Graphql$Http$errorDecoder(decoder),
				A2($elm$json$Json$Decode$map, $elm$core$Result$Ok, decoder)
			]));
};
var $dillonkearns$elm_graphql$Graphql$Document$serializeMutation = function (_v0) {
	var fields = _v0.a;
	var decoder_ = _v0.b;
	return A2($dillonkearns$elm_graphql$Graphql$Document$serialize, 'mutation', fields);
};
var $dillonkearns$elm_graphql$Graphql$Document$serializeMutationWithOperationName = F2(
	function (operationName, _v0) {
		var fields = _v0.a;
		var decoder_ = _v0.b;
		return A3($dillonkearns$elm_graphql$Graphql$Document$serializeWithOperationName, 'mutation', operationName, fields);
	});
var $dillonkearns$elm_graphql$Graphql$Http$toReadyRequest = function (_v0) {
	var request = _v0;
	var _v1 = request.br;
	if (!_v1.$) {
		var forcedRequestMethod = _v1.a;
		var querySelectionSet = _v1.b;
		var queryRequestDetails = A5(
			$dillonkearns$elm_graphql$Graphql$Http$QueryHelper$build,
			function () {
				if (!forcedRequestMethod.$) {
					if (!forcedRequestMethod.a) {
						var _v4 = forcedRequestMethod.a;
						return $elm$core$Maybe$Just(0);
					} else {
						var _v5 = forcedRequestMethod.a;
						return $elm$core$Maybe$Nothing;
					}
				} else {
					return $elm$core$Maybe$Just(1);
				}
			}(),
			request.a3,
			request.an,
			request.aj,
			querySelectionSet);
		return {
			df: queryRequestDetails.df,
			bq: $dillonkearns$elm_graphql$Graphql$Http$decoderOrError(request.ds),
			z: request.z,
			ae: function () {
				var _v2 = queryRequestDetails.ae;
				if (!_v2) {
					return 'GET';
				} else {
					return 'Post';
				}
			}(),
			E: request.E,
			ar: queryRequestDetails.ar
		};
	} else {
		var mutationSelectionSet = _v1.a;
		var serializedMutation = function () {
			var _v7 = request.aj;
			if (!_v7.$) {
				var operationName = _v7.a;
				return A2($dillonkearns$elm_graphql$Graphql$Document$serializeMutationWithOperationName, operationName, mutationSelectionSet);
			} else {
				return $dillonkearns$elm_graphql$Graphql$Document$serializeMutation(mutationSelectionSet);
			}
		}();
		return {
			df: $elm$http$Http$jsonBody(
				$elm$json$Json$Encode$object(
					A2(
						$elm$core$List$append,
						_List_fromArray(
							[
								_Utils_Tuple2(
								'query',
								$elm$json$Json$Encode$string(serializedMutation))
							]),
						function () {
							var _v6 = request.aj;
							if (!_v6.$) {
								var operationName = _v6.a;
								return _List_fromArray(
									[
										_Utils_Tuple2(
										'operationName',
										$elm$json$Json$Encode$string(operationName))
									]);
							} else {
								return _List_Nil;
							}
						}()))),
			bq: $dillonkearns$elm_graphql$Graphql$Http$decoderOrError(request.ds),
			z: request.z,
			ae: 'POST',
			E: request.E,
			ar: A2($dillonkearns$elm_graphql$Graphql$Http$QueryParams$urlWithQueryParams, request.an, request.a3)
		};
	}
};
var $dillonkearns$elm_graphql$Graphql$Http$toHttpRequestRecord = F2(
	function (resultToMessage, fullRequest) {
		var request = fullRequest;
		return function (readyRequest) {
			return {
				df: readyRequest.df,
				ds: A2(
					$dillonkearns$elm_graphql$Graphql$Http$expectJson,
					A2($elm$core$Basics$composeR, $dillonkearns$elm_graphql$Graphql$Http$convertResult, resultToMessage),
					readyRequest.bq),
				z: readyRequest.z,
				ae: readyRequest.ae,
				E: readyRequest.E,
				c5: $elm$core$Maybe$Nothing,
				ar: readyRequest.ar
			};
		}(
			$dillonkearns$elm_graphql$Graphql$Http$toReadyRequest(fullRequest));
	});
var $dillonkearns$elm_graphql$Graphql$Http$send = F2(
	function (resultToMessage, fullRequest) {
		var request = fullRequest;
		return (request.at ? $elm$http$Http$riskyRequest : $elm$http$Http$request)(
			A2($dillonkearns$elm_graphql$Graphql$Http$toHttpRequestRecord, resultToMessage, fullRequest));
	});
var $elm$http$Http$Header = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$http$Http$header = $elm$http$Http$Header;
var $dillonkearns$elm_graphql$Graphql$Http$withHeader = F3(
	function (key, value, _v0) {
		var request = _v0;
		return _Utils_update(
			request,
			{
				z: A2(
					$elm$core$List$cons,
					A2($elm$http$Http$header, key, value),
					request.z)
			});
	});
var $author$project$Api$cartLinesAdd = F2(
	function (_v0, input) {
		var url = _v0.ar;
		var token = _v0.aY;
		return A2(
			$dillonkearns$elm_graphql$Graphql$Http$send,
			$elm$core$Basics$identity,
			A3(
				$dillonkearns$elm_graphql$Graphql$Http$withHeader,
				'X-Shopify-Storefront-Access-Token',
				token,
				A2(
					$dillonkearns$elm_graphql$Graphql$Http$mutationRequest,
					url,
					$author$project$Api$cartLinesAddMutation(input))));
	});
var $author$project$Cart$cartLinesAdd = F2(
	function (config, input) {
		return A3(
			$author$project$Cart$cartApiResultToMsg,
			$author$project$Cart$CartUpdated,
			$author$project$Cart$CartUpdateFailed,
			A2($author$project$Api$cartLinesAdd, config, input));
	});
var $author$project$Shopify$Object$CartLinesRemovePayload$cart = function (object____) {
	return A4(
		$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField,
		'cart',
		_List_Nil,
		object____,
		A2($elm$core$Basics$composeR, $elm$core$Basics$identity, $elm$json$Json$Decode$nullable));
};
var $author$project$Shopify$Mutation$cartLinesRemove = F2(
	function (requiredArgs____, object____) {
		return A4(
			$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField,
			'cartLinesRemove',
			_List_fromArray(
				[
					A3(
					$dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$required,
					'cartId',
					requiredArgs____.Z,
					A2(
						$author$project$Shopify$Scalar$unwrapEncoder,
						function ($) {
							return $.ac;
						},
						$author$project$Shopify$ScalarCodecs$codecs)),
					A3(
					$dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$required,
					'lineIds',
					requiredArgs____.dF,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$list(
						A2(
							$author$project$Shopify$Scalar$unwrapEncoder,
							function ($) {
								return $.ac;
							},
							$author$project$Shopify$ScalarCodecs$codecs)))
				]),
			object____,
			A2($elm$core$Basics$composeR, $elm$core$Basics$identity, $elm$json$Json$Decode$nullable));
	});
var $author$project$Api$cartLinesRemoveMutation = function (_v0) {
	var cartId = _v0.Z;
	var lineId = _v0.bs;
	return A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$map,
		$elm$core$Maybe$andThen($elm$core$Basics$identity),
		A2(
			$author$project$Shopify$Mutation$cartLinesRemove,
			{
				Z: cartId,
				dF: _List_fromArray(
					[lineId])
			},
			$author$project$Shopify$Object$CartLinesRemovePayload$cart($author$project$Api$cartInfoSelection)));
};
var $author$project$Api$cartLinesRemove = F2(
	function (_v0, input) {
		var url = _v0.ar;
		var token = _v0.aY;
		return A2(
			$dillonkearns$elm_graphql$Graphql$Http$send,
			$elm$core$Basics$identity,
			A3(
				$dillonkearns$elm_graphql$Graphql$Http$withHeader,
				'X-Shopify-Storefront-Access-Token',
				token,
				A2(
					$dillonkearns$elm_graphql$Graphql$Http$mutationRequest,
					url,
					$author$project$Api$cartLinesRemoveMutation(input))));
	});
var $author$project$Cart$cartLinesRemove = F2(
	function (config, input) {
		return A3(
			$author$project$Cart$cartApiResultToMsg,
			$author$project$Cart$CartUpdated,
			$author$project$Cart$CartUpdateFailed,
			A2($author$project$Api$cartLinesRemove, config, input));
	});
var $author$project$Shopify$InputObject$buildCartLineUpdateInput = F2(
	function (required____, fillOptionals____) {
		var optionals____ = fillOptionals____(
			{n: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, dK: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, am: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, M: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent});
		return {n: optionals____.n, aI: required____.aI, dK: optionals____.dK, am: optionals____.am, M: optionals____.M};
	});
var $author$project$Shopify$Object$CartLinesUpdatePayload$cart = function (object____) {
	return A4(
		$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField,
		'cart',
		_List_Nil,
		object____,
		A2($elm$core$Basics$composeR, $elm$core$Basics$identity, $elm$json$Json$Decode$nullable));
};
var $author$project$Shopify$InputObject$encodeCartLineUpdateInput = function (input____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'id',
				$elm$core$Maybe$Just(
					A2(
						$author$project$Shopify$Scalar$unwrapEncoder,
						function ($) {
							return $.ac;
						},
						$author$project$Shopify$ScalarCodecs$codecs)(input____.aI))),
				_Utils_Tuple2(
				'quantity',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.am, $dillonkearns$elm_graphql$Graphql$Internal$Encode$int)),
				_Utils_Tuple2(
				'merchandiseId',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.dK,
					A2(
						$author$project$Shopify$Scalar$unwrapEncoder,
						function ($) {
							return $.ac;
						},
						$author$project$Shopify$ScalarCodecs$codecs))),
				_Utils_Tuple2(
				'attributes',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.n,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$list($author$project$Shopify$InputObject$encodeAttributeInput))),
				_Utils_Tuple2(
				'sellingPlanId',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.M,
					A2(
						$author$project$Shopify$Scalar$unwrapEncoder,
						function ($) {
							return $.ac;
						},
						$author$project$Shopify$ScalarCodecs$codecs)))
			]));
};
var $author$project$Shopify$Mutation$cartLinesUpdate = F2(
	function (requiredArgs____, object____) {
		return A4(
			$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField,
			'cartLinesUpdate',
			_List_fromArray(
				[
					A3(
					$dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$required,
					'cartId',
					requiredArgs____.Z,
					A2(
						$author$project$Shopify$Scalar$unwrapEncoder,
						function ($) {
							return $.ac;
						},
						$author$project$Shopify$ScalarCodecs$codecs)),
					A3(
					$dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$required,
					'lines',
					requiredArgs____.bt,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$list($author$project$Shopify$InputObject$encodeCartLineUpdateInput))
				]),
			object____,
			A2($elm$core$Basics$composeR, $elm$core$Basics$identity, $elm$json$Json$Decode$nullable));
	});
var $author$project$Api$cartLinesUpdateMutation = function (_v0) {
	var cartId = _v0.Z;
	var lineId = _v0.bs;
	var quantity = _v0.am;
	return A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$map,
		$elm$core$Maybe$andThen($elm$core$Basics$identity),
		A2(
			$author$project$Shopify$Mutation$cartLinesUpdate,
			{
				Z: cartId,
				bt: _List_fromArray(
					[
						A2(
						$author$project$Shopify$InputObject$buildCartLineUpdateInput,
						{aI: lineId},
						function (optionals) {
							return _Utils_update(
								optionals,
								{
									am: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Present(quantity)
								});
						})
					])
			},
			$author$project$Shopify$Object$CartLinesUpdatePayload$cart($author$project$Api$cartInfoSelection)));
};
var $author$project$Api$cartLinesUpdate = F2(
	function (_v0, input) {
		var url = _v0.ar;
		var token = _v0.aY;
		return A2(
			$dillonkearns$elm_graphql$Graphql$Http$send,
			$elm$core$Basics$identity,
			A3(
				$dillonkearns$elm_graphql$Graphql$Http$withHeader,
				'X-Shopify-Storefront-Access-Token',
				token,
				A2(
					$dillonkearns$elm_graphql$Graphql$Http$mutationRequest,
					url,
					$author$project$Api$cartLinesUpdateMutation(input))));
	});
var $author$project$Cart$cartLinesUpdate = F2(
	function (config, input) {
		return A3(
			$author$project$Cart$cartApiResultToMsg,
			$author$project$Cart$CartUpdated,
			$author$project$Cart$CartUpdateFailed,
			A2($author$project$Api$cartLinesUpdate, config, input));
	});
var $author$project$Cart$commands = _Platform_outgoingPort('commands', $elm$core$Basics$identity);
var $author$project$Cart$CartCreated = function (a) {
	return {$: 3, a: a};
};
var $author$project$Cart$CartCreationFailed = {$: 4};
var $author$project$Shopify$InputObject$buildCartInput = function (fillOptionals____) {
	var optionals____ = fillOptionals____(
		{n: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, H: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, aF: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, bt: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, q: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent});
	return {n: optionals____.n, H: optionals____.H, aF: optionals____.aF, bt: optionals____.bt, q: optionals____.q};
};
var $author$project$Shopify$Object$CartCreatePayload$cart = function (object____) {
	return A4(
		$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField,
		'cart',
		_List_Nil,
		object____,
		A2($elm$core$Basics$composeR, $elm$core$Basics$identity, $elm$json$Json$Decode$nullable));
};
var $author$project$Shopify$Enum$CountryCode$toString = function (enum____) {
	switch (enum____) {
		case 0:
			return 'AF';
		case 1:
			return 'AX';
		case 2:
			return 'AL';
		case 3:
			return 'DZ';
		case 4:
			return 'AD';
		case 5:
			return 'AO';
		case 6:
			return 'AI';
		case 7:
			return 'AG';
		case 8:
			return 'AR';
		case 9:
			return 'AM';
		case 10:
			return 'AW';
		case 11:
			return 'AC';
		case 12:
			return 'AU';
		case 13:
			return 'AT';
		case 14:
			return 'AZ';
		case 15:
			return 'BS';
		case 16:
			return 'BH';
		case 17:
			return 'BD';
		case 18:
			return 'BB';
		case 19:
			return 'BY';
		case 20:
			return 'BE';
		case 21:
			return 'BZ';
		case 22:
			return 'BJ';
		case 23:
			return 'BM';
		case 24:
			return 'BT';
		case 25:
			return 'BO';
		case 26:
			return 'BA';
		case 27:
			return 'BW';
		case 28:
			return 'BV';
		case 29:
			return 'BR';
		case 30:
			return 'IO';
		case 31:
			return 'BN';
		case 32:
			return 'BG';
		case 33:
			return 'BF';
		case 34:
			return 'BI';
		case 35:
			return 'KH';
		case 36:
			return 'CA';
		case 37:
			return 'CV';
		case 38:
			return 'BQ';
		case 39:
			return 'KY';
		case 40:
			return 'CF';
		case 41:
			return 'TD';
		case 42:
			return 'CL';
		case 43:
			return 'CN';
		case 44:
			return 'CX';
		case 45:
			return 'CC';
		case 46:
			return 'CO';
		case 47:
			return 'KM';
		case 48:
			return 'CG';
		case 49:
			return 'CD';
		case 50:
			return 'CK';
		case 51:
			return 'CR';
		case 52:
			return 'HR';
		case 53:
			return 'CU';
		case 54:
			return 'CW';
		case 55:
			return 'CY';
		case 56:
			return 'CZ';
		case 57:
			return 'CI';
		case 58:
			return 'DK';
		case 59:
			return 'DJ';
		case 60:
			return 'DM';
		case 61:
			return 'DO';
		case 62:
			return 'EC';
		case 63:
			return 'EG';
		case 64:
			return 'SV';
		case 65:
			return 'GQ';
		case 66:
			return 'ER';
		case 67:
			return 'EE';
		case 68:
			return 'SZ';
		case 69:
			return 'ET';
		case 70:
			return 'FK';
		case 71:
			return 'FO';
		case 72:
			return 'FJ';
		case 73:
			return 'FI';
		case 74:
			return 'FR';
		case 75:
			return 'GF';
		case 76:
			return 'PF';
		case 77:
			return 'TF';
		case 78:
			return 'GA';
		case 79:
			return 'GM';
		case 80:
			return 'GE';
		case 81:
			return 'DE';
		case 82:
			return 'GH';
		case 83:
			return 'GI';
		case 84:
			return 'GR';
		case 85:
			return 'GL';
		case 86:
			return 'GD';
		case 87:
			return 'GP';
		case 88:
			return 'GT';
		case 89:
			return 'GG';
		case 90:
			return 'GN';
		case 91:
			return 'GW';
		case 92:
			return 'GY';
		case 93:
			return 'HT';
		case 94:
			return 'HM';
		case 95:
			return 'VA';
		case 96:
			return 'HN';
		case 97:
			return 'HK';
		case 98:
			return 'HU';
		case 99:
			return 'IS';
		case 100:
			return 'IN';
		case 101:
			return 'ID';
		case 102:
			return 'IR';
		case 103:
			return 'IQ';
		case 104:
			return 'IE';
		case 105:
			return 'IM';
		case 106:
			return 'IL';
		case 107:
			return 'IT';
		case 108:
			return 'JM';
		case 109:
			return 'JP';
		case 110:
			return 'JE';
		case 111:
			return 'JO';
		case 112:
			return 'KZ';
		case 113:
			return 'KE';
		case 114:
			return 'KI';
		case 115:
			return 'KP';
		case 116:
			return 'XK';
		case 117:
			return 'KW';
		case 118:
			return 'KG';
		case 119:
			return 'LA';
		case 120:
			return 'LV';
		case 121:
			return 'LB';
		case 122:
			return 'LS';
		case 123:
			return 'LR';
		case 124:
			return 'LY';
		case 125:
			return 'LI';
		case 126:
			return 'LT';
		case 127:
			return 'LU';
		case 128:
			return 'MO';
		case 129:
			return 'MG';
		case 130:
			return 'MW';
		case 131:
			return 'MY';
		case 132:
			return 'MV';
		case 133:
			return 'ML';
		case 134:
			return 'MT';
		case 135:
			return 'MQ';
		case 136:
			return 'MR';
		case 137:
			return 'MU';
		case 138:
			return 'YT';
		case 139:
			return 'MX';
		case 140:
			return 'MD';
		case 141:
			return 'MC';
		case 142:
			return 'MN';
		case 143:
			return 'ME';
		case 144:
			return 'MS';
		case 145:
			return 'MA';
		case 146:
			return 'MZ';
		case 147:
			return 'MM';
		case 148:
			return 'NA';
		case 149:
			return 'NR';
		case 150:
			return 'NP';
		case 151:
			return 'NL';
		case 152:
			return 'AN';
		case 153:
			return 'NC';
		case 154:
			return 'NZ';
		case 155:
			return 'NI';
		case 156:
			return 'NE';
		case 157:
			return 'NG';
		case 158:
			return 'NU';
		case 159:
			return 'NF';
		case 160:
			return 'MK';
		case 161:
			return 'NO';
		case 162:
			return 'OM';
		case 163:
			return 'PK';
		case 164:
			return 'PS';
		case 165:
			return 'PA';
		case 166:
			return 'PG';
		case 167:
			return 'PY';
		case 168:
			return 'PE';
		case 169:
			return 'PH';
		case 170:
			return 'PN';
		case 171:
			return 'PL';
		case 172:
			return 'PT';
		case 173:
			return 'QA';
		case 174:
			return 'CM';
		case 175:
			return 'RE';
		case 176:
			return 'RO';
		case 177:
			return 'RU';
		case 178:
			return 'RW';
		case 179:
			return 'BL';
		case 180:
			return 'SH';
		case 181:
			return 'KN';
		case 182:
			return 'LC';
		case 183:
			return 'MF';
		case 184:
			return 'PM';
		case 185:
			return 'WS';
		case 186:
			return 'SM';
		case 187:
			return 'ST';
		case 188:
			return 'SA';
		case 189:
			return 'SN';
		case 190:
			return 'RS';
		case 191:
			return 'SC';
		case 192:
			return 'SL';
		case 193:
			return 'SG';
		case 194:
			return 'SX';
		case 195:
			return 'SK';
		case 196:
			return 'SI';
		case 197:
			return 'SB';
		case 198:
			return 'SO';
		case 199:
			return 'ZA';
		case 200:
			return 'GS';
		case 201:
			return 'KR';
		case 202:
			return 'SS';
		case 203:
			return 'ES';
		case 204:
			return 'LK';
		case 205:
			return 'VC';
		case 206:
			return 'SD';
		case 207:
			return 'SR';
		case 208:
			return 'SJ';
		case 209:
			return 'SE';
		case 210:
			return 'CH';
		case 211:
			return 'SY';
		case 212:
			return 'TW';
		case 213:
			return 'TJ';
		case 214:
			return 'TZ';
		case 215:
			return 'TH';
		case 216:
			return 'TL';
		case 217:
			return 'TG';
		case 218:
			return 'TK';
		case 219:
			return 'TO';
		case 220:
			return 'TT';
		case 221:
			return 'TA';
		case 222:
			return 'TN';
		case 223:
			return 'TR';
		case 224:
			return 'TM';
		case 225:
			return 'TC';
		case 226:
			return 'TV';
		case 227:
			return 'UG';
		case 228:
			return 'UA';
		case 229:
			return 'AE';
		case 230:
			return 'GB';
		case 231:
			return 'US';
		case 232:
			return 'UM';
		case 233:
			return 'UY';
		case 234:
			return 'UZ';
		case 235:
			return 'VU';
		case 236:
			return 'VE';
		case 237:
			return 'VN';
		case 238:
			return 'VG';
		case 239:
			return 'WF';
		case 240:
			return 'EH';
		case 241:
			return 'YE';
		case 242:
			return 'ZM';
		case 243:
			return 'ZW';
		default:
			return 'ZZ';
	}
};
var $author$project$Shopify$InputObject$encodeCartBuyerIdentityInput = function (input____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'email',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.a4, $dillonkearns$elm_graphql$Graphql$Internal$Encode$string)),
				_Utils_Tuple2(
				'phone',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.h, $dillonkearns$elm_graphql$Graphql$Internal$Encode$string)),
				_Utils_Tuple2(
				'countryCode',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.P,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Shopify$Enum$CountryCode$toString))),
				_Utils_Tuple2(
				'customerAccessToken',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.aE, $dillonkearns$elm_graphql$Graphql$Internal$Encode$string))
			]));
};
var $author$project$Shopify$InputObject$encodeCartInput = function (input____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'attributes',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.n,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$list($author$project$Shopify$InputObject$encodeAttributeInput))),
				_Utils_Tuple2(
				'lines',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.bt,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$list($author$project$Shopify$InputObject$encodeCartLineInput))),
				_Utils_Tuple2(
				'discountCodes',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.aF,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$list($dillonkearns$elm_graphql$Graphql$Internal$Encode$string))),
				_Utils_Tuple2(
				'note',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.q, $dillonkearns$elm_graphql$Graphql$Internal$Encode$string)),
				_Utils_Tuple2(
				'buyerIdentity',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.H, $author$project$Shopify$InputObject$encodeCartBuyerIdentityInput))
			]));
};
var $author$project$Shopify$Mutation$cartCreate = F2(
	function (fillInOptionals____, object____) {
		var filledInOptionals____ = fillInOptionals____(
			{dC: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent});
		var optionalArgs____ = A2(
			$elm$core$List$filterMap,
			$elm$core$Basics$identity,
			_List_fromArray(
				[
					A3($dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$optional, 'input', filledInOptionals____.dC, $author$project$Shopify$InputObject$encodeCartInput)
				]));
		return A4(
			$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField,
			'cartCreate',
			optionalArgs____,
			object____,
			A2($elm$core$Basics$composeR, $elm$core$Basics$identity, $elm$json$Json$Decode$nullable));
	});
var $author$project$Api$createCartMutation = function (linesDict) {
	var lines = A2(
		$elm$core$List$map,
		$author$project$Api$cartLineInput,
		$elm$core$Dict$toList(linesDict));
	var cartInput = function (optionals) {
		return _Utils_update(
			optionals,
			{
				bt: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Present(lines)
			});
	};
	return A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$map,
		$elm$core$Maybe$andThen($elm$core$Basics$identity),
		A2(
			$author$project$Shopify$Mutation$cartCreate,
			function (optionals) {
				return _Utils_update(
					optionals,
					{
						dC: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Present(
							$author$project$Shopify$InputObject$buildCartInput(cartInput))
					});
			},
			$author$project$Shopify$Object$CartCreatePayload$cart($author$project$Api$cartInfoSelection)));
};
var $author$project$Api$createCart = F2(
	function (_v0, lines) {
		var url = _v0.ar;
		var token = _v0.aY;
		return A2(
			$dillonkearns$elm_graphql$Graphql$Http$send,
			$elm$core$Basics$identity,
			A3(
				$dillonkearns$elm_graphql$Graphql$Http$withHeader,
				'X-Shopify-Storefront-Access-Token',
				token,
				A2(
					$dillonkearns$elm_graphql$Graphql$Http$mutationRequest,
					url,
					$author$project$Api$createCartMutation(lines))));
	});
var $author$project$Cart$createCart = F2(
	function (config, lines) {
		return A3(
			$author$project$Cart$cartApiResultToMsg,
			$author$project$Cart$CartCreated,
			$author$project$Cart$CartCreationFailed,
			A2($author$project$Api$createCart, config, lines));
	});
var $elm$json$Json$Encode$float = _Json_wrap;
var $elm$core$String$replace = F3(
	function (before, after, string) {
		return A2(
			$elm$core$String$join,
			after,
			A2($elm$core$String$split, before, string));
	});
var $author$project$CartEvent$localProductIdFromGlobalProductId = function (globalId) {
	return A3($elm$core$String$replace, 'gid://shopify/Product/', '', globalId);
};
var $author$project$CartEvent$localProductVariantIdFromGlobalProductVariantId = function (globalId) {
	return A3($elm$core$String$replace, 'gid://shopify/ProductVariant/', '', globalId);
};
var $elm$core$String$toFloat = _String_toFloat;
var $cmditch$elm_bigint$BigInt$BigIntNotNormalised = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $cmditch$elm_bigint$BigInt$MagnitudeNotNormalised = $elm$core$Basics$identity;
var $elm$core$Basics$abs = function (n) {
	return (n < 0) ? (-n) : n;
};
var $elm_community$list_extra$List$Extra$last = function (items) {
	last:
	while (true) {
		if (!items.b) {
			return $elm$core$Maybe$Nothing;
		} else {
			if (!items.b.b) {
				var x = items.a;
				return $elm$core$Maybe$Just(x);
			} else {
				var rest = items.b;
				var $temp$items = rest;
				items = $temp$items;
				continue last;
			}
		}
	}
};
var $cmditch$elm_bigint$BigInt$isNegativeMagnitude = function (digits) {
	var _v0 = $elm_community$list_extra$List$Extra$last(digits);
	if (_v0.$ === 1) {
		return false;
	} else {
		var x = _v0.a;
		return x < 0;
	}
};
var $cmditch$elm_bigint$BigInt$mkBigIntNotNormalised = F2(
	function (s, digits) {
		return A2($cmditch$elm_bigint$BigInt$BigIntNotNormalised, s, digits);
	});
var $elm_community$list_extra$List$Extra$dropWhileRight = function (p) {
	return A2(
		$elm$core$List$foldr,
		F2(
			function (x, xs) {
				return (p(x) && $elm$core$List$isEmpty(xs)) ? _List_Nil : A2($elm$core$List$cons, x, xs);
			}),
		_List_Nil);
};
var $cmditch$elm_bigint$BigInt$dropZeroes = $elm_community$list_extra$List$Extra$dropWhileRight(
	$elm$core$Basics$eq(0));
var $elm$core$Basics$pow = _Basics_pow;
var $cmditch$elm_bigint$Constants$maxDigitValue = (-1) + A2($elm$core$Basics$pow, 10, $cmditch$elm_bigint$Constants$maxDigitMagnitude);
var $cmditch$elm_bigint$BigInt$baseDigit = $cmditch$elm_bigint$Constants$maxDigitValue + 1;
var $elm$core$Tuple$mapFirst = F2(
	function (func, _v0) {
		var x = _v0.a;
		var y = _v0.b;
		return _Utils_Tuple2(
			func(x),
			y);
	});
var $cmditch$elm_bigint$BigInt$normaliseDigit = function (x) {
	return (x < 0) ? A2(
		$elm$core$Tuple$mapFirst,
		$elm$core$Basics$add(-1),
		$cmditch$elm_bigint$BigInt$normaliseDigit(x + $cmditch$elm_bigint$BigInt$baseDigit)) : _Utils_Tuple2((x / $cmditch$elm_bigint$BigInt$baseDigit) | 0, x % $cmditch$elm_bigint$BigInt$baseDigit);
};
var $cmditch$elm_bigint$BigInt$normaliseDigitList = F2(
	function (carry, xs) {
		normaliseDigitList:
		while (true) {
			if (!xs.b) {
				if (_Utils_cmp(carry, $cmditch$elm_bigint$BigInt$baseDigit) > 0) {
					var $temp$carry = 0,
						$temp$xs = _List_fromArray(
						[carry]);
					carry = $temp$carry;
					xs = $temp$xs;
					continue normaliseDigitList;
				} else {
					return _List_fromArray(
						[carry]);
				}
			} else {
				var x = xs.a;
				var xs_ = xs.b;
				var _v1 = $cmditch$elm_bigint$BigInt$normaliseDigit(x + carry);
				var newCarry = _v1.a;
				var x_ = _v1.b;
				return A2(
					$elm$core$List$cons,
					x_,
					A2($cmditch$elm_bigint$BigInt$normaliseDigitList, newCarry, xs_));
			}
		}
	});
var $cmditch$elm_bigint$BigInt$normaliseMagnitude = function (_v0) {
	var xs = _v0;
	return $cmditch$elm_bigint$BigInt$dropZeroes(
		A2($cmditch$elm_bigint$BigInt$normaliseDigitList, 0, xs));
};
var $cmditch$elm_bigint$BigInt$reverseMagnitude = $elm$core$List$map($elm$core$Basics$negate);
var $cmditch$elm_bigint$BigInt$Zero = 2;
var $cmditch$elm_bigint$BigInt$signNegate = function (sign_) {
	switch (sign_) {
		case 0:
			return 1;
		case 1:
			return 0;
		default:
			return 2;
	}
};
var $cmditch$elm_bigint$BigInt$normalise = function (_v0) {
	normalise:
	while (true) {
		var s = _v0.a;
		var digits = _v0.b;
		var _v1 = $cmditch$elm_bigint$BigInt$normaliseMagnitude(digits);
		var normalisedMag = _v1;
		if ($cmditch$elm_bigint$BigInt$isNegativeMagnitude(normalisedMag)) {
			var $temp$_v0 = A2(
				$cmditch$elm_bigint$BigInt$mkBigIntNotNormalised,
				$cmditch$elm_bigint$BigInt$signNegate(s),
				$cmditch$elm_bigint$BigInt$reverseMagnitude(normalisedMag));
			_v0 = $temp$_v0;
			continue normalise;
		} else {
			return A2($cmditch$elm_bigint$BigInt$mkBigInt, s, normalisedMag);
		}
	}
};
var $cmditch$elm_bigint$BigInt$signFromInt = function (x) {
	var _v0 = A2($elm$core$Basics$compare, x, 0);
	switch (_v0) {
		case 0:
			return 1;
		case 2:
			return 0;
		default:
			return 2;
	}
};
var $cmditch$elm_bigint$BigInt$fromInt = function (x) {
	return $cmditch$elm_bigint$BigInt$normalise(
		A2(
			$cmditch$elm_bigint$BigInt$BigIntNotNormalised,
			$cmditch$elm_bigint$BigInt$signFromInt(x),
			_List_fromArray(
				[
					$elm$core$Basics$abs(x)
				])));
};
var $cmditch$elm_bigint$BigInt$compareMagnitude = F4(
	function (x, y, xs, ys) {
		compareMagnitude:
		while (true) {
			var _v0 = _Utils_Tuple2(xs, ys);
			if (!_v0.a.b) {
				if (!_v0.b.b) {
					return A2($elm$core$Basics$compare, x, y);
				} else {
					return 0;
				}
			} else {
				if (!_v0.b.b) {
					return 2;
				} else {
					var _v1 = _v0.a;
					var x_ = _v1.a;
					var xss = _v1.b;
					var _v2 = _v0.b;
					var y_ = _v2.a;
					var yss = _v2.b;
					if (_Utils_eq(x_, y_)) {
						var $temp$x = x,
							$temp$y = y,
							$temp$xs = xss,
							$temp$ys = yss;
						x = $temp$x;
						y = $temp$y;
						xs = $temp$xs;
						ys = $temp$ys;
						continue compareMagnitude;
					} else {
						var $temp$x = x_,
							$temp$y = y_,
							$temp$xs = xss,
							$temp$ys = yss;
						x = $temp$x;
						y = $temp$y;
						xs = $temp$xs;
						ys = $temp$ys;
						continue compareMagnitude;
					}
				}
			}
		}
	});
var $cmditch$elm_bigint$BigInt$orderNegate = function (x) {
	switch (x) {
		case 0:
			return 2;
		case 1:
			return 1;
		default:
			return 0;
	}
};
var $cmditch$elm_bigint$BigInt$compare = F2(
	function (int1, int2) {
		var _v0 = _Utils_Tuple2(int1, int2);
		switch (_v0.a.$) {
			case 0:
				if (!_v0.b.$) {
					var mag1 = _v0.a.a;
					var mag2 = _v0.b.a;
					return A4($cmditch$elm_bigint$BigInt$compareMagnitude, 0, 0, mag1, mag2);
				} else {
					return 2;
				}
			case 1:
				if (_v0.b.$ === 1) {
					var mag1 = _v0.a.a;
					var mag2 = _v0.b.a;
					return $cmditch$elm_bigint$BigInt$orderNegate(
						A4($cmditch$elm_bigint$BigInt$compareMagnitude, 0, 0, mag1, mag2));
				} else {
					return 0;
				}
			default:
				switch (_v0.b.$) {
					case 0:
						var _v1 = _v0.a;
						return 0;
					case 2:
						var _v2 = _v0.a;
						var _v3 = _v0.b;
						return 1;
					default:
						var _v4 = _v0.a;
						return 2;
				}
		}
	});
var $cmditch$elm_bigint$BigInt$lt = F2(
	function (x, y) {
		return !A2($cmditch$elm_bigint$BigInt$compare, x, y);
	});
var $cmditch$elm_bigint$BigInt$gte = F2(
	function (x, y) {
		return !A2($cmditch$elm_bigint$BigInt$lt, x, y);
	});
var $elm$core$String$right = F2(
	function (n, string) {
		return (n < 1) ? '' : A3(
			$elm$core$String$slice,
			-n,
			$elm$core$String$length(string),
			string);
	});
var $prikhi$decimal$Decimal$insert_decimal_period = F2(
	function (pos, s) {
		var extra_zeros = pos - $elm$core$String$length(s);
		var padded_s = (extra_zeros >= 0) ? _Utils_ap(
			A2($elm$core$String$repeat, extra_zeros + 1, '0'),
			s) : s;
		var before = A2($elm$core$String$dropRight, pos, padded_s);
		var after = A2($elm$core$String$right, pos, padded_s);
		return before + ('.' + after);
	});
var $cmditch$elm_bigint$BigInt$negate = function (bigInt) {
	switch (bigInt.$) {
		case 2:
			return $cmditch$elm_bigint$BigInt$Zer;
		case 0:
			var mag = bigInt.a;
			return $cmditch$elm_bigint$BigInt$Neg(mag);
		default:
			var mag = bigInt.a;
			return $cmditch$elm_bigint$BigInt$Pos(mag);
	}
};
var $elm$core$String$cons = _String_cons;
var $elm$core$String$fromChar = function (_char) {
	return A2($elm$core$String$cons, _char, '');
};
var $elm$core$String$padLeft = F3(
	function (n, _char, string) {
		return _Utils_ap(
			A2(
				$elm$core$String$repeat,
				n - $elm$core$String$length(string),
				$elm$core$String$fromChar(_char)),
			string);
	});
var $cmditch$elm_bigint$BigInt$fillZeroes = A2(
	$elm$core$Basics$composeL,
	A2($elm$core$String$padLeft, $cmditch$elm_bigint$Constants$maxDigitMagnitude, '0'),
	$elm$core$String$fromInt);
var $cmditch$elm_bigint$BigInt$revMagnitudeToString = function (_v0) {
	var digits = _v0;
	var _v1 = $elm$core$List$reverse(digits);
	if (!_v1.b) {
		return '0';
	} else {
		var x = _v1.a;
		var xs = _v1.b;
		return $elm$core$String$concat(
			A2(
				$elm$core$List$cons,
				$elm$core$String$fromInt(x),
				A2($elm$core$List$map, $cmditch$elm_bigint$BigInt$fillZeroes, xs)));
	}
};
var $cmditch$elm_bigint$BigInt$toString = function (bigInt) {
	switch (bigInt.$) {
		case 2:
			return '0';
		case 0:
			var mag = bigInt.a;
			return $cmditch$elm_bigint$BigInt$revMagnitudeToString(mag);
		default:
			var mag = bigInt.a;
			return '-' + $cmditch$elm_bigint$BigInt$revMagnitudeToString(mag);
	}
};
var $prikhi$decimal$Decimal$toString = function (_v0) {
	var m = _v0.a;
	var e = _v0.b;
	var sign = A2(
		$cmditch$elm_bigint$BigInt$gte,
		m,
		$cmditch$elm_bigint$BigInt$fromInt(0)) ? '' : '-';
	var add_zeros = function (n) {
		return A2($elm$core$String$repeat, n, '0');
	};
	var abs_m = A2(
		$cmditch$elm_bigint$BigInt$gte,
		m,
		$cmditch$elm_bigint$BigInt$fromInt(0)) ? m : $cmditch$elm_bigint$BigInt$negate(m);
	var s = $cmditch$elm_bigint$BigInt$toString(abs_m);
	var _v1 = A2($elm$core$Basics$compare, e, 0);
	switch (_v1) {
		case 1:
			return _Utils_ap(sign, s);
		case 2:
			return _Utils_ap(
				sign,
				_Utils_ap(
					s,
					add_zeros(e)));
		default:
			return _Utils_ap(
				sign,
				A2($prikhi$decimal$Decimal$insert_decimal_period, 0 - e, s));
	}
};
var $prikhi$decimal$Decimal$toFloat = function (d) {
	var _v0 = $elm$core$String$toFloat(
		$prikhi$decimal$Decimal$toString(d));
	if (!_v0.$) {
		var a = _v0.a;
		return a;
	} else {
		return 42.0;
	}
};
var $author$project$CartEvent$encodeItem = function (item) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'item_id',
				$elm$json$Json$Encode$string(
					$author$project$CartEvent$localProductIdFromGlobalProductId(item.bb))),
				_Utils_Tuple2(
				'price',
				$elm$json$Json$Encode$float(
					$prikhi$decimal$Decimal$toFloat(item.d0.bS))),
				_Utils_Tuple2(
				'quantity',
				$elm$json$Json$Encode$int(item.am)),
				_Utils_Tuple2(
				'item_variant',
				$elm$json$Json$Encode$string(
					$author$project$CartEvent$localProductVariantIdFromGlobalProductVariantId(item.cI)))
			]));
};
var $elm$json$Json$Encode$list = F2(
	function (func, entries) {
		return _Json_wrap(
			A3(
				$elm$core$List$foldl,
				_Json_addEntry(func),
				_Json_emptyArray(0),
				entries));
	});
var $author$project$CartEvent$encodeEventValue = F2(
	function (maybeTransactionId, change) {
		var items = A2(
			$elm$core$List$map,
			$elm$core$Tuple$second,
			$elm$core$Dict$toList(change.a5));
		var encodedTransactionId = A2(
			$elm$core$Maybe$withDefault,
			_List_Nil,
			A2(
				$elm$core$Maybe$map,
				$elm$core$List$singleton,
				A2(
					$elm$core$Maybe$map,
					function (value) {
						return _Utils_Tuple2(
							'transaction_id',
							$elm$json$Json$Encode$string(value));
					},
					maybeTransactionId)));
		return $elm$json$Json$Encode$object(
			A2(
				$elm$core$List$append,
				_List_fromArray(
					[
						_Utils_Tuple2(
						'currency',
						$elm$json$Json$Encode$string(change.c1.dp)),
						_Utils_Tuple2(
						'value',
						$elm$json$Json$Encode$float(
							$prikhi$decimal$Decimal$toFloat(change.c1.bS))),
						_Utils_Tuple2(
						'items',
						A2($elm$json$Json$Encode$list, $author$project$CartEvent$encodeItem, items))
					]),
				encodedTransactionId));
	});
var $author$project$CartEvent$encode = function (event) {
	switch (event.$) {
		case 0:
			var cartId = event.a;
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'type',
						$elm$json$Json$Encode$string('CartCreated')),
						_Utils_Tuple2(
						'value',
						$elm$json$Json$Encode$string(cartId))
					]));
		case 1:
			var change = event.a;
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'type',
						$elm$json$Json$Encode$string('AddedToCart')),
						_Utils_Tuple2(
						'value',
						A2($author$project$CartEvent$encodeEventValue, $elm$core$Maybe$Nothing, change))
					]));
		case 2:
			var change = event.a;
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'type',
						$elm$json$Json$Encode$string('RemovedFromCart')),
						_Utils_Tuple2(
						'value',
						A2($author$project$CartEvent$encodeEventValue, $elm$core$Maybe$Nothing, change))
					]));
		case 3:
			var cartState = event.a;
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'type',
						$elm$json$Json$Encode$string('CheckoutStarted')),
						_Utils_Tuple2(
						'value',
						A2($author$project$CartEvent$encodeEventValue, $elm$core$Maybe$Nothing, cartState))
					]));
		default:
			var transactionId = event.a;
			var cartState = event.b;
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'type',
						$elm$json$Json$Encode$string('CheckoutCompleted')),
						_Utils_Tuple2(
						'value',
						A2(
							$author$project$CartEvent$encodeEventValue,
							$elm$core$Maybe$Just(transactionId),
							cartState))
					]));
	}
};
var $author$project$Cart$events = _Platform_outgoingPort('events', $elm$core$Basics$identity);
var $author$project$Cart$CartLoaded = function (a) {
	return {$: 5, a: a};
};
var $author$project$Cart$CartLoadingFailed = {$: 6};
var $author$project$Shopify$Query$cart = F2(
	function (requiredArgs____, object____) {
		return A4(
			$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField,
			'cart',
			_List_fromArray(
				[
					A3(
					$dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$required,
					'id',
					requiredArgs____.aI,
					A2(
						$author$project$Shopify$Scalar$unwrapEncoder,
						function ($) {
							return $.ac;
						},
						$author$project$Shopify$ScalarCodecs$codecs))
				]),
			object____,
			A2($elm$core$Basics$composeR, $elm$core$Basics$identity, $elm$json$Json$Decode$nullable));
	});
var $author$project$Api$getCartQuery = function (id) {
	return A2(
		$author$project$Shopify$Query$cart,
		{aI: id},
		$author$project$Api$cartInfoSelection);
};
var $dillonkearns$elm_graphql$Graphql$Http$Query = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $dillonkearns$elm_graphql$Graphql$Http$queryRequest = F2(
	function (baseUrl, query) {
		return {
			a3: baseUrl,
			br: A2($dillonkearns$elm_graphql$Graphql$Http$Query, $elm$core$Maybe$Nothing, query),
			ds: $dillonkearns$elm_graphql$Graphql$Document$decoder(query),
			z: _List_Nil,
			aj: $elm$core$Maybe$Nothing,
			an: _List_Nil,
			E: $elm$core$Maybe$Nothing,
			at: false
		};
	});
var $author$project$Api$getCart = F2(
	function (_v0, id) {
		var url = _v0.ar;
		var token = _v0.aY;
		return A2(
			$dillonkearns$elm_graphql$Graphql$Http$send,
			$elm$core$Basics$identity,
			A3(
				$dillonkearns$elm_graphql$Graphql$Http$withHeader,
				'X-Shopify-Storefront-Access-Token',
				token,
				A2(
					$dillonkearns$elm_graphql$Graphql$Http$queryRequest,
					url,
					$author$project$Api$getCartQuery(id))));
	});
var $author$project$Cart$loadCart = F2(
	function (config, cartId) {
		return A3(
			$author$project$Cart$cartApiResultToMsg,
			$author$project$Cart$CartLoaded,
			$author$project$Cart$CartLoadingFailed,
			A2($author$project$Api$getCart, config, cartId));
	});
var $author$project$Cart$effectToCmd = F2(
	function (apiConfig, effect) {
		switch (effect.$) {
			case 0:
				var cartId = effect.a;
				return A2($author$project$Cart$loadCart, apiConfig, cartId);
			case 1:
				var lines = effect.a;
				return A2($author$project$Cart$createCart, apiConfig, lines);
			case 2:
				var input = effect.a;
				return A2($author$project$Cart$cartLinesAdd, apiConfig, input);
			case 3:
				var input = effect.a;
				return A2($author$project$Cart$cartLinesRemove, apiConfig, input);
			case 4:
				var input = effect.a;
				return A2($author$project$Cart$cartLinesUpdate, apiConfig, input);
			case 5:
				var url = effect.a;
				return $author$project$Cart$commands(
					$elm$json$Json$Encode$object(
						_List_fromArray(
							[
								_Utils_Tuple2(
								'type',
								$elm$json$Json$Encode$string('Checkout')),
								_Utils_Tuple2(
								'url',
								$elm$json$Json$Encode$string(url))
							])));
			default:
				var event = effect.a;
				return $author$project$Cart$events(
					$author$project$CartEvent$encode(event));
		}
	});
var $author$project$Cart$Closed = 1;
var $author$project$Cart$CreateCart = function (a) {
	return {$: 1, a: a};
};
var $author$project$Cart$LoadCart = function (a) {
	return {$: 0, a: a};
};
var $author$project$Cart$Loading = {$: 0};
var $elm$core$String$append = _String_append;
var $author$project$Cart$init = function (_v0) {
	var endpoint = _v0.bA;
	var token = _v0.aY;
	var cartId = _v0.Z;
	var productVariantId = _v0.cI;
	var maybeProductVariantId = A2(
		$elm$core$Maybe$map,
		$elm$core$String$append('gid://shopify/ProductVariant/'),
		productVariantId);
	var effects = $elm$core$List$singleton(
		A2(
			$elm$core$Maybe$withDefault,
			$author$project$Cart$CreateCart($elm$core$Dict$empty),
			A2($elm$core$Maybe$map, $author$project$Cart$LoadCart, cartId)));
	return _Utils_Tuple2(
		{
			bn: {aY: token, ar: endpoint},
			f: $author$project$Cart$Loading,
			cI: maybeProductVariantId,
			bi: 1
		},
		effects);
};
var $author$project$Cart$initWithCmd = function (flags) {
	var _v0 = $author$project$Cart$init(flags);
	var cart = _v0.a;
	var effects = _v0.b;
	var cmd = $elm$core$Platform$Cmd$batch(
		A2(
			$elm$core$List$map,
			$author$project$Cart$effectToCmd(cart.bn),
			effects));
	return _Utils_Tuple2(cart, cmd);
};
var $author$project$Cart$NoOpMsg = {$: 0};
var $elm$json$Json$Decode$decodeValue = _Json_run;
var $author$project$Cart$CheckoutCompleted = function (a) {
	return {$: 12, a: a};
};
var $author$project$Cart$UpdateCart = F2(
	function (a, b) {
		return {$: 9, a: a, b: b};
	});
var $author$project$Cart$decodeTypeField = function (value) {
	switch (value) {
		case 'UpdateCart':
			return A3(
				$elm$json$Json$Decode$map2,
				$author$project$Cart$UpdateCart,
				A2($elm$json$Json$Decode$field, 'id', $elm$json$Json$Decode$string),
				A2($elm$json$Json$Decode$field, 'quantity', $elm$json$Json$Decode$int));
		case 'CheckoutCompleted':
			return A2(
				$elm$json$Json$Decode$map,
				$author$project$Cart$CheckoutCompleted,
				A2(
					$elm$json$Json$Decode$at,
					_List_fromArray(
						['order', 'transactionId']),
					$elm$json$Json$Decode$string));
		default:
			return $elm$json$Json$Decode$fail('Unknown value \'' + (value + '\' for field \'type\''));
	}
};
var $author$project$Cart$messageDecoder = A2(
	$elm$json$Json$Decode$andThen,
	$author$project$Cart$decodeTypeField,
	A2($elm$json$Json$Decode$field, 'type', $elm$json$Json$Decode$string));
var $author$project$Cart$messages = _Platform_incomingPort('messages', $elm$json$Json$Decode$value);
var $elm$core$Result$withDefault = F2(
	function (def, result) {
		if (!result.$) {
			var a = result.a;
			return a;
		} else {
			return def;
		}
	});
var $author$project$Cart$subscriptions = $author$project$Cart$messages(
	A2(
		$elm$core$Basics$composeR,
		$elm$json$Json$Decode$decodeValue($author$project$Cart$messageDecoder),
		$elm$core$Result$withDefault($author$project$Cart$NoOpMsg)));
var $author$project$Cart$Broadcast = function (a) {
	return {$: 6, a: a};
};
var $author$project$CartEvent$CartCreated = function (a) {
	return {$: 0, a: a};
};
var $author$project$CartEvent$CheckoutCompleted = F2(
	function (a, b) {
		return {$: 4, a: a, b: b};
	});
var $author$project$CartEvent$CheckoutStarted = function (a) {
	return {$: 3, a: a};
};
var $author$project$Cart$CreationFailed = {$: 2};
var $author$project$Cart$Loaded = function (a) {
	return {$: 1, a: a};
};
var $author$project$Cart$Open = 0;
var $author$project$Cart$Recreating = F2(
	function (a, b) {
		return {$: 4, a: a, b: b};
	});
var $author$project$Cart$RecreationFailed = F2(
	function (a, b) {
		return {$: 5, a: a, b: b};
	});
var $author$project$Cart$StartCheckout = function (a) {
	return {$: 5, a: a};
};
var $author$project$CartEvent$AddedToCart = function (a) {
	return {$: 1, a: a};
};
var $author$project$CartEvent$RemovedFromCart = function (a) {
	return {$: 2, a: a};
};
var $cmditch$elm_bigint$BigInt$MagnitudePair = $elm$core$Basics$identity;
var $cmditch$elm_bigint$BigInt$sameSizeRaw = F2(
	function (xs, ys) {
		var _v0 = _Utils_Tuple2(xs, ys);
		if (!_v0.a.b) {
			if (!_v0.b.b) {
				return _List_Nil;
			} else {
				var _v2 = _v0.b;
				var y = _v2.a;
				var ys_ = _v2.b;
				return A2(
					$elm$core$List$cons,
					_Utils_Tuple2(0, y),
					A2($cmditch$elm_bigint$BigInt$sameSizeRaw, _List_Nil, ys_));
			}
		} else {
			if (!_v0.b.b) {
				var _v1 = _v0.a;
				var x = _v1.a;
				var xs_ = _v1.b;
				return A2(
					$elm$core$List$cons,
					_Utils_Tuple2(x, 0),
					A2($cmditch$elm_bigint$BigInt$sameSizeRaw, xs_, _List_Nil));
			} else {
				var _v3 = _v0.a;
				var x = _v3.a;
				var xs_ = _v3.b;
				var _v4 = _v0.b;
				var y = _v4.a;
				var ys_ = _v4.b;
				return A2(
					$elm$core$List$cons,
					_Utils_Tuple2(x, y),
					A2($cmditch$elm_bigint$BigInt$sameSizeRaw, xs_, ys_));
			}
		}
	});
var $cmditch$elm_bigint$BigInt$sameSizeNotNormalized = F2(
	function (_v0, _v1) {
		var xs = _v0;
		var ys = _v1;
		return A2($cmditch$elm_bigint$BigInt$sameSizeRaw, xs, ys);
	});
var $cmditch$elm_bigint$BigInt$toPositiveSign = function (bigInt) {
	switch (bigInt.$) {
		case 2:
			return A2($cmditch$elm_bigint$BigInt$mkBigIntNotNormalised, 2, _List_Nil);
		case 1:
			var digits = bigInt.a;
			return A2(
				$cmditch$elm_bigint$BigInt$mkBigIntNotNormalised,
				0,
				$cmditch$elm_bigint$BigInt$reverseMagnitude(digits));
		default:
			var digits = bigInt.a;
			return A2($cmditch$elm_bigint$BigInt$mkBigIntNotNormalised, 0, digits);
	}
};
var $cmditch$elm_bigint$BigInt$add = F2(
	function (a, b) {
		var _v0 = $cmditch$elm_bigint$BigInt$toPositiveSign(b);
		var mb = _v0.b;
		var _v1 = $cmditch$elm_bigint$BigInt$toPositiveSign(a);
		var ma = _v1.b;
		var _v2 = A2($cmditch$elm_bigint$BigInt$sameSizeNotNormalized, ma, mb);
		var pairs = _v2;
		var added = A2(
			$elm$core$List$map,
			function (_v3) {
				var a_ = _v3.a;
				var b_ = _v3.b;
				return a_ + b_;
			},
			pairs);
		return $cmditch$elm_bigint$BigInt$normalise(
			A2($cmditch$elm_bigint$BigInt$BigIntNotNormalised, 0, added));
	});
var $elm$core$Basics$min = F2(
	function (x, y) {
		return (_Utils_cmp(x, y) < 0) ? x : y;
	});
var $cmditch$elm_bigint$BigInt$magnitude = function (bigInt) {
	switch (bigInt.$) {
		case 2:
			return _List_Nil;
		case 0:
			var mag = bigInt.a;
			return mag;
		default:
			var mag = bigInt.a;
			return mag;
	}
};
var $cmditch$elm_bigint$BigInt$mulSingleDigit = F2(
	function (_v0, d) {
		var xs = _v0;
		return $cmditch$elm_bigint$BigInt$normaliseMagnitude(
			A2(
				$elm$core$List$map,
				$elm$core$Basics$mul(d),
				xs));
	});
var $cmditch$elm_bigint$BigInt$mulMagnitudes = F2(
	function (_v0, _v1) {
		var mag1 = _v0;
		var mag2 = _v1;
		if (!mag1.b) {
			return _List_Nil;
		} else {
			if (!mag1.b.b) {
				var m = mag1.a;
				return A2($cmditch$elm_bigint$BigInt$mulSingleDigit, mag2, m);
			} else {
				var m = mag1.a;
				var mx = mag1.b;
				var accum = A2($cmditch$elm_bigint$BigInt$mulSingleDigit, mag2, m);
				var _v3 = A2($cmditch$elm_bigint$BigInt$mulMagnitudes, mx, mag2);
				var rest = _v3;
				var bigInt = A2(
					$cmditch$elm_bigint$BigInt$add,
					A2($cmditch$elm_bigint$BigInt$mkBigInt, 0, accum),
					A2(
						$cmditch$elm_bigint$BigInt$mkBigInt,
						0,
						A2($elm$core$List$cons, 0, rest)));
				return $cmditch$elm_bigint$BigInt$magnitude(bigInt);
			}
		}
	});
var $cmditch$elm_bigint$BigInt$sign = function (bigInt) {
	switch (bigInt.$) {
		case 2:
			return 2;
		case 0:
			return 0;
		default:
			return 1;
	}
};
var $cmditch$elm_bigint$BigInt$signProduct = F2(
	function (x, y) {
		return ((x === 2) || (y === 2)) ? 2 : (_Utils_eq(x, y) ? 0 : 1);
	});
var $cmditch$elm_bigint$BigInt$mul = F2(
	function (int1, int2) {
		return A2(
			$cmditch$elm_bigint$BigInt$mkBigInt,
			A2(
				$cmditch$elm_bigint$BigInt$signProduct,
				$cmditch$elm_bigint$BigInt$sign(int1),
				$cmditch$elm_bigint$BigInt$sign(int2)),
			A2(
				$cmditch$elm_bigint$BigInt$mulMagnitudes,
				$cmditch$elm_bigint$BigInt$magnitude(int1),
				$cmditch$elm_bigint$BigInt$magnitude(int2)));
	});
var $prikhi$decimal$Decimal$addDecimals = F2(
	function (i, _v0) {
		addDecimals:
		while (true) {
			var m = _v0.a;
			var e = _v0.b;
			var mul10 = function (x) {
				return A2(
					$cmditch$elm_bigint$BigInt$mul,
					x,
					$cmditch$elm_bigint$BigInt$fromInt(10));
			};
			if (!i) {
				return A2($prikhi$decimal$Decimal$Decimal, m, e);
			} else {
				if (i > 0) {
					var $temp$i = i - 1,
						$temp$_v0 = A2(
						$prikhi$decimal$Decimal$Decimal,
						mul10(m),
						e - 1);
					i = $temp$i;
					_v0 = $temp$_v0;
					continue addDecimals;
				} else {
					return A2($prikhi$decimal$Decimal$Decimal, m, e);
				}
			}
		}
	});
var $prikhi$decimal$Decimal$toExponent = F2(
	function (e, _v0) {
		var md = _v0.a;
		var ed = _v0.b;
		return A2(
			$prikhi$decimal$Decimal$addDecimals,
			ed - e,
			A2($prikhi$decimal$Decimal$Decimal, md, ed));
	});
var $prikhi$decimal$Decimal$toCommonExponent = function (_v0) {
	var a = _v0.a;
	var b = _v0.b;
	var _v1 = b;
	var mb = _v1.a;
	var eb = _v1.b;
	var _v2 = a;
	var ma = _v2.a;
	var ea = _v2.b;
	var exponent = A2($elm$core$Basics$min, ea, eb);
	return _Utils_Tuple2(
		A2($prikhi$decimal$Decimal$toExponent, exponent, a),
		A2($prikhi$decimal$Decimal$toExponent, exponent, b));
};
var $prikhi$decimal$Decimal$add = F2(
	function (a, b) {
		var _v0 = $prikhi$decimal$Decimal$toCommonExponent(
			_Utils_Tuple2(a, b));
		var ra = _v0.a;
		var rb = _v0.b;
		var _v1 = rb;
		var mb = _v1.a;
		var eb = _v1.b;
		var _v2 = ra;
		var ma = _v2.a;
		var ea = _v2.b;
		return A2(
			$prikhi$decimal$Decimal$Decimal,
			A2($cmditch$elm_bigint$BigInt$add, ma, mb),
			ea);
	});
var $author$project$Money$add = F2(
	function (moneyA, moneyB) {
		return _Utils_update(
			moneyA,
			{
				bS: A2($prikhi$decimal$Decimal$add, moneyA.bS, moneyB.bS)
			});
	});
var $prikhi$decimal$Decimal$fromIntWithExponent = F2(
	function (n, e) {
		return A2(
			$prikhi$decimal$Decimal$Decimal,
			$cmditch$elm_bigint$BigInt$fromInt(n),
			e);
	});
var $prikhi$decimal$Decimal$fromInt = function (n) {
	return A2($prikhi$decimal$Decimal$fromIntWithExponent, n, 0);
};
var $prikhi$decimal$Decimal$mul = F2(
	function (_v0, _v1) {
		var ma = _v0.a;
		var ea = _v0.b;
		var mb = _v1.a;
		var eb = _v1.b;
		return A2(
			$prikhi$decimal$Decimal$Decimal,
			A2($cmditch$elm_bigint$BigInt$mul, ma, mb),
			ea + eb);
	});
var $author$project$Money$mul = F2(
	function (money, factor) {
		return _Utils_update(
			money,
			{
				bS: A2(
					$prikhi$decimal$Decimal$mul,
					money.bS,
					$prikhi$decimal$Decimal$fromInt(factor))
			});
	});
var $author$project$Cart$addDiffToExistingChange = F2(
	function (newItem, existingChange) {
		return {
			a5: A3($elm$core$Dict$insert, newItem.cI, newItem, existingChange.a5),
			c1: A2(
				$author$project$Money$add,
				existingChange.c1,
				A2($author$project$Money$mul, newItem.d0, newItem.am))
		};
	});
var $elm$core$Dict$singleton = F2(
	function (key, value) {
		return A5($elm$core$Dict$RBNode_elm_builtin, 1, key, value, $elm$core$Dict$RBEmpty_elm_builtin, $elm$core$Dict$RBEmpty_elm_builtin);
	});
var $author$project$Cart$cartChangeFromCartItem = function (item) {
	return {
		a5: A2($elm$core$Dict$singleton, item.cI, item),
		c1: A2($author$project$Money$mul, item.d0, item.am)
	};
};
var $author$project$Cart$cartToCartItems = function (cart) {
	return $elm$core$Dict$fromList(
		A2(
			$elm$core$List$map,
			function (line) {
				return _Utils_Tuple2(
					line.d2.aI,
					{d0: line.d2.d0, bb: line.d2.d1.aI, cI: line.d2.aI, am: line.am});
			},
			cart.bt));
};
var $author$project$Cart$emptyCartDiff = {ah: $elm$core$Maybe$Nothing, ap: $elm$core$Maybe$Nothing};
var $elm$core$Dict$foldl = F3(
	function (func, acc, dict) {
		foldl:
		while (true) {
			if (dict.$ === -2) {
				return acc;
			} else {
				var key = dict.b;
				var value = dict.c;
				var left = dict.d;
				var right = dict.e;
				var $temp$func = func,
					$temp$acc = A3(
					func,
					key,
					value,
					A3($elm$core$Dict$foldl, func, acc, left)),
					$temp$dict = right;
				func = $temp$func;
				acc = $temp$acc;
				dict = $temp$dict;
				continue foldl;
			}
		}
	});
var $elm$core$Dict$merge = F6(
	function (leftStep, bothStep, rightStep, leftDict, rightDict, initialResult) {
		var stepState = F3(
			function (rKey, rValue, _v0) {
				stepState:
				while (true) {
					var list = _v0.a;
					var result = _v0.b;
					if (!list.b) {
						return _Utils_Tuple2(
							list,
							A3(rightStep, rKey, rValue, result));
					} else {
						var _v2 = list.a;
						var lKey = _v2.a;
						var lValue = _v2.b;
						var rest = list.b;
						if (_Utils_cmp(lKey, rKey) < 0) {
							var $temp$rKey = rKey,
								$temp$rValue = rValue,
								$temp$_v0 = _Utils_Tuple2(
								rest,
								A3(leftStep, lKey, lValue, result));
							rKey = $temp$rKey;
							rValue = $temp$rValue;
							_v0 = $temp$_v0;
							continue stepState;
						} else {
							if (_Utils_cmp(lKey, rKey) > 0) {
								return _Utils_Tuple2(
									list,
									A3(rightStep, rKey, rValue, result));
							} else {
								return _Utils_Tuple2(
									rest,
									A4(bothStep, lKey, lValue, rValue, result));
							}
						}
					}
				}
			});
		var _v3 = A3(
			$elm$core$Dict$foldl,
			stepState,
			_Utils_Tuple2(
				$elm$core$Dict$toList(leftDict),
				initialResult),
			rightDict);
		var leftovers = _v3.a;
		var intermediateResult = _v3.b;
		return A3(
			$elm$core$List$foldl,
			F2(
				function (_v4, result) {
					var k = _v4.a;
					var v = _v4.b;
					return A3(leftStep, k, v, result);
				}),
			intermediateResult,
			leftovers);
	});
var $author$project$Cart$diffCarts = F2(
	function (oldCart, newCart) {
		return A6(
			$elm$core$Dict$merge,
			F3(
				function (_v0, vl, acc) {
					return _Utils_update(
						acc,
						{
							ap: $elm$core$Maybe$Just(
								A2(
									$elm$core$Maybe$withDefault,
									$author$project$Cart$cartChangeFromCartItem(vl),
									A2(
										$elm$core$Maybe$map,
										$author$project$Cart$addDiffToExistingChange(vl),
										acc.ap)))
						});
				}),
			F4(
				function (_v1, vl, vr, acc) {
					if (_Utils_cmp(vl.am, vr.am) > 0) {
						var item = {d0: vl.d0, bb: vl.bb, cI: vl.cI, am: vl.am - vr.am};
						var removals = $elm$core$Maybe$Just(
							A2(
								$elm$core$Maybe$withDefault,
								$author$project$Cart$cartChangeFromCartItem(item),
								A2(
									$elm$core$Maybe$map,
									$author$project$Cart$addDiffToExistingChange(item),
									acc.ap)));
						return _Utils_update(
							acc,
							{ap: removals});
					} else {
						if (_Utils_cmp(vl.am, vr.am) < 0) {
							var item = {d0: vr.d0, bb: vr.bb, cI: vr.cI, am: vr.am - vl.am};
							var additions = $elm$core$Maybe$Just(
								A2(
									$elm$core$Maybe$withDefault,
									$author$project$Cart$cartChangeFromCartItem(item),
									A2(
										$elm$core$Maybe$map,
										$author$project$Cart$addDiffToExistingChange(item),
										acc.ah)));
							return _Utils_update(
								acc,
								{ah: additions});
						} else {
							return acc;
						}
					}
				}),
			F3(
				function (_v2, vr, acc) {
					return _Utils_update(
						acc,
						{
							ah: $elm$core$Maybe$Just(
								A2(
									$elm$core$Maybe$withDefault,
									$author$project$Cart$cartChangeFromCartItem(vr),
									A2(
										$elm$core$Maybe$map,
										$author$project$Cart$addDiffToExistingChange(vr),
										acc.ah)))
						});
				}),
			$author$project$Cart$cartToCartItems(oldCart),
			$author$project$Cart$cartToCartItems(newCart),
			$author$project$Cart$emptyCartDiff);
	});
var $author$project$Cart$getCart = function (remoteCart) {
	switch (remoteCart.$) {
		case 0:
			return $elm$core$Maybe$Nothing;
		case 1:
			var cart = remoteCart.a;
			return $elm$core$Maybe$Just(cart);
		case 2:
			return $elm$core$Maybe$Nothing;
		case 3:
			var cart = remoteCart.a;
			var change = remoteCart.b;
			return $elm$core$Maybe$Just(cart);
		case 4:
			var cart = remoteCart.a;
			var change = remoteCart.b;
			return $elm$core$Maybe$Just(cart);
		default:
			var cart = remoteCart.a;
			var change = remoteCart.b;
			return $elm$core$Maybe$Just(cart);
	}
};
var $author$project$Cart$calculateCartChangeEvent = F2(
	function (remoteCart, newCart) {
		var _v0 = $author$project$Cart$getCart(remoteCart);
		if (!_v0.$) {
			var oldCart = _v0.a;
			var cartDiff = A2($author$project$Cart$diffCarts, oldCart, newCart);
			return A2(
				$elm$core$List$map,
				$author$project$Cart$Broadcast,
				A2(
					$elm$core$List$filterMap,
					$elm$core$Basics$identity,
					_List_fromArray(
						[
							A2($elm$core$Maybe$map, $author$project$CartEvent$AddedToCart, cartDiff.ah),
							A2($elm$core$Maybe$map, $author$project$CartEvent$RemovedFromCart, cartDiff.ap)
						])));
		} else {
			return _List_Nil;
		}
	});
var $elm$core$Dict$filter = F2(
	function (isGood, dict) {
		return A3(
			$elm$core$Dict$foldl,
			F3(
				function (k, v, d) {
					return A2(isGood, k, v) ? A3($elm$core$Dict$insert, k, v, d) : d;
				}),
			$elm$core$Dict$empty,
			dict);
	});
var $author$project$Cart$getProductVariantIdAndQuantity = function (line) {
	return _Utils_Tuple2(line.d2.aI, line.am);
};
var $author$project$Cart$moreThan = F2(
	function (compare, value) {
		return _Utils_cmp(value, compare) > 0;
	});
var $author$project$Cart$calculateCreateCartChange = F2(
	function (cart, _v0) {
		var id = _v0.a;
		var quantity = _v0.b;
		return A2(
			$elm$core$Dict$filter,
			$elm$core$Basics$always(
				$author$project$Cart$moreThan(0)),
			A3(
				$elm$core$Dict$insert,
				id,
				quantity,
				$elm$core$Dict$fromList(
					A2($elm$core$List$map, $author$project$Cart$getProductVariantIdAndQuantity, cart.bt))));
	});
var $elm$core$List$sum = function (numbers) {
	return A3($elm$core$List$foldl, $elm$core$Basics$add, 0, numbers);
};
var $author$project$Cart$cartItemCount = function (cart) {
	return $elm$core$List$sum(
		A2(
			$elm$core$List$map,
			function ($) {
				return $.am;
			},
			cart.bt));
};
var $author$project$Cart$getCartWithChange = function (remoteCart) {
	switch (remoteCart.$) {
		case 0:
			return $elm$core$Maybe$Nothing;
		case 1:
			var cart = remoteCart.a;
			return $elm$core$Maybe$Nothing;
		case 2:
			return $elm$core$Maybe$Nothing;
		case 3:
			var cart = remoteCart.a;
			var change = remoteCart.b;
			return $elm$core$Maybe$Just(
				_Utils_Tuple2(cart, change));
		case 4:
			var cart = remoteCart.a;
			var change = remoteCart.b;
			return $elm$core$Maybe$Just(
				_Utils_Tuple2(cart, change));
		default:
			var cart = remoteCart.a;
			var change = remoteCart.b;
			return $elm$core$Maybe$Just(
				_Utils_Tuple2(cart, change));
	}
};
var $author$project$Cart$quantityOfProductVariant = F2(
	function (cart, productVariantId) {
		return A2(
			$elm$core$Maybe$withDefault,
			0,
			A2(
				$elm$core$Dict$get,
				productVariantId,
				$elm$core$Dict$fromList(
					A2($elm$core$List$map, $author$project$Cart$getProductVariantIdAndQuantity, cart.bt))));
	});
var $author$project$Cart$Updating = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $author$project$Cart$CartLinesAdd = function (a) {
	return {$: 2, a: a};
};
var $author$project$Cart$CartLinesRemove = function (a) {
	return {$: 3, a: a};
};
var $author$project$Cart$CartLinesUpdate = function (a) {
	return {$: 4, a: a};
};
var $author$project$Cart$getProductVariantIdAndLineId = function (line) {
	return _Utils_Tuple2(line.d2.aI, line.aI);
};
var $author$project$Cart$calculateCartUpdateEffect = F2(
	function (cart, _v0) {
		var productVariantId = _v0.a;
		var quantity = _v0.b;
		var lineIdToEffect = (quantity > 0) ? function (lineId) {
			return $author$project$Cart$CartLinesUpdate(
				{Z: cart.aI, bs: lineId, am: quantity});
		} : function (lineId) {
			return $author$project$Cart$CartLinesRemove(
				{Z: cart.aI, bs: lineId});
		};
		return A2(
			$elm$core$Maybe$withDefault,
			$author$project$Cart$CartLinesAdd(
				{Z: cart.aI, cI: productVariantId, am: quantity}),
			A2(
				$elm$core$Maybe$map,
				lineIdToEffect,
				A2(
					$elm$core$Dict$get,
					productVariantId,
					$elm$core$Dict$fromList(
						A2($elm$core$List$map, $author$project$Cart$getProductVariantIdAndLineId, cart.bt)))));
	});
var $author$project$Cart$updateCart = F4(
	function (model, cart, productVariantId, quantity) {
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{
					f: A2(
						$author$project$Cart$Updating,
						cart,
						_Utils_Tuple2(productVariantId, quantity))
				}),
			_List_fromArray(
				[
					A2(
					$author$project$Cart$calculateCartUpdateEffect,
					cart,
					_Utils_Tuple2(productVariantId, quantity))
				]));
	});
var $author$project$Cart$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				return _Utils_Tuple2(model, _List_Nil);
			case 1:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{bi: 0}),
					_List_Nil);
			case 2:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{bi: 1}),
					_List_Nil);
			case 3:
				var cart = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							f: $author$project$Cart$Loaded(cart)
						}),
					A2(
						$elm$core$List$append,
						_List_fromArray(
							[
								$author$project$Cart$Broadcast(
								$author$project$CartEvent$CartCreated(cart.aI))
							]),
						A2($author$project$Cart$calculateCartChangeEvent, model.f, cart)));
			case 4:
				var remoteCart = A2(
					$elm$core$Maybe$withDefault,
					$author$project$Cart$CreationFailed,
					A2(
						$elm$core$Maybe$map,
						function (_v1) {
							var cart = _v1.a;
							var change = _v1.b;
							return A2($author$project$Cart$RecreationFailed, cart, change);
						},
						$author$project$Cart$getCartWithChange(model.f)));
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{f: remoteCart}),
					_List_Nil);
			case 5:
				var cart = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							f: $author$project$Cart$Loaded(cart)
						}),
					_List_Nil);
			case 6:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{f: $author$project$Cart$Loading}),
					_List_fromArray(
						[
							$author$project$Cart$CreateCart($elm$core$Dict$empty)
						]));
			case 10:
				var productVariantId = msg.a;
				var _v2 = model.f;
				if (_v2.$ === 1) {
					var cart = _v2.a;
					var newQuantity = A2($author$project$Cart$quantityOfProductVariant, cart, productVariantId) + 1;
					return A4($author$project$Cart$updateCart, model, cart, productVariantId, newQuantity);
				} else {
					return _Utils_Tuple2(model, _List_Nil);
				}
			case 9:
				var productVariantId = msg.a;
				var newQuantity = msg.b;
				var _v3 = model.f;
				if (_v3.$ === 1) {
					var cart = _v3.a;
					return A4($author$project$Cart$updateCart, model, cart, productVariantId, newQuantity);
				} else {
					return _Utils_Tuple2(model, _List_Nil);
				}
			case 7:
				var cart = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							f: $author$project$Cart$Loaded(cart)
						}),
					A2($author$project$Cart$calculateCartChangeEvent, model.f, cart));
			case 8:
				var _v4 = model.f;
				if (_v4.$ === 3) {
					var cart = _v4.a;
					var change = _v4.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								f: A2($author$project$Cart$Recreating, cart, change)
							}),
						_List_fromArray(
							[
								$author$project$Cart$CreateCart(
								A2($author$project$Cart$calculateCreateCartChange, cart, change))
							]));
				} else {
					return _Utils_Tuple2(model, _List_Nil);
				}
			case 11:
				var _v5 = model.f;
				if (_v5.$ === 1) {
					var cart = _v5.a;
					var quantity = $author$project$Cart$cartItemCount(cart);
					var cartState = {
						a5: $author$project$Cart$cartToCartItems(cart),
						c1: cart.c1
					};
					var effects = (quantity > 0) ? _List_fromArray(
						[
							$author$project$Cart$StartCheckout(cart.dj),
							$author$project$Cart$Broadcast(
							$author$project$CartEvent$CheckoutStarted(cartState))
						]) : _List_Nil;
					return _Utils_Tuple2(model, effects);
				} else {
					return _Utils_Tuple2(model, _List_Nil);
				}
			case 12:
				var transactionId = msg.a;
				var _v6 = $author$project$Cart$getCart(model.f);
				if (!_v6.$) {
					var cart = _v6.a;
					var cartState = {
						a5: $author$project$Cart$cartToCartItems(cart),
						c1: cart.c1
					};
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{f: $author$project$Cart$Loading}),
						_List_fromArray(
							[
								$author$project$Cart$CreateCart($elm$core$Dict$empty),
								$author$project$Cart$Broadcast(
								A2($author$project$CartEvent$CheckoutCompleted, transactionId, cartState))
							]));
				} else {
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{f: $author$project$Cart$Loading}),
						_List_fromArray(
							[
								$author$project$Cart$CreateCart($elm$core$Dict$empty)
							]));
				}
			default:
				var _v7 = model.f;
				switch (_v7.$) {
					case 2:
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{f: $author$project$Cart$Loading}),
							_List_fromArray(
								[
									$author$project$Cart$CreateCart($elm$core$Dict$empty)
								]));
					case 5:
						var cart = _v7.a;
						var change = _v7.b;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									f: A2($author$project$Cart$Recreating, cart, change)
								}),
							_List_fromArray(
								[
									$author$project$Cart$CreateCart(
									A2($author$project$Cart$calculateCreateCartChange, cart, change))
								]));
					default:
						return _Utils_Tuple2(model, _List_Nil);
				}
		}
	});
var $author$project$Cart$updateWithCmd = F2(
	function (msg, model) {
		var _v0 = A2($author$project$Cart$update, msg, model);
		var cart = _v0.a;
		var effects = _v0.b;
		var cmd = $elm$core$Platform$Cmd$batch(
			A2(
				$elm$core$List$map,
				$author$project$Cart$effectToCmd(cart.bn),
				effects));
		return _Utils_Tuple2(cart, cmd);
	});
var $author$project$Cart$AddToCart = function (a) {
	return {$: 10, a: a};
};
var $author$project$Cart$addToCart = function (productVariantId) {
	return $author$project$Cart$AddToCart(productVariantId);
};
var $author$project$Cart$button = function (attributes) {
	var newAttributes = A2(
		$elm$core$List$append,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('f5 link mb2 br3')
			]),
		attributes);
	return $elm$html$Html$button(newAttributes);
};
var $author$project$Cart$buttonPrimary = function (attributes) {
	return $author$project$Cart$button(
		A2(
			$elm$core$List$append,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('dim pointer ph4 pv3 bg-black white bn')
				]),
			attributes));
};
var $author$project$Cart$buyButton = function (id) {
	return A2(
		$author$project$Cart$buttonPrimary,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('mt2'),
				$elm$html$Html$Events$onClick(
				$author$project$Cart$addToCart(id))
			]),
		_List_fromArray(
			[
				$elm$html$Html$text('Add to cart')
			]));
};
var $author$project$Cart$OpenCart = {$: 1};
var $elm$svg$Svg$trustedNode = _VirtualDom_nodeNS('http://www.w3.org/2000/svg');
var $elm$svg$Svg$circle = $elm$svg$Svg$trustedNode('circle');
var $elm$svg$Svg$Attributes$cx = _VirtualDom_attribute('cx');
var $elm$svg$Svg$Attributes$cy = _VirtualDom_attribute('cy');
var $elm$svg$Svg$Attributes$d = _VirtualDom_attribute('d');
var $feathericons$elm_feather$FeatherIcons$Icon = $elm$core$Basics$identity;
var $feathericons$elm_feather$FeatherIcons$defaultAttributes = function (name) {
	return {
		bo: $elm$core$Maybe$Just('feather feather-' + name),
		cY: 24,
		bd: '',
		bx: 2,
		by: '0 0 24 24'
	};
};
var $feathericons$elm_feather$FeatherIcons$makeBuilder = F2(
	function (name, src) {
		return {
			B: $feathericons$elm_feather$FeatherIcons$defaultAttributes(name),
			a: src
		};
	});
var $elm$svg$Svg$path = $elm$svg$Svg$trustedNode('path');
var $elm$svg$Svg$Attributes$r = _VirtualDom_attribute('r');
var $feathericons$elm_feather$FeatherIcons$shoppingCart = A2(
	$feathericons$elm_feather$FeatherIcons$makeBuilder,
	'shopping-cart',
	_List_fromArray(
		[
			A2(
			$elm$svg$Svg$circle,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$cx('9'),
					$elm$svg$Svg$Attributes$cy('21'),
					$elm$svg$Svg$Attributes$r('1')
				]),
			_List_Nil),
			A2(
			$elm$svg$Svg$circle,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$cx('20'),
					$elm$svg$Svg$Attributes$cy('21'),
					$elm$svg$Svg$Attributes$r('1')
				]),
			_List_Nil),
			A2(
			$elm$svg$Svg$path,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$d('M1 1h4l2.68 13.39a2 2 0 0 0 2 1.61h9.72a2 2 0 0 0 2-1.61L23 6H6')
				]),
			_List_Nil)
		]));
var $elm$svg$Svg$Attributes$class = _VirtualDom_attribute('class');
var $elm$svg$Svg$Attributes$fill = _VirtualDom_attribute('fill');
var $elm$svg$Svg$Attributes$height = _VirtualDom_attribute('height');
var $elm$virtual_dom$VirtualDom$map = _VirtualDom_map;
var $elm$svg$Svg$map = $elm$virtual_dom$VirtualDom$map;
var $elm$svg$Svg$Attributes$stroke = _VirtualDom_attribute('stroke');
var $elm$svg$Svg$Attributes$strokeLinecap = _VirtualDom_attribute('stroke-linecap');
var $elm$svg$Svg$Attributes$strokeLinejoin = _VirtualDom_attribute('stroke-linejoin');
var $elm$svg$Svg$Attributes$strokeWidth = _VirtualDom_attribute('stroke-width');
var $elm$svg$Svg$svg = $elm$svg$Svg$trustedNode('svg');
var $elm$svg$Svg$Attributes$viewBox = _VirtualDom_attribute('viewBox');
var $elm$svg$Svg$Attributes$width = _VirtualDom_attribute('width');
var $feathericons$elm_feather$FeatherIcons$toHtml = F2(
	function (attributes, _v0) {
		var src = _v0.a;
		var attrs = _v0.B;
		var strSize = $elm$core$String$fromFloat(attrs.cY);
		var baseAttributes = _List_fromArray(
			[
				$elm$svg$Svg$Attributes$fill('none'),
				$elm$svg$Svg$Attributes$height(
				_Utils_ap(strSize, attrs.bd)),
				$elm$svg$Svg$Attributes$width(
				_Utils_ap(strSize, attrs.bd)),
				$elm$svg$Svg$Attributes$stroke('currentColor'),
				$elm$svg$Svg$Attributes$strokeLinecap('round'),
				$elm$svg$Svg$Attributes$strokeLinejoin('round'),
				$elm$svg$Svg$Attributes$strokeWidth(
				$elm$core$String$fromFloat(attrs.bx)),
				$elm$svg$Svg$Attributes$viewBox(attrs.by)
			]);
		var combinedAttributes = _Utils_ap(
			function () {
				var _v1 = attrs.bo;
				if (!_v1.$) {
					var c = _v1.a;
					return A2(
						$elm$core$List$cons,
						$elm$svg$Svg$Attributes$class(c),
						baseAttributes);
				} else {
					return baseAttributes;
				}
			}(),
			attributes);
		return A2(
			$elm$svg$Svg$svg,
			combinedAttributes,
			A2(
				$elm$core$List$map,
				$elm$svg$Svg$map($elm$core$Basics$never),
				src));
	});
var $feathericons$elm_feather$FeatherIcons$withSize = F2(
	function (size, _v0) {
		var attrs = _v0.B;
		var src = _v0.a;
		return {
			B: _Utils_update(
				attrs,
				{cY: size}),
			a: src
		};
	});
var $feathericons$elm_feather$FeatherIcons$withSizeUnit = F2(
	function (sizeUnit, _v0) {
		var attrs = _v0.B;
		var src = _v0.a;
		return {
			B: _Utils_update(
				attrs,
				{bd: sizeUnit}),
			a: src
		};
	});
var $author$project$Cart$cartButton = function (cart) {
	var totalItemsText = A2(
		$elm$core$Maybe$withDefault,
		$elm$html$Html$text('...'),
		A2(
			$elm$core$Maybe$map,
			A2($elm$core$Basics$composeR, $elm$core$String$fromInt, $elm$html$Html$text),
			A2($elm$core$Maybe$map, $author$project$Cart$cartItemCount, cart)));
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('fixed top-0 right-0 h-100 flex items-center')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$a,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('f6 link dim mb2 bt bl bb b--white br2 br--left ph3 pv2 white bg-black pointer'),
						$elm$html$Html$Events$onClick($author$project$Cart$OpenCart)
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$span,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('db tc mb2')
							]),
						_List_fromArray(
							[totalItemsText])),
						A2(
						$elm$html$Html$span,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('db tc')
							]),
						_List_fromArray(
							[
								A2(
								$feathericons$elm_feather$FeatherIcons$toHtml,
								_List_Nil,
								A2(
									$feathericons$elm_feather$FeatherIcons$withSizeUnit,
									'em',
									A2($feathericons$elm_feather$FeatherIcons$withSize, 1.5, $feathericons$elm_feather$FeatherIcons$shoppingCart)))
							]))
					]))
			]));
};
var $author$project$Cart$Retry = {$: 13};
var $author$project$Cart$buttonSecondarySmall = function (attributes) {
	return $author$project$Cart$button(
		A2(
			$elm$core$List$append,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('dim pointer ph3 pv2 bg-white black ba')
				]),
			attributes));
};
var $author$project$Cart$cartErrorView = A2(
	$elm$html$Html$div,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('ph3')
		]),
	_List_fromArray(
		[
			A2(
			$elm$html$Html$p,
			_List_Nil,
			_List_fromArray(
				[
					$elm$html$Html$text('Something unexpected happened while trying to update your cart.')
				])),
			A2(
			$author$project$Cart$buttonSecondarySmall,
			_List_fromArray(
				[
					$elm$html$Html$Events$onClick($author$project$Cart$Retry)
				]),
			_List_fromArray(
				[
					$elm$html$Html$text('Try Again')
				]))
		]));
var $author$project$Cart$buttonPrimaryDisabled = function (attributes) {
	return $author$project$Cart$button(
		A2(
			$elm$core$List$append,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$disabled(true),
					$elm$html$Html$Attributes$class('ph4 pv3 bg-black white bn o-30')
				]),
			attributes));
};
var $author$project$Cart$Checkout = {$: 11};
var $author$project$Cart$checkout = $author$project$Cart$Checkout;
var $elm$core$List$any = F2(
	function (isOkay, list) {
		any:
		while (true) {
			if (!list.b) {
				return false;
			} else {
				var x = list.a;
				var xs = list.b;
				if (isOkay(x)) {
					return true;
				} else {
					var $temp$isOkay = isOkay,
						$temp$list = xs;
					isOkay = $temp$isOkay;
					list = $temp$list;
					continue any;
				}
			}
		}
	});
var $myrho$elm_round$Round$addSign = F2(
	function (signed, str) {
		var isNotZero = A2(
			$elm$core$List$any,
			function (c) {
				return (c !== '0') && (c !== '.');
			},
			$elm$core$String$toList(str));
		return _Utils_ap(
			(signed && isNotZero) ? '-' : '',
			str);
	});
var $elm$core$Char$fromCode = _Char_fromCode;
var $myrho$elm_round$Round$increaseNum = function (_v0) {
	var head = _v0.a;
	var tail = _v0.b;
	if (head === '9') {
		var _v1 = $elm$core$String$uncons(tail);
		if (_v1.$ === 1) {
			return '01';
		} else {
			var headtail = _v1.a;
			return A2(
				$elm$core$String$cons,
				'0',
				$myrho$elm_round$Round$increaseNum(headtail));
		}
	} else {
		var c = $elm$core$Char$toCode(head);
		return ((c >= 48) && (c < 57)) ? A2(
			$elm$core$String$cons,
			$elm$core$Char$fromCode(c + 1),
			tail) : '0';
	}
};
var $elm$core$Basics$isInfinite = _Basics_isInfinite;
var $elm$core$Basics$isNaN = _Basics_isNaN;
var $elm$core$String$padRight = F3(
	function (n, _char, string) {
		return _Utils_ap(
			string,
			A2(
				$elm$core$String$repeat,
				n - $elm$core$String$length(string),
				$elm$core$String$fromChar(_char)));
	});
var $elm$core$String$reverse = _String_reverse;
var $myrho$elm_round$Round$splitComma = function (str) {
	var _v0 = A2($elm$core$String$split, '.', str);
	if (_v0.b) {
		if (_v0.b.b) {
			var before = _v0.a;
			var _v1 = _v0.b;
			var after = _v1.a;
			return _Utils_Tuple2(before, after);
		} else {
			var before = _v0.a;
			return _Utils_Tuple2(before, '0');
		}
	} else {
		return _Utils_Tuple2('0', '0');
	}
};
var $myrho$elm_round$Round$toDecimal = function (fl) {
	var _v0 = A2(
		$elm$core$String$split,
		'e',
		$elm$core$String$fromFloat(
			$elm$core$Basics$abs(fl)));
	if (_v0.b) {
		if (_v0.b.b) {
			var num = _v0.a;
			var _v1 = _v0.b;
			var exp = _v1.a;
			var e = A2(
				$elm$core$Maybe$withDefault,
				0,
				$elm$core$String$toInt(
					A2($elm$core$String$startsWith, '+', exp) ? A2($elm$core$String$dropLeft, 1, exp) : exp));
			var _v2 = $myrho$elm_round$Round$splitComma(num);
			var before = _v2.a;
			var after = _v2.b;
			var total = _Utils_ap(before, after);
			var zeroed = (e < 0) ? A2(
				$elm$core$Maybe$withDefault,
				'0',
				A2(
					$elm$core$Maybe$map,
					function (_v3) {
						var a = _v3.a;
						var b = _v3.b;
						return a + ('.' + b);
					},
					A2(
						$elm$core$Maybe$map,
						$elm$core$Tuple$mapFirst($elm$core$String$fromChar),
						$elm$core$String$uncons(
							_Utils_ap(
								A2(
									$elm$core$String$repeat,
									$elm$core$Basics$abs(e),
									'0'),
								total))))) : A3($elm$core$String$padRight, e + 1, '0', total);
			return _Utils_ap(
				(fl < 0) ? '-' : '',
				zeroed);
		} else {
			var num = _v0.a;
			return _Utils_ap(
				(fl < 0) ? '-' : '',
				num);
		}
	} else {
		return '';
	}
};
var $myrho$elm_round$Round$roundFun = F3(
	function (functor, s, fl) {
		if ($elm$core$Basics$isInfinite(fl) || $elm$core$Basics$isNaN(fl)) {
			return $elm$core$String$fromFloat(fl);
		} else {
			var signed = fl < 0;
			var _v0 = $myrho$elm_round$Round$splitComma(
				$myrho$elm_round$Round$toDecimal(
					$elm$core$Basics$abs(fl)));
			var before = _v0.a;
			var after = _v0.b;
			var r = $elm$core$String$length(before) + s;
			var normalized = _Utils_ap(
				A2($elm$core$String$repeat, (-r) + 1, '0'),
				A3(
					$elm$core$String$padRight,
					r,
					'0',
					_Utils_ap(before, after)));
			var totalLen = $elm$core$String$length(normalized);
			var roundDigitIndex = A2($elm$core$Basics$max, 1, r);
			var increase = A2(
				functor,
				signed,
				A3($elm$core$String$slice, roundDigitIndex, totalLen, normalized));
			var remains = A3($elm$core$String$slice, 0, roundDigitIndex, normalized);
			var num = increase ? $elm$core$String$reverse(
				A2(
					$elm$core$Maybe$withDefault,
					'1',
					A2(
						$elm$core$Maybe$map,
						$myrho$elm_round$Round$increaseNum,
						$elm$core$String$uncons(
							$elm$core$String$reverse(remains))))) : remains;
			var numLen = $elm$core$String$length(num);
			var numZeroed = (num === '0') ? num : ((s <= 0) ? _Utils_ap(
				num,
				A2(
					$elm$core$String$repeat,
					$elm$core$Basics$abs(s),
					'0')) : ((_Utils_cmp(
				s,
				$elm$core$String$length(after)) < 0) ? (A3($elm$core$String$slice, 0, numLen - s, num) + ('.' + A3($elm$core$String$slice, numLen - s, numLen, num))) : _Utils_ap(
				before + '.',
				A3($elm$core$String$padRight, s, '0', after))));
			return A2($myrho$elm_round$Round$addSign, signed, numZeroed);
		}
	});
var $myrho$elm_round$Round$round = $myrho$elm_round$Round$roundFun(
	F2(
		function (signed, str) {
			var _v0 = $elm$core$String$uncons(str);
			if (_v0.$ === 1) {
				return false;
			} else {
				if ('5' === _v0.a.a) {
					if (_v0.a.b === '') {
						var _v1 = _v0.a;
						return !signed;
					} else {
						var _v2 = _v0.a;
						return true;
					}
				} else {
					var _v3 = _v0.a;
					var _int = _v3.a;
					return function (i) {
						return ((i > 53) && signed) || ((i >= 53) && (!signed));
					}(
						$elm$core$Char$toCode(_int));
				}
			}
		}));
var $author$project$Money$toString = function (money) {
	var stringAmount = A2(
		$myrho$elm_round$Round$round,
		2,
		$prikhi$decimal$Decimal$toFloat(money.bS));
	return '£' + stringAmount;
};
var $author$project$Cart$cartFooter = function (maybeCart) {
	var content = function () {
		if (!maybeCart.$) {
			var cart = maybeCart.a;
			var checkoutButton = function (count) {
				return (count > 0) ? A2(
					$author$project$Cart$buttonPrimary,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('db w-100'),
							$elm$html$Html$Events$onClick($author$project$Cart$checkout)
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('Checkout')
						])) : A2(
					$author$project$Cart$buttonPrimaryDisabled,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('db w-100')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('Checkout')
						]));
			}(
				$author$project$Cart$cartItemCount(cart));
			return _List_fromArray(
				[
					A2(
					$elm$html$Html$p,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$span,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('Subtotal:')
								])),
							A2(
							$elm$html$Html$span,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('fr fw6')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(
									$author$project$Money$toString(cart.c1))
								]))
						])),
					A2(
					$elm$html$Html$p,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('tc f6')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('Shipping and discount codes are added at checkout.')
						])),
					checkoutButton
				]);
		} else {
			return _List_Nil;
		}
	}();
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('self-end w-100 ph3')
			]),
		content);
};
var $author$project$Cart$CloseCart = {$: 2};
var $author$project$Cart$closeButton = A2(
	$elm$html$Html$button,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('f1 bn pointer dim ph0 bg-white'),
			$elm$html$Html$Events$onClick($author$project$Cart$CloseCart)
		]),
	_List_fromArray(
		[
			$elm$html$Html$text('×')
		]));
var $elm$html$Html$h1 = _VirtualDom_node('h1');
var $author$project$Cart$cartHeader = A2(
	$elm$html$Html$div,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('self-start w-100 ph3 flex justify-between')
		]),
	_List_fromArray(
		[
			A2(
			$elm$html$Html$h1,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('f3 fw4')
				]),
			_List_fromArray(
				[
					$elm$html$Html$text('Cart')
				])),
			$author$project$Cart$closeButton
		]));
var $elm$html$Html$Attributes$height = function (n) {
	return A2(
		_VirtualDom_attribute,
		'height',
		$elm$core$String$fromInt(n));
};
var $elm$html$Html$img = _VirtualDom_node('img');
var $elm$html$Html$Attributes$src = function (url) {
	return A2(
		$elm$html$Html$Attributes$stringProperty,
		'src',
		_VirtualDom_noJavaScriptOrHtmlUri(url));
};
var $elm$html$Html$Attributes$width = function (n) {
	return A2(
		_VirtualDom_attribute,
		'width',
		$elm$core$String$fromInt(n));
};
var $author$project$Cart$imageView = function (image) {
	return A2(
		$elm$html$Html$img,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('db mw-100 h-auto bg-light-gray'),
				$elm$html$Html$Attributes$src(image.ar),
				$elm$html$Html$Attributes$width(64),
				$elm$html$Html$Attributes$height(64)
			]),
		_List_Nil);
};
var $author$project$Cart$updateItemQuantityInCart = F2(
	function (productVariantId, quantity) {
		return A2($author$project$Cart$UpdateCart, productVariantId, quantity);
	});
var $author$project$Cart$lineView = function (_v0) {
	var id = _v0.aI;
	var productVariant = _v0.d2;
	var quantity = _v0.am;
	var subTotal = _v0.c1;
	var image = A2(
		$elm$core$Maybe$withDefault,
		A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('dib w3 h3 bg-light-gray')
				]),
			_List_Nil),
		A2($elm$core$Maybe$map, $author$project$Cart$imageView, productVariant.dy));
	var addButtonAttributes = (_Utils_cmp(productVariant.d3, quantity) > 0) ? _List_fromArray(
		[
			$elm$html$Html$Attributes$class('ba br2 br--right pa2 dim pointer dib w2 tc b bg-white'),
			$elm$html$Html$Events$onClick(
			A2($author$project$Cart$updateItemQuantityInCart, productVariant.aI, quantity + 1))
		]) : _List_fromArray(
		[
			$elm$html$Html$Attributes$class('ba br2 br--right pa2 dib w2 tc b bg-white o-30'),
			$elm$html$Html$Attributes$disabled(true)
		]);
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('flex mb3')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$span,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('db pa0')
					]),
				_List_fromArray(
					[image])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('ml2 flex-auto')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$h4,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('mv1')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(productVariant.d1.eh)
							])),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('dib')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$a,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('ba br2 br--left pa2 dim pointer dib w2 tc b bg-white'),
										$elm$html$Html$Events$onClick(
										A2($author$project$Cart$updateItemQuantityInCart, productVariant.aI, quantity - 1))
									]),
								_List_fromArray(
									[
										$elm$html$Html$text('–')
									])),
								A2(
								$elm$html$Html$span,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('bt bb pa2 dib w2 tc')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text(
										$elm$core$String$fromInt(quantity))
									])),
								A2(
								$elm$html$Html$a,
								addButtonAttributes,
								_List_fromArray(
									[
										$elm$html$Html$text('+')
									]))
							])),
						A2(
						$elm$html$Html$span,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('dib fr fw6 pv2')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(
								$author$project$Money$toString(subTotal))
							]))
					]))
			]));
};
var $author$project$Cart$cartView = function (cart) {
	var content = ($elm$core$List$length(cart.bt) > 0) ? A2($elm$core$List$map, $author$project$Cart$lineView, cart.bt) : _List_fromArray(
		[
			A2(
			$elm$html$Html$p,
			_List_Nil,
			_List_fromArray(
				[
					$elm$html$Html$text('Your cart is empty.')
				]))
		]);
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('h-100 overflow-y-scroll ph3')
			]),
		content);
};
var $elm$html$Html$section = _VirtualDom_node('section');
var $author$project$Cart$remoteCartView = F2(
	function (remoteCart, isOpen) {
		var transformAttribute = isOpen ? A2($elm$html$Html$Attributes$style, 'transform', 'translateX(0%)') : A2($elm$html$Html$Attributes$style, 'transform', 'translateX(100%)');
		var content = function () {
			switch (remoteCart.$) {
				case 0:
					return A2(
						$elm$html$Html$p,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('Loading')
							]));
				case 1:
					var cart = remoteCart.a;
					return $author$project$Cart$cartView(cart);
				case 2:
					return $author$project$Cart$cartErrorView;
				case 3:
					var cart = remoteCart.a;
					var change = remoteCart.b;
					return $author$project$Cart$cartView(cart);
				case 4:
					var cart = remoteCart.a;
					var change = remoteCart.b;
					return $author$project$Cart$cartView(cart);
				default:
					return $author$project$Cart$cartErrorView;
			}
		}();
		return A2(
			$elm$html$Html$section,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('fixed right-0 top-0 h-100 measure w-100 bg-white shadow-2'),
					transformAttribute,
					A2($elm$html$Html$Attributes$style, 'transition', 'transform .25s ease-in-out')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('flex flex-column h-100 justify-between')
						]),
					_List_fromArray(
						[
							$author$project$Cart$cartHeader,
							content,
							$author$project$Cart$cartFooter(
							$author$project$Cart$getCart(remoteCart))
						]))
				]));
	});
var $author$project$Cart$view = function (model) {
	var cartContent = function () {
		var _v0 = model.bi;
		if (_v0 === 1) {
			return A2($author$project$Cart$remoteCartView, model.f, false);
		} else {
			return A2($author$project$Cart$remoteCartView, model.f, true);
		}
	}();
	var addToCartButton = A2($elm$core$Maybe$map, $author$project$Cart$buyButton, model.cI);
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('avenir'),
				$elm$html$Html$Attributes$id('cart')
			]),
		A2(
			$elm$core$List$filterMap,
			$elm$core$Basics$identity,
			_List_fromArray(
				[
					addToCartButton,
					$elm$core$Maybe$Just(
					$author$project$Cart$cartButton(
						$author$project$Cart$getCart(model.f))),
					$elm$core$Maybe$Just(cartContent)
				])));
};
var $author$project$Cart$main = $elm$browser$Browser$element(
	{
		dB: $author$project$Cart$initWithCmd,
		ee: $elm$core$Basics$always($author$project$Cart$subscriptions),
		ek: $author$project$Cart$updateWithCmd,
		el: $author$project$Cart$view
	});
_Platform_export({'Cart':{'init':$author$project$Cart$main(
	A2(
		$elm$json$Json$Decode$andThen,
		function (token) {
			return A2(
				$elm$json$Json$Decode$andThen,
				function (productVariantId) {
					return A2(
						$elm$json$Json$Decode$andThen,
						function (endpoint) {
							return A2(
								$elm$json$Json$Decode$andThen,
								function (cartId) {
									return $elm$json$Json$Decode$succeed(
										{Z: cartId, bA: endpoint, cI: productVariantId, aY: token});
								},
								A2(
									$elm$json$Json$Decode$field,
									'cartId',
									$elm$json$Json$Decode$oneOf(
										_List_fromArray(
											[
												$elm$json$Json$Decode$null($elm$core$Maybe$Nothing),
												A2($elm$json$Json$Decode$map, $elm$core$Maybe$Just, $elm$json$Json$Decode$string)
											]))));
						},
						A2($elm$json$Json$Decode$field, 'endpoint', $elm$json$Json$Decode$string));
				},
				A2(
					$elm$json$Json$Decode$field,
					'productVariantId',
					$elm$json$Json$Decode$oneOf(
						_List_fromArray(
							[
								$elm$json$Json$Decode$null($elm$core$Maybe$Nothing),
								A2($elm$json$Json$Decode$map, $elm$core$Maybe$Just, $elm$json$Json$Decode$string)
							]))));
		},
		A2($elm$json$Json$Decode$field, 'token', $elm$json$Json$Decode$string)))(0)},'Mailer':{'init':$author$project$Mailer$main(
	$elm$json$Json$Decode$succeed(0))(0)},'Consent':{'init':$author$project$Consent$main(
	$elm$json$Json$Decode$oneOf(
		_List_fromArray(
			[
				$elm$json$Json$Decode$null($elm$core$Maybe$Nothing),
				A2($elm$json$Json$Decode$map, $elm$core$Maybe$Just, $elm$json$Json$Decode$string)
			])))(0)}});}(this));